import { environment } from "src/environments/environment";

var app = {};
app["kz"] = {
    add_agent_phone_pattern: '77\\d{9}',
    add_agent_iin_pattern: '\\d{12}',
    add_agent_iin_length: 12,
    add_agent_phone_prefix: '77',
    add_agent_phone_length: 11
};
app["kg"] = {
    add_agent_phone_pattern: '996\\d{9}',
    add_agent_iin_pattern: '\\d{14}',
    add_agent_iin_length: 14,
    add_agent_phone_prefix: '996',
    add_agent_phone_length: 12
};
export const appConfig = app[environment.globalLocation] ?? app['kg'];
