import { Component, OnInit } from '@angular/core';
import { ICategory } from 'src/app/interfaces/icategory';
import { AuthService } from 'src/app/services/auth.service';
import { CategoriesService } from 'src/app/services/categories.service';


@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {

  categories: ICategory[] = [];
  displayedColumns: string[] = ['ordering','alias', 'name', 'action'];
  dataSource: ICategory[] = [];

  constructor(public authService: AuthService,
    public categoriesSrv: CategoriesService,
  ) { }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.getCategories();
  }

  getCategories() {
    this.categoriesSrv.get().subscribe(
      (data: any) => {
        this.categories = data;
        this.dataSource = this.categories
        .sort((a,b) => a.ordering-b.ordering);
      },
      error => console.log(error)
    )
  }

  // Move category
  move(item: any, direction: number): void {
    this.categoriesSrv.move(item.id, direction).subscribe(
      (data: any) => {
        if (data == true){
          this.getCategories();
        }
        else{
          console.log("error");
        }
      },
      error => console.log(error)
    )
  }
}
