<div fxLayout="column" fxLayoutAlign="center center">    
    <h1 class="mat-headline">{{'whatsapp-report'| translate}}</h1>
    <h4 class="mat-subheading-1">{{'whatsapp-report-description' | translate}}</h4>  
</div>
<div class="container" fxLayout fxLayout.xs="column" fxLayoutAlign="center center" fxLayoutGap="10px"
    fxLayoutGap.xs="0">
    <mat-form-field fxFlex="40">
        <input type="text" placeholder="{{'agent'| translate}}" matInput [formControl]="agentId"
            [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" autoActiveFirstOption (optionSelected)="accountChanged()">
            <mat-option *ngFor="let option of agentAutoComplete$ | async; let index = index" [value]="option">
                {{option.title}}
            </mat-option>
        </mat-autocomplete>
        <button *ngIf="agentId != null" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
            <mat-icon>close</mat-icon>
          </button>
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{'dateFrom' | translate}}</mat-label>
        <input matInput [matDatepicker]="pickerFrom" (click)="pickerFrom.open()" [formControl]="dateFrom" placeholder="{{'dateFrom'| translate}}" (dateChange)="dateFromChanged()" readonly>
        <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
        <mat-datepicker #pickerFrom></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
        <mat-label>{{'dateTo' | translate}}</mat-label>
        <input matInput [matDatepicker]="pickerTo"  (click)="pickerTo.open()" [formControl]="dateTo" placeholder="{{'dateTo'| translate}}" (dateChange)="dateToChanged()" readonly>
        <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
        <mat-datepicker #pickerTo></mat-datepicker>
    </mat-form-field>
    <mat-form-field fxFlex="40">
        <mat-select placeholder="{{'agent-city' | translate}}" [formControl]="cityId" (selectionChange)="getReport()">
            <mat-option [value]="">
                {{ 'choose-city' | translate}}
                </mat-option>
            <mat-option *ngFor="let item of cities" [value]="item.code">
            {{ item.title }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <button mat-icon-button color="primary" (click)="getReport()">
        <mat-icon>search</mat-icon>
    </button>

    <button mat-icon-button color="primary" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="mainMenuButtonExcel" [disabled]="dataSource?.length < 1">
        <mat-icon>save_alt</mat-icon>
    </button>
    <mat-menu #mainMenuButtonExcel="matMenu">
        <div class="table-column-list-block">
            <section class="button-download">
                <button mat-raised-button color="primary"  (click)="exportToExcel()" >
                    {{'export' | translate}}
                    <mat-icon>save_alt</mat-icon>
                </button>   
            </section>
            <span (click)="$event.stopPropagation()" *ngFor="let element of columnDefinitionsForExcel;index as i">
                <mat-slide-toggle color="primary" (change)="element.isShow = !element.isShow" [checked]="element.isShow == true"></mat-slide-toggle>
                {{element.label}}
                <br>
            </span>
        </div>
    </mat-menu>
</div>

<mat-progress-bar mode="indeterminate" *ngIf="!isCompleted"></mat-progress-bar>
<div fxLayout="column" fxLayoutAlign="none">
    <table mat-table [dataSource]="datasourceTableForPagination" class="mat-elevation-z0">

        <!-- date Column -->
        <ng-container matColumnDef="sendDate">
            <th mat-header-cell *matHeaderCellDef> {{'date' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.sendDate | date: 'dd/MM/yyyy HH:mm:ss'}} </td>
        </ng-container>

        <!-- creator  -->
        <ng-container matColumnDef="creator">
            <th mat-header-cell *matHeaderCellDef> {{'Creator' | translate}}   </th>
            <td mat-cell *matCellDef="let element"> {{element.creator}} </td>
        </ng-container>

        <!-- city Column -->
        <ng-container matColumnDef="cityName">
            <th mat-header-cell *matHeaderCellDef> {{'agent-city' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.cityName}} </td>
        </ng-container>

         <!-- phoneNumber Column -->
         <ng-container matColumnDef="phoneNumber">
            <th mat-header-cell *matHeaderCellDef> {{'phoneNumber' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.phoneNumber}} </td>
        </ng-container>

         <!-- status Column -->
         <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>{{'report-status' | translate}} </th>
            <td mat-cell *matCellDef="let element"> 
                <div>
                    <mat-icon *ngIf="element.status" class="success">check</mat-icon>
                    <mat-icon *ngIf="!element.status" class="error">close</mat-icon>
                </div>
            </td>
        </ng-container>

         <!-- transactionId Column -->
         <ng-container matColumnDef="transactionId">
            <th mat-header-cell *matHeaderCellDef> {{'report-transaction-id-desc' | translate}}</th>
            <td mat-cell *matCellDef="let element"> {{element.transactionId}} 
                <a (click)="openTransactionDetailDialog(element)">
                    <button title="{{'transaction-detail' | translate}}" mat-icon-button color="primary">
                        <mat-icon>receipt</mat-icon>
                    </button>
                </a>
            </td>
        </ng-container>

         <!-- statusMessage Column -->
         <ng-container matColumnDef="statusMessage">
            <th mat-header-cell *matHeaderCellDef>{{'whatsapp-response-message' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.statusMessage}} </td>
        </ng-container>
        
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>     
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSize]="100" [pageSizeOptions]="[5, 10, 25, 100, 500, 1000, 5000, 10000]"></mat-paginator>
</div>
