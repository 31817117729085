import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { TranslateService } from '@ngx-translate/core';
import { ExportService } from 'src/app/services/export.service';
import { TariffsService } from 'src/app/services/tariffs.service';

@Component({
  selector: 'app-tariff-rates-tree',
  templateUrl: './tariff-rates-tree.component.html',
  styleUrls: ['./tariff-rates-tree.component.css']
})
export class TariffRatesTreeComponent implements OnInit {

  TreeData: any[] = [];
  columnDefinitionsForExcel = [];

  private _transformer = (node: any, level: number) => {
    node.expandable = !!node.subs && node.subs.length > 0;
    node.level = level;
    return node;
  }

  treeControl = new FlatTreeControl<any>(
    node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.level, node => node.expandable, node => node.subs);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  
  serviceName: string
  
  isCompleted: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public tariffRatesTree : TariffsService,
  private translate: TranslateService,
  public snackBar: MatSnackBar,
  private exportSrv: ExportService,) {

  }

  hasChild = (_: number, node: any) => node.expandable;

  ngOnInit(): void {
    this.isCompleted = false;
    this.getRatesTariff();
  }

  getRatesTariff() {
    this.serviceName = this.data.tariff.serviceName;
    this.tariffRatesTree.getRatesTariffTree(this.data.tariff.tariffId, this.data.tariff.serviceId).subscribe(
      (data: any[]) => {
        this.TreeData = data;
        this.dataSource.data = this.TreeData;
        
        if(data[0]){
            this.columnDefinitionsForExcel = this.exportSrv.getAllColumnsExcel(Object.keys(this.TreeData[0]));
        }
      },
      error => {
        if (error.status === 404) {
          let message = this.translate.instant('not-found');
          this.openSnackBar(message, 'x');
        }
        else
          if (error.status === 400) {
            let message = this.translate.instant('an-error-occurred-while-processing');
            this.openSnackBar(message, 'x');
          }
          else
            if (error.status === 500) {
              let message = this.translate.instant('service-is-temporarily-unavailable');
              this.openSnackBar(message, 'x');
            }
            else {
              let message = this.translate.instant('an-error-occurred-while-processing');
              this.openSnackBar(message, 'x');
            }
      }
    ).add(() => { this.isCompleted = true });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  exportToExcel() {
    let totalArray = this.dataSource.data;
    let arrayTariffs = [];
    this.getRecursiveTariffs(arrayTariffs, totalArray);
    let checkTariffs = obj => obj.def === "tariffName";
    if(!this.columnDefinitionsForExcel.some(checkTariffs)){
      this.columnDefinitionsForExcel.push({ def: "tariffName", isShow: true, label: this.translate.instant('tariffName')});
    }
    this.exportSrv.exportToExcel(arrayTariffs, 'Report', 'filename.xlsx', this.columnDefinitionsForExcel);
  }
 
  getRecursiveTariffs(arrayTariffs, agents) {
    agents.forEach(element => {
      let stringSpace = "";
      let level = element['level'];
      for (var i = 0; i < level * 10; i++) {
        stringSpace += " ";
      }
      element['tariffName'] = stringSpace + " " + (element['tariffName'] ?? "");
      arrayTariffs.push(Object.assign({'tariffName':  element['tariffName']}, element));
      if (element['subs'] && element['subs'] != null) {
        element['subs'].forEach(element2 => {
          let stringSpace = "";
          let level = element2['level'];
          for (var i = 0; i < level * 10; i++) {
            stringSpace += " ";
          }
          element2['tariffName'] = stringSpace + " " + (element2['tariffName'] ?? "");
          arrayTariffs.push(Object.assign({'tariffName': element2['tariffName']}, element2));
          return this.getRecursiveTariffs(arrayTariffs, element2['subs']);
        });
      }
    });
    return arrayTariffs;
  }
  
}
