import { Component, OnInit } from '@angular/core';
import { IAccount } from 'src/app/interfaces/iaccount';
import { AccountService } from 'src/app/services/account.service';
import { AuthService } from 'src/app/services/auth.service';
import { FormControl } from '@angular/forms';
import { IConsolidatedReportExtended } from 'src/app/interfaces/reports/iconsolidated-report-extended';
import { ExportService } from 'src/app/services/export.service';
import { DictionaryService } from 'src/app/services/dictionary.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ReportService } from 'src/app/services/report.service';
import { catchError, debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AgentService } from 'src/app/services/agent.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-consolidated-extended',
  templateUrl: './consolidated-extended.component.html',
  styleUrls: ['./consolidated-extended.component.css']
})
export class ConsolidatedExtendedComponent implements OnInit {

  displayedColumns: string[] = ['date', 'startBalance', 'incomeAll', 'outcomeAll', 'endBalance'];
  dataSource: IConsolidatedReportExtended[] = [];

  public balances: IConsolidatedReportExtended[] = [];
  public accounts: IAccount[] = [];
 // public selectedAccount: IAccount;

  agentId = new FormControl();
  public agentAutoComplete$: Observable<any> = null;
  columnDefinitionsForExcel = [];
  dateFrom = new FormControl(new Date(new Date().setDate(new Date().getDate() - 30)));
  dateTo = new FormControl(new Date());
  isCompleted: boolean = false;
  // глобальные параметры валюты приложения
  globalLocation = environment.globalLocation;
  globalCurrencyCode = environment.globalCurrencyCode;
  constructor(
    private authSrv: AuthService,
    private accountSrv: AccountService,
    private exportSrv: ExportService,
    private dictionarySrv: DictionaryService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private reportSrv: ReportService,
    private agentSrv: AgentService
  ) { }

  lookup(value: any): Observable<any> {  
    if (typeof value === 'object')
    {
      value = value.title;
    } 
    
    return this.agentSrv.search(value.toLowerCase()).pipe(
        // map the item property of the results as our return object
        map(results => results),
        // catch errors
        catchError(_ => {
          return of(null);
        })
      );
    }
    
  ngOnInit(): void {
    this.load();

    this.agentAutoComplete$ = this.agentId.valueChanges.pipe(
        startWith(''),
        // delay emits
        debounceTime(300),
        // use switch map so as to cancel previous subscribed events, before creating new once
        switchMap(value => {
          if (value !== '') {
            // lookup from agents
            return this.lookup(value);
          } else {
            // if no value is pressent, return null
            return of(null);
          }
        })
      );
  }
  load() {
    this.getAccounts();
  }
  getReport(userId = 0) {
    this.isCompleted = false;

    var curUserId = null;
    if (userId != 0) {
        curUserId = userId;
    }
    else if (this.agentId.value != null && this.agentId.value != undefined) {
        curUserId = this.agentId.value.code;
    }
    this.columnDefinitionsForExcel = [];
    this.dataSource = [];
    this.reportSrv.balanceExtended(curUserId, this.dateFrom.value, this.dateTo.value).subscribe(data => {
        this.dataSource = data;
        if(this.dataSource[0]){
          this.columnDefinitionsForExcel = this.exportSrv.getAllColumnsExcel(Object.keys(this.dataSource[0]));
        }
      },
      error => {
        if (error.status === 404) {
          let message = this.translate.instant('no-data');
          this.openSnackBar(message, 'x');
        }
        else
          if (error.status === 400) {
            let message = this.translate.instant('bad-request');
            this.openSnackBar(message, 'x');
          }
          else
            if (error.status === 500) {
              let message = this.translate.instant('service-is-temporarily-unavailable');
              this.openSnackBar(message, 'x');
            }
            else {
              let message = this.translate.instant('an-error-occurred-while-processing');
              this.openSnackBar(message, 'x');
            }
      }
    ).add(() => { this.isCompleted = true });

  }

  getAccounts() {
    this.accounts = [];
    this.dictionarySrv.agents().subscribe(data => {
        this.accounts = data;
        if(data != null){
          this.agentId.setValue(this.accounts[0]);
          this.getReport(data[0]['code']);
        }
      },
      error => {
        if (error.status === 404) {
          let message = this.translate.instant('no-data');
          this.openSnackBar(message, 'x');
        }
        else
          if (error.status === 400) {
            let message = this.translate.instant('bad-request');
            this.openSnackBar(message, 'x');
          }
          else
            if (error.status === 500) {
              let message = this.translate.instant('service-is-temporarily-unavailable');
              this.openSnackBar(message, 'x');
            }
            else {
              let message = this.translate.instant('an-error-occurred-while-processing');
              this.openSnackBar(message, 'x');
            }
      }
      );
  }

  displayFn(account: IAccount): string {
    return account && account.title ? account.title : '';
  }
  exportToExcel() {
    this.exportSrv.exportToExcel(this.dataSource, 'Report', 'filename.xlsx', this.columnDefinitionsForExcel);
  }
  
  public dateFromChanged()
  {
    this.getReport(0);
  }

  public dateToChanged()
  {
    this.getReport(0);
  }

  public accountChanged()
  {
    this.getReport(0);
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  clearSearch(){
      this.agentId.setValue("");
        this.getReport();   
  }
}
