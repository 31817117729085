<div fxLayout="column" fxLayoutAlign="center center">
    <h1 class="mat-headline">{{'refund-requests'| translate}}</h1>
</div>
<mat-progress-bar mode="indeterminate" *ngIf="!isCompleted"></mat-progress-bar>
<div fxLayout="column" fxLayoutAlign="none" *ngIf="isCompleted">
    <div fxLayoutAlign="center center" fxLayout="row"  fxLayout.xs="column" fxLayoutGap="10px" fxLayoutGap.xs="0">
        <mat-form-field fxFlex="20"  fxFlex.xs="60">
            <mat-label>{{'id' | translate}}</mat-label>
            <input matInput [formControl]="id" placeholder="{{'id' | translate}}" (change)="searchRefundRequests()" type="number">
        </mat-form-field>

        <mat-form-field fxFlex="20" fxFlex.xs="60">
            <mat-select placeholder="{{'refund-status-name' | translate}}" [formControl]="statusId" (selectionChange)="searchRefundRequests()">
                <mat-option [value]="">
                    {{ 'choose-status' | translate}}
                </mat-option>
                <mat-option *ngFor="let item of statuses" [value]="item.code">
                    {{ item.title }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field fxFlex="20"  fxFlex.xs="60">
            <mat-label>{{'created-date-from' | translate}}</mat-label>
            <input matInput [matDatepicker]="pickerFrom" (click)="pickerFrom.open()" [formControl]="dateFrom" placeholder="dateFrom" (dateChange)="searchRefundRequests()" readonly>
            <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
            <mat-datepicker #pickerFrom></mat-datepicker>
        </mat-form-field>

        <mat-form-field fxFlex="20" fxFlex.xs="60">
            <mat-label>{{'created-date-to' | translate}}</mat-label>
            <input matInput [matDatepicker]="pickerTo"  (click)="pickerTo.open()" [formControl]="dateTo" placeholder="{{'dateFrom'| translate}}" (dateChange)="searchRefundRequests()" readonly>
            <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
            <mat-datepicker #pickerTo></mat-datepicker>
        </mat-form-field>
    
        <span>
            <button mat-icon-button color="primary" (click)="searchRefundRequests()">
                <mat-icon>search</mat-icon>
            </button>
        
            <button mat-icon-button color="primary" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="mainMenuButton">
                <mat-icon>settings</mat-icon>
            </button>
        </span>

        <mat-menu #mainMenuButton="matMenu">
            <div class="table-column-list-block">
                <section class="button-download">
                    <button mat-raised-button color="primary"  (click)="exportToExcel()" >
                        {{'export' | translate}}
                        <mat-icon>save_alt</mat-icon>
                    </button>   
                </section>
                <span (click)="$event.stopPropagation()" *ngFor="let element of columnDefinitions;index as i">
                    <mat-slide-toggle color="primary" [(ngModel)]="element.isShow"></mat-slide-toggle>
                    {{element.label}}
                    <br>
                </span>
            </div>
        </mat-menu>
    </div>


    <table mat-table [dataSource]="dataSource" class="mat-elevation-z0 agents-table">

        <!-- id Column -->
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> {{'id' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>
        <!-- createdAt Column -->
        <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef> {{'created' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.createdAt | date:"dd/MM/yyyy HH:mm:ss"}} </td>
        </ng-container>
        <!-- transactionId Column -->
        <ng-container matColumnDef="transactionInfo">
            <th mat-header-cell *matHeaderCellDef> {{'transaction-info' | translate}} </th>
            <td mat-cell *matCellDef="let element"> 
                <span class="mat-small mat-small-font">№ {{element.transactionId}}</span> <br>
                <span class="mat-small mat-small-font">{{'created' | translate}}: {{element.transactionCreatedAt | date:"dd/MM/yyyy HH:mm:ss"}}</span> <br>
                <span class="mat-small">{{element.serviceName}} </span>
                <div *ngIf="element.parameters">
                    <span class="mat-small" *ngFor="let parameter of element.parameters | keyvalue" style="max-width: 250px; word-break: break-all; display: block;">
                        {{parameter.key.toLowerCase() | translate}} - {{parameter.value}}
                    </span>
                </div>
            </td>
        </ng-container>

        <!-- agentInfo Column -->
        <ng-container matColumnDef="agentInfo">
            <th mat-header-cell *matHeaderCellDef> {{'agent-info' | translate}} </th>
            <td mat-cell *matCellDef="let element">
                <span class="mat-small">{{element.agentName }}</span> <br>
                <span class="mat-small mat-small-font">{{element.phone }}</span>  <br>
                <span class="mat-small mat-small-font">{{element.cityName }}</span>
            </td>
        </ng-container>
        
        <!-- transactionAmount Column -->
        <ng-container matColumnDef="transactionAmount">
            <th mat-header-cell *matHeaderCellDef> {{'transaction-amount' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.transactionAmount | number : '1.2-2'}} </td>
        </ng-container>
        
        <!-- canceledAmount Column -->
        <ng-container matColumnDef="canceledAmount">
            <th mat-header-cell *matHeaderCellDef> {{'canceled-amount' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.canceledAmount | number : '1.2-2'}} </td>
        </ng-container>

        <!-- agentComment Column -->
        <ng-container matColumnDef="agentComment">
            <th mat-header-cell *matHeaderCellDef> {{'agent-comment' | translate}} </th>
            <td mat-cell *matCellDef="let element"><span class="mat-small"> {{element.agentComment}}</span> </td>
        </ng-container>

        <!-- userComment Column -->
        <ng-container matColumnDef="userComment">
            <th mat-header-cell *matHeaderCellDef> {{'user-comment' | translate}} </th>
            <td mat-cell *matCellDef="let element"> <span class="mat-small">{{element.userComment}} </span> </td>
        </ng-container>

        <!-- statusName Column -->
        <ng-container matColumnDef="statusName">
            <th mat-header-cell *matHeaderCellDef> {{'refund-status-name' | translate}} </th>
            <td mat-cell *matCellDef="let element" class="status-td">
                <button mat-raised-button class="badge-status badge-{{refundStatusesColors[element.statusId]}}">{{element.statusName}} </button>             
            </td>
        </ng-container>

        <!-- canceledAt Column -->
        <ng-container matColumnDef="canceledAt">
            <th mat-header-cell *matHeaderCellDef> {{'canceled-at' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.canceledAt | date:"dd/MM/yyyy HH:mm:ss"}} </td>
        </ng-container>

        <!-- userName Column -->
        <ng-container matColumnDef="userName">
            <th mat-header-cell *matHeaderCellDef> {{'user-name' | translate}} </th>
            <td mat-cell *matCellDef="let element"><span class="mat-small"> {{element.userName}} </span> </td>
        </ng-container>

        <!-- updatedAt Column -->
        <ng-container matColumnDef="updatedAt">
            <th mat-header-cell *matHeaderCellDef> {{'updatedAt' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.updatedAt | date:"dd/MM/yyyy HH:mm:ss"}} </td>
        </ng-container>

        <!-- paynetReference Column -->
        <ng-container matColumnDef="paynetReference">
            <th mat-header-cell *matHeaderCellDef> {{'paynet-reference' | translate}} </th>
            <td mat-cell *matCellDef="let element"><span class="mat-small"> {{element.paynetReference}} </span> </td>
        </ng-container>
        
        <!-- action Column -->
        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> {{'settings' | translate}} </th>
            <td mat-cell *matCellDef="let element">
                <section class="template-section">
                    <button mat-icon-button color="primary" [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu" class="refund-request-action-mat-menu">
                        <button *ngIf="element.statusId < 3" mat-menu-item (click)="openRefundEditDialog(element)">
                            <mat-icon>edit</mat-icon>
                            <span>{{'edit-refund' | translate}}</span>
                        </button>
                    </mat-menu>
                </section>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="getDisplayedColumns(); sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: getDisplayedColumns();"></tr>
    </table>
</div>