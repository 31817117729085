<div fxLayout="column" fxLayoutAlign="center center">    
    <h1 class="mat-headline">{{'providers-report'| translate}}</h1>
    <h4 class="mat-subheading-1">{{'providers-report-description' | translate}}</h4>  
</div>
<div class="container" fxLayout fxLayout.xs="column" fxLayoutAlign="center center" fxLayoutGap="10px"
    fxLayoutGap.xs="0">
    <mat-form-field fxFlex="40">
        <input type="text" placeholder="{{'agent'| translate}}" matInput [formControl]="agentId"
            [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" autoActiveFirstOption (optionSelected)="accountChanged()">
            <mat-option *ngFor="let option of agentAutoComplete$ | async; let index = index" [value]="option">
                {{option.title}}
            </mat-option>
        </mat-autocomplete>
        <button *ngIf="agentId != null" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
            <mat-icon>close</mat-icon>
          </button>
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{'dateFrom' | translate}}</mat-label>
        <input matInput [matDatepicker]="pickerFrom" (click)="pickerFrom.open()" [formControl]="dateFrom" placeholder="dateFrom" (dateChange)="dateFromChanged()" readonly>
        <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
        <mat-datepicker #pickerFrom></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
        <mat-label>{{'dateTo' | translate}}</mat-label>
        <input matInput [matDatepicker]="pickerTo"  (click)="pickerTo.open()" [formControl]="dateTo" placeholder="{{'dateFrom'| translate}}" (dateChange)="dateToChanged()" readonly>
        <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
        <mat-datepicker #pickerTo></mat-datepicker>
    </mat-form-field>

    <button mat-icon-button color="primary" (click)="getReport()">
        <mat-icon>search</mat-icon>
    </button>

    <button mat-icon-button color="primary" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="mainMenuButtonExcel" [disabled]="dataSource?.length < 1">
        <mat-icon>save_alt</mat-icon>
    </button>
    <mat-menu #mainMenuButtonExcel="matMenu">
        <div class="table-column-list-block">
            <section class="button-download">
                <button mat-raised-button color="primary"  (click)="exportToExcel()" >
                    {{'export' | translate}}
                    <mat-icon>save_alt</mat-icon>
                </button>   
            </section>
            <span (click)="$event.stopPropagation()" *ngFor="let element of columnDefinitionsForExcel;index as i">
                <mat-slide-toggle color="primary" (change)="element.isShow = !element.isShow" [checked]="element.isShow == true"></mat-slide-toggle>
                {{element.label}}
                <br>
            </span>
        </div>
    </mat-menu>
</div>
<mat-progress-bar mode="indeterminate" *ngIf="!isCompleted"></mat-progress-bar>
<div fxLayout="column" fxLayoutAlign="none">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z0">

        <!-- serviceName Column -->
        <ng-container matColumnDef="serviceName">
            <th mat-header-cell *matHeaderCellDef> {{'serviceName' | translate}} </th>
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element"> {{element.serviceName }} </td>
            <td mat-footer-cell *matFooterCellDef>{{'total' | translate}}</td>
        </ng-container>

        <!-- serviceId  Column -->
        <ng-container matColumnDef="serviceId">
            <th mat-header-cell *matHeaderCellDef> {{'serviceId' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.serviceId}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <!-- successCount Column -->
        <ng-container matColumnDef="successCount">
            <th mat-header-cell *matHeaderCellDef> {{'successTransactionCount' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.successCount}} </td>
            <td mat-footer-cell *matFooterCellDef>{{getTotalSuccesTransaction()}} </td>
        </ng-container>

        <!-- acceptedAmount Column -->
        <ng-container matColumnDef="acceptedAmount">
            <th mat-header-cell *matHeaderCellDef> {{'acceptedAmount' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.acceptedAmount | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </td>
            <td mat-footer-cell *matFooterCellDef>{{getTotalAccepted() | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </td>
        </ng-container>

        <!-- userAmount Column -->
        <ng-container matColumnDef="userAmount">
            <th mat-header-cell *matHeaderCellDef> {{'userAmount' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.userAmount | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </td>
            <td mat-footer-cell *matFooterCellDef>{{getTotalUserAmount() | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </td>
        </ng-container>

        <!-- upperCommissionAmount Column -->
        <ng-container matColumnDef="upperCommissionAmount">
            <th mat-header-cell *matHeaderCellDef> {{'upperCommissionAmount' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.upperCommissionAmount | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </td>
            <td mat-footer-cell *matFooterCellDef>{{getTotalUpper() | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </td>
        </ng-container>

        <!-- lowerCommissionAmount Column -->
        <ng-container matColumnDef="lowerCommissionAmount">
            <th mat-header-cell *matHeaderCellDef> {{'lowerCommissionAmount' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.lowerCommissionAmount | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </td>
            <td mat-footer-cell *matFooterCellDef>{{getTotalLower() | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </td>
        </ng-container>

        <!-- itoCommissionAmount Column -->
        <ng-container matColumnDef="itoCommissionAmount">
            <th mat-header-cell *matHeaderCellDef> {{'itoCommissionAmount' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.itoCommissionAmount | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </td>
            <td mat-footer-cell *matFooterCellDef>{{getTotalITO() | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </td>
        </ng-container>

        <!-- canceledCount Column -->
        <ng-container matColumnDef="canceledCount">
            <th mat-header-cell *matHeaderCellDef> {{'canceledTransactionCount' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.canceledCount}} </td>
            <td mat-footer-cell *matFooterCellDef>{{getTotalCanceledTransaction()}} </td>
        </ng-container>
        
        <!-- canceledAmount Column -->
        <ng-container matColumnDef="canceledAmount">
            <th mat-header-cell *matHeaderCellDef> {{'canceledAmount' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.canceledAmount | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </td>
            <td mat-footer-cell *matFooterCellDef>{{getTotalCanceledAmount() | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>     
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
    </table>
</div>