import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RatesService {
  host: string;
  
  constructor(private http: HttpClient) {
    this.host = environment.api_url + '/api/tariffs'
  }

  rates(tariffId: number): Observable<any> {
    let url = this.host + '/' + tariffId + '/values';
    return this.http.get<any[]>(url);
  };
}
