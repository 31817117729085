<div fxLayout="column" fxLayoutAlign="center center">
    <h1 class="mat-headline">{{'refill-agent'| translate}}</h1>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="!isCompleted || isRefillProcessing"></mat-progress-bar>
  <div *ngIf="isCompleted">
    <form [formGroup]="refillForm" novalidate (ngSubmit)="update(refillForm.value)">
        <mat-dialog-content class="mat-typography">
            <h3 *ngIf="item">{{'agent' | translate}} : {{item.agent.company}}</h3>
            
            <mat-form-field fxFill>
                <input #refillSum matInput type="text" placeholder="{{'refill-sum' | translate}}" formControlName="refillSum" (keypress)="onKeypressAmountEvent($event, 'refillSum')" currencyMask>

                <mat-error *ngIf="refillForm.controls['refillSum'].hasError('required') || refillForm.controls['refillSum'].hasError('min')">
                    {{'refill-sum-is-req' | translate}}
                </mat-error>
            </mat-form-field>

            <mat-form-field fxFill>
                <input #refillSum2 matInput type="text" placeholder="{{'refill-sum-repeat' | translate}}" formControlName="refillSumRepeat" (keypress)="onKeypressAmountEvent($event, 'refillSumRepeat')" currencyMask>

                <mat-error *ngIf="f.refillSumRepeat.errors">
                    <div *ngIf="f.refillSumRepeat.errors.required || f.refillSumRepeat.errors.min">{{'refill-sum-repeat-is-req' | translate}}</div>
                    <div *ngIf="f.refillSumRepeat.errors.mustMatch">{{'refile-sum-repeat-must-match' | translate}}</div>
                </mat-error>
            </mat-form-field>

            <mat-form-field fxFill >
                <textarea matInput placeholder="{{'comment' | translate}}" formControlName="comment"></textarea>
            </mat-form-field>

            <mat-form-field style="margin-right: 10px;">
                <input matInput type="text" placeholder="{{'refill-code' | translate}}" formControlName="refillCode">

                <mat-error *ngIf="refillForm.controls['refillCode'].hasError('required')">
                    {{'refill-code-is-req' | translate}}
                </mat-error>
            </mat-form-field>

            <button mat-flat-button color="accent" type="button" (click)="getRefillCode()" [disabled]="isCodeSending">{{'get-refill-code'| translate}}</button>

        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-button mat-dialog-close>{{'cancel'| translate}}</button>
            <button mat-flat-button color="primary" type="submit" [disabled]="!refillForm.valid || isRefillProcessing"
                cdkFocusInitial>{{'to-refill'| translate}}</button>
        </mat-dialog-actions>
    </form>
  </div>