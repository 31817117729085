import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { AgentService } from 'src/app/services/agent.service';
import { AuthService } from 'src/app/services/auth.service';
import { DictionaryService } from 'src/app/services/dictionary.service';
import { PasswordManagerService } from 'src/app/services/password-manager.service';
import { AddAgentComponent } from './add-agent/add-agent.component';
import { AgentsTreeComponent } from './agents-tree/agents-tree.component';
import { RolesChangeComponent } from './roles-change/roles-change.component';
import { BlockAgentComponent } from './block-agent/block-agent.component';
import { CashOffComponent } from './cash-off/cash-off.component';
import { EditAgentComponent } from './edit-agent/edit-agent.component';
import { RefillAgentComponent } from './refill-agent/refill-agent.component';
/* import { RedefineUpperCommissionComponent } from './redefine-upper-commission/redefine-upper-commission.component'; */
import { TariffChangeComponent } from './tariff-change/tariff-change.component';

@Component({
  selector: 'app-agents',
  templateUrl: './agents.component.html',
  styleUrls: ['./agents.component.css']
})
export class AgentsComponent implements OnInit {

  agentId = new FormControl();

  isCompleted: boolean = false;
  agents: any[] = [];
  dataSource: any[] = [];

  columnDefinitions = [
    { def: 'id', isShow: false, 'label': 'ID' }, 
    { def: 'phone', isShow: true, 'label': 'Телефон' }, 
    { def: 'fio', isShow: true, label: 'ФИО' },
    { def: 'parentAgent', isShow: false, label: 'Родительский агент' },
    { def: 'cityName', isShow: true, label: 'Город'  },
    { def: 'address', isShow: false, label: 'Адрес'  },
    { def: 'created', isShow: false, label: 'Создан' },  
    { def: 'template', isShow: false, label: 'Тарифный план'  },  
    { def: 'templateId', isShow: false, label: 'Идентификатор тарифа'  },  
    { def: 'balance', isShow: true, label: 'Баланс'  },
    { def: 'lockout', isShow: false, label: 'Дата и время блокировки'  },
    { def: 'email', isShow: false, label: 'Email'  },
    { def: 'terminalId', isShow: false, label: 'ID терминала'  },
    { def: 'action', isShow: true, label: 'Действия'  }
  ];

  public agentAutoComplete$: Observable<any> = null;
  public iinAutoComplete$: Observable<any> = null;
  public phoneAutoComplete$: Observable<any> = null;

  cityId = new FormControl();
  cities = [];

  dateFrom = new FormControl();
  dateTo = new FormControl();

  constructor(public authService: AuthService,
    private translate: TranslateService,
    public snackBar: MatSnackBar,
    public agentsSrv: AgentService,
    public dialog: MatDialog,
    private agentSrv: AgentService,
    private fb: FormBuilder,
    private dictionarySrv: DictionaryService
  ) { }

  lookup(value: any): Observable<any> {  
    if (typeof value === 'object')
    {
      value = value.title;
    } 
    
    return this.agentSrv.search(value.toLowerCase()).pipe(
        // map the item property of the results as our return object
        map(results => results),
        // catch errors
        catchError(_ => {
          return of(null);
        })
      );
    }

  ngOnInit(): void {
    this.agentAutoComplete$ = this.agentId.valueChanges.pipe(
      startWith(''),
      // delay emits
      debounceTime(300),
      // use switch map so as to cancel previous subscribed events, before creating new once
      switchMap(value => {
        if (value !== '') {
          // lookup from agents
          return this.lookup(value);
        } else {
          // if no value is pressent, return null
          return of(null);
        }
      })
    );

    this.load();
  }

  load() {
    this.getCities();
    this.getAgents();
  }

  getCities() {
    this.dictionarySrv.getCities().subscribe(
      (data: any) => { this.cities = data; },
      error => console.log(error)
    )
  }

  getAgents() {
    this.isCompleted = false;
    this.agents =[];
    this.dataSource=[];

    var curAgentId = null;
    if (this.agentId.value != null && this.agentId.value != undefined) {
        curAgentId = this.agentId.value.code;
    }
    var curCityId = null;
    if (this.cityId.value != null && this.cityId.value != undefined) {
        curCityId = this.cityId.value;
    }

    this.agentsSrv.agents(curAgentId, curCityId, this.dateFrom.value,  this.dateTo.value).subscribe(
      (data: any[]) => {
        this.agents = data;
        this.dataSource = this.agents?.sort((a, b) => a.id - b.id);
      },
      error => {
        if (error.status === 404) {
          let message = this.translate.instant('not-found');
          this.openSnackBar(message, 'x');
        }
        else
          if (error.status === 400) {
            let message = this.translate.instant('an-error-occurred-while-processing');
            this.openSnackBar(message, 'x');
          }
          else
            if (error.status === 500) {
              let message = this.translate.instant('service-is-temporarily-unavailable');
              this.openSnackBar(message, 'x');
            }
            else {
              let message = this.translate.instant('an-error-occurred-while-processing');
              this.openSnackBar(message, 'x');
            }
      }
     ).add(() => { this.isCompleted = true });
}

  // View tariff change dialog
  openAgentDialog(item: any) {
    const dialogRef = this.dialog.open(TariffChangeComponent,
      { data: { agent: item } });

    dialogRef.afterClosed().subscribe(result => {
      this.getAgents();
     // console.log(`Dialog result: ${result}`);
    });
  }

  openAgentTreeDialog(item: any) {
    const dialogRef = this.dialog.open(AgentsTreeComponent,
      { data: { agent: item },
      height: '90%', });

    dialogRef.afterClosed().subscribe(result => {
      this.getAgents();
    });
  }

  openRolesDialog(item: any) {
    const dialogRef = this.dialog.open(RolesChangeComponent,
      { data: { agent: item }});

    dialogRef.afterClosed().subscribe(result => {
      // this.getAgents();
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  openAgentReturnDialog(item: any) {
    const dialogRef = this.dialog.open(CashOffComponent,
      { data: { agent: item } });
  }

  openAgentRefillDialog(item: any) {
    const dialogRef = this.dialog.open(RefillAgentComponent,
      { data: { agent: item } });
  }

  openAddSubAgentDialog(item: any) {
    const dialogRef = this.dialog.open(AddAgentComponent,
      { data: { agent: item },
      autoFocus: false,
      maxHeight: '95vh',
      disableClose: true 
    });
  }

  openAgentEditDialog(item:any){
    const dialogRef = this.dialog.open(EditAgentComponent,
      { data: { agent: item } });

    dialogRef.afterClosed().subscribe(result => {
      this.getAgents();
    });
  }

  searchAgents(){
    this.getAgents();
  }

  displayFn(account: any): string {
    return account && account.title ? account.title : '';
  }

  displayFnIin(account: any): string {
    return account && account.iin ? account.iin : '';
  }

  displayFnPhone(account: any): string {
    return account && account.phone ? account.phone : '';
  }

  clearSearch(){
      this.agentId.setValue("");
      this.getAgents();   
  }

  blockUser(item: any){
      const dialogRef = this.dialog.open(BlockAgentComponent,
        { data: { agent: item } });

      dialogRef.afterClosed().subscribe(result => {
        this.getAgents();
      });
  }
  
  unblockUser(item: any){
    this.agentSrv.unblockUser(item.id)
        .subscribe(
          data => {
            const dialogRef = this.dialog.closeAll();
            let message = this.translate.instant('successfully-unblocked-agent');
            this.openSnackBar(message, 'x');
            this.getAgents();   
          },
          error => {

            if (error.status === 500) {
              let message = this.translate.instant('service-is-temporarily-unavailable');
              this.openSnackBar(message, 'x');
            }
            else {
              let message = this.translate.instant('an-error-occurred-while-processing');
              this.openSnackBar(message, 'x');
            }
          }
        ).add(() => {
          //http call ends
        });
  }

  getDisplayedColumns(): string[] {
    return this.columnDefinitions
      .filter(cd => cd.isShow == true)
      .map(cd => cd.def);
  }

}

