import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MustMatch } from 'src/app/helpers/must-match';
import { PasswordManagerService } from 'src/app/services/password-manager.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  returnUrl: string = "/login";
  hide = true;
  resetpasswordForm = this.fb.group({
    code: [null, [Validators.required]],
    email: [null, [Validators.required, Validators.email]],
    password: [null, [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')]],
    passwordConfirm: [null, Validators.required],
  },
    {
      validator: MustMatch('password', 'passwordConfirm')
    });


  constructor(private fb: FormBuilder,
    private translate: TranslateService,  
    private router: Router,
    public snackBar: MatSnackBar,
    private passwordManagerService: PasswordManagerService) { }

  ngOnInit(): void {
  }

   // convenience getter for easy access to form fields
   get f() { return this.resetpasswordForm.controls; }

   public hasError = (controlName: string, errorName: string) => {
     return this.resetpasswordForm.controls[controlName].hasError(errorName);
   }

   recovery(): void {
    if (this.resetpasswordForm.valid) {
      //http call starts
      this.passwordManagerService.resetpassword(this.resetpasswordForm.controls['code'].value,this.resetpasswordForm.controls['email'].value,this.resetpasswordForm.controls['password'].value)
        .subscribe(
          data => {
            this.router.navigate([this.returnUrl]);
          },
          error => {

            if (error.status === 500) {
              let message = this.translate.instant('service-is-temporarily-unavailable');
              this.openSnackBar(message, 'x');
            }
            else {
              let message = this.translate.instant('an-error-occurred-while-processing');
              this.openSnackBar(message, 'x');
            }
          }
        ).add(() => {
          //http call ends
        });
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

}
