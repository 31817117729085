import { BrowserModule } from '@angular/platform-browser';
import {  NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConsolidatedComponent } from './components/reports/consolidated/consolidated.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { I18nModule } from './i18n/i18n.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ConsolidatedExtendedComponent } from './components/reports/consolidated-extended/consolidated-extended.component';
import { AccountBalanceComponent } from './components/reports/account-balance/account-balance.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatProgressBarModule } from '@angular/material/progress-bar'
import {MatTreeModule} from '@angular/material/tree';

import { registerLocaleData } from '@angular/common';
import localeKz from '@angular/common/locales/ru-KZ';
import localeKg from '@angular/common/locales/ru-KG';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { LoginComponent } from './components/login/login.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { HomeComponent } from './components/home/home.component';
import { ProfilesComponent } from './components/reports/profiles/profiles.component';
import { UnauthorizedComponent } from './components/pages/unauthorized/unauthorized.component';
import { IdleProfilesComponent } from './components/reports/idle-profiles/idle-profiles.component';
import { CategoryComponent } from './components/settings/category/category.component';
import { ServiceComponent } from './components/settings/service/service.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ProvidersReportComponent } from './components/reports/providers-report/providers-report.component';
import { IncomeReportComponent } from './components/reports/income-report/income-report.component';
import { AgentsComponent } from './components/settings/agents/agents.component';
import { TariffChangeComponent } from './components/settings/agents/tariff-change/tariff-change.component'
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RatesComponent } from './components/rates/rates.component';
import { UpperCommissionComponent } from './components/rates/upper-commission/upper-commission.component';
import { AllRatesComponent } from './components/all-rates/all-rates.component';
import { PaidLowerCommissionReportComponent } from './components/reports/paid-lower-commission-report/paid-lower-commission-report.component';
import { CurrentBalanceComponent } from './components/reports/current-balance/current-balance.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { TransactionsComponent } from './components/reports/transactions/transactions.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CashOffComponent } from './components/settings/agents/cash-off/cash-off.component';
import { CurrencyMaskInputMode, NgxCurrencyModule } from "ngx-currency";
import { AgentsReportComponent } from './components/reports/agents-report/agents-report.component';
import { RefillAgentComponent } from './components/settings/agents/refill-agent/refill-agent.component';
import { AddAgentComponent } from './components/settings/agents/add-agent/add-agent.component';
import { RecoveryComponent } from './components/user/recovery/recovery.component';
import { ResetPasswordComponent } from './components/user/reset-password/reset-password.component';
import { PagesComponent } from './components/settings/pages/pages.component';
import { EditPageComponent } from './components/settings/pages/edit-page/edit-page.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ConfirmDeleteComponent } from './components/custom-dialogs/confirm-delete/confirm-delete.component';
import { TransactionDetailsComponent } from './components/reports/transactions/transaction-details/transaction-details.component';
import { RegionReportComponent } from './components/reports/region-report/region-report.component';
import { EditAgentComponent } from './components/settings/agents/edit-agent/edit-agent.component';
import { BlockAgentComponent } from './components/settings/agents/block-agent/block-agent.component';
import { TariffsComponent } from './components/settings/tariff/tariffs/tariffs.component';
import { EditTariffComponent } from './components/settings/tariff/edit-tariff/edit-tariff/edit-tariff.component';
import { AddTariffComponent } from './components/settings/tariff/add-tariff/add-tariff/add-tariff.component';
import { UpperCommissionsComponent as UpperCommissionsList } from './components/settings/upper-commissions/upper-commissions.component';
import { UpperCommissionsEditComponent } from './components/settings/upper-commissions/upper-commissions-edit/upper-commissions-edit/upper-commissions-edit.component';
import { EditTariffConfirmComponent } from './components/settings/tariff/edit-tariff/edit-tariff-confirm/edit-tariff-confirm/edit-tariff-confirm.component';
import { NewsComponent } from './components/settings/news/news.component';
import { EditNewsComponent } from './components/settings/news/edit-news/edit-news.component';
import { ViewTariffComponent } from './components/settings/tariff/view-tariff/view-tariff.component';
import { AgentsTreeComponent } from './components/settings/agents/agents-tree/agents-tree.component';
import { environment } from 'src/environments/environment';
import { TariffRatesTreeComponent } from './components/all-rates/tariff-rates-tree/tariff-rates-tree.component';
import { WhatsappReportComponent } from './components/reports/whatsapp-report/whatsapp-report.component';
import { RefundRequestsComponent } from './components/refund-requests/refund-requests.component';
import { EditRefundRequestComponent } from './components/refund-requests/edit-refund-request/edit-refund-request.component';
import { LowerCommissionPayComponent } from './components/settings/lower-commission-pay/lower-commission-pay.component';
import { MatCheckbox, MatCheckboxModule } from '@angular/material/checkbox';
import { ConfirmDialogComponent } from './components/settings/lower-commission-pay/confirm-dialog/confirm-dialog.component';
import { DynamicButtonComponent } from './components/settings/lower-commission-pay/dynamic-button/dynamic-button.component';
import { RolesChangeComponent } from './components/settings/agents/roles-change/roles-change.component';

// registerLocaleData(localeKz, 'kz');
registerLocaleData(localeKg, 'kg');

export const customCurrencyMaskConfig = {
  align: "right",
  allowNegative: false,
  allowZero: true,
  decimal: ".",
  precision: 2,
  prefix: "",
  suffix: " " + environment.globalCurrencySymbol,
  thousands: " ",
  nullable: false,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.NATURAL
};


@NgModule({
  declarations: [
    AppComponent,
    ConsolidatedComponent,
    ConsolidatedExtendedComponent,
    AccountBalanceComponent,
    LoginComponent,
    HomeComponent,
    ProfilesComponent,
    UnauthorizedComponent,
    IdleProfilesComponent,
    CategoryComponent,
    ServiceComponent,
    ProvidersReportComponent,
    IncomeReportComponent,
    AgentsComponent,
    TariffChangeComponent,
    RatesComponent,
    UpperCommissionComponent,
    AllRatesComponent,
    PaidLowerCommissionReportComponent,
    CurrentBalanceComponent,
    TransactionsComponent,
    CashOffComponent,
    AgentsReportComponent,
    RefillAgentComponent,
    AddAgentComponent,
    RecoveryComponent,
    ResetPasswordComponent,
    PagesComponent,
    EditPageComponent,
    ConfirmDeleteComponent,
    TransactionDetailsComponent,
    RegionReportComponent,
    EditAgentComponent,
    BlockAgentComponent,
    TariffsComponent,
    EditTariffComponent,
    AddTariffComponent,
    UpperCommissionsList,
    UpperCommissionsEditComponent,
    EditTariffConfirmComponent,
    NewsComponent,
    EditNewsComponent,
    ViewTariffComponent,
    AgentsTreeComponent,
    TariffRatesTreeComponent,
    WhatsappReportComponent,
    RefundRequestsComponent,
    EditRefundRequestComponent,
    LowerCommissionPayComponent,
    ConfirmDialogComponent,
    DynamicButtonComponent,
    RolesChangeComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    I18nModule,
    MatCheckboxModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    ReactiveFormsModule,
    MatAutocompleteModule, LayoutModule, MatToolbarModule, MatSidenavModule, MatListModule,
    FlexLayoutModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatCardModule,
    FormsModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    MatDialogModule,
    MatSelectModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatTooltipModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    AngularEditorModule,
    MatTreeModule
  ],
  exports: [MatDatepickerModule],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'ru-KG' },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    MatDatepickerModule,
    MatNativeDateModule],
  bootstrap: [AppComponent]
})
export class AppModule { }
