<div fxLayout="column" fxLayoutAlign="center center">
    <h1 class="mat-headline">{{'lower-commission-pay-report'| translate}}</h1>
    <p>
        <span *ngIf="selectedMonth == 0 && selectedYear == 0">
            {{'period'| translate}}: {{'all-time' | translate}}
        </span>
        <span *ngIf="selectedMonth > 0 && selectedYear > 0">
            {{'period'| translate}}: {{getMonths()[selectedMonth] | translate}} {{selectedYear}}
        </span>
    </p>
</div>
<mat-progress-bar mode="indeterminate" *ngIf="!isCompleted"></mat-progress-bar>

<div *ngIf="isCompleted">
    <div class="month-year-datepicker" fxLayout fxLayout.xs="column" fxLayoutAlign="right center" fxLayoutGap="10px" fxLayoutGap.xs="0">
        <mat-form-field>
            <mat-label>{{'month' | translate}}</mat-label>
            <mat-select matNativeControl [formControl]="month">
                <mat-option *ngFor="let month of getMonths(); let i = index" value="{{i}}">{{ month | translate }}</mat-option>
            </mat-select>
        </mat-form-field>
    
        <mat-form-field>
            <mat-label>{{ 'year' | translate}}</mat-label>
            <mat-select matNativeControl [formControl]="year">
                <mat-option value="0">{{ 'choose-year' | translate }}</mat-option>
                <mat-option *ngFor="let year of getYear()" value="{{year}}">{{ year }}</mat-option>
            </mat-select>
        </mat-form-field>

        <button mat-icon-button color="primary" (click)="filterAgentsNk()">
            <mat-icon>search</mat-icon>
        </button>
    </div>
    
    <button mat-raised-button color="primary" class="button lower-commission-pay-btn" (click)="openConfirmDialog()" [disabled]="isEmptyAgentsCount()">{{'lower-commission-pay' | translate}}</button>
    <mat-card fxLayout="row" class="mat-card-header">
        <mat-card fxFlex="5%" fxLayoutAlign="center center" class="mat-th"></mat-card>
        <mat-card fxFlex="55%" fxLayoutAlign="center center" class="mat-th">{{'fname' | translate}}</mat-card>
        <mat-card fxFlex="10%" fxLayoutAlign="center center" class="mat-th">{{'balance' | translate }}</mat-card>
        <mat-card fxFlex="10%" fxLayoutAlign="center center" class="mat-th">{{'expected-income-lower-commission-amount'
            | translate }}</mat-card>
        <mat-card fxFlex="10%" fxLayoutAlign="center center" class="mat-th">{{'expected-outcome-lower-commission-amount'
            | translate }}</mat-card>
        <mat-card fxFlex="10%" fxLayoutAlign="center center" class="mat-th">{{'expected-delta-lower-commission-amount' |
            translate }}</mat-card>
    </mat-card>

    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="agents-mat-tree">
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
            <mat-card fxLayout="row" fxFill>
                <mat-card fxFlex="5%" fxLayoutAlign="center center" class="mat-td">
                    <mat-checkbox class="checkBox" (change)="agentCheckboxClick($event, node)"
                        [checked]="getAgentCheckedStatus(node)"></mat-checkbox>
                </mat-card>
                <mat-card fxFlex="55%" [style.padding-left.px]="[ 30 * (node.level+1)]">
                    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
                        <mat-icon class="mat-icon-rtl-mirror">
                        </mat-icon>
                        <span class="mat-body">
                            {{node.lname}} {{node.fname}} <span class="mat-small">({{node.phone}})</span>
                        </span>
                    </button>
                </mat-card>
                <mat-card fxFlex="10%" fxLayoutAlign="center center" class="mat-td">{{node.balance | number : '1.2-2' }}
                </mat-card>
                <mat-card fxFlex="10%" fxLayoutAlign="center center" class="mat-td">{{node.incomeNk | number : '1.2-2'
                    }}</mat-card>
                <mat-card fxFlex="10%" fxLayoutAlign="center center" class="mat-td">{{node.outcomeNk | number : '1.2-2'
                    }}</mat-card>
                <mat-card fxFlex="10%" fxLayoutAlign="center center" class="mat-td">{{node.deltaNk | number : '1.2-2' }}
                </mat-card>
            </mat-card>
        </mat-tree-node>

        <!-- This is the tree node template for expandable nodes -->
        <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
            <mat-card fxLayout="row" fxFill>
                <mat-card fxFlex="5%" fxLayoutAlign="center center" class="mat-td">
                    <mat-checkbox class="checkBox" (change)="agentCheckboxClick($event, node)"
                        [checked]="getAgentCheckedStatus(node)"></mat-checkbox>
                </mat-card>
                <mat-card fxFlex="55%" [style.padding-left.px]="[ 30 * (node.level+1)]">
                    <br />
                    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
                        <mat-icon class="mat-icon-rtl-mirror">
                            {{treeControl.isExpanded(node) ? 'remove' : 'add'}}
                        </mat-icon>
                        <span class="mat-body">
                            <b>{{node.lname}} {{node.fname}} </b> <span class="mat-small">({{node.phone}})</span>


                        </span>
                    </button>
                    <br>
                    <app-dynamic-button (childClickEvent)="setAgentsByTree(node, $event)"
                        [isName]="setButtonStatus(node)"></app-dynamic-button>
                </mat-card>
                <mat-card fxFlex="10%" fxLayoutAlign="center center" class="mat-td">{{node.balance | number : '1.2-2' }}
                </mat-card>
                <mat-card fxFlex="10%" fxLayoutAlign="center center" class="mat-td">{{node.incomeNk | number : '1.2-2'
                    }}</mat-card>
                <mat-card fxFlex="10%" fxLayoutAlign="center center" class="mat-td">{{node.outcomeNk | number : '1.2-2'
                    }}</mat-card>
                <mat-card fxFlex="10%" fxLayoutAlign="center center" class="mat-td">{{node.deltaNk | number : '1.2-2' }}
                </mat-card>
            </mat-card>
        </mat-tree-node>
    </mat-tree>
</div>