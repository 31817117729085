<div fxLayout="column" fxLayoutAlign="center center">
    <h1 class="mat-headline">{{'paid-lower-commission-report'| translate}}</h1>
</div>
<div fxLayout="column" fxLayoutAlign="none">
    <form novalidate>
        <div fxLayoutAlign="center center">
            <mat-form-field fxFlex="40">
                <input type="text" placeholder="{{'agent'| translate}}" matInput [formControl]="selectedAgent"
                    [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" autoActiveFirstOption (optionSelected)="updateAllPaidLowerCommissions()">
                    <mat-option *ngFor="let option of agentAutoComplete$ | async; let index = index" [value]="option">
                        {{option.title}}
                    </mat-option>
                </mat-autocomplete>
                <button *ngIf="selectedAgent != null" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
                    <mat-icon>close</mat-icon>
                  </button>
            </mat-form-field>

            <mat-form-field fxFlex="40">
                <input type="text" placeholder="{{'selectService'| translate}}" matInput [formControl]="selectedService"
                    [matAutocomplete]="auto2">
                <mat-autocomplete #auto2="matAutocomplete" [displayWith]="displayFnService" autoActiveFirstOption (optionSelected)="updateAllPaidLowerCommissions()">
                    <mat-option *ngFor="let service of serviceAutoComplete$ | async; let index = index" [value]="service">
                        {{service.alias}}
                    </mat-option>
                </mat-autocomplete>
                <button *ngIf="selectedService != null" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="clearSearchService()">
                    <mat-icon>close</mat-icon>
                  </button>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{'transactionDateFrom' | translate}}</mat-label>
                <input matInput [matDatepicker]="pickerFrom" (click)="pickerFrom.open()" [formControl]="transactionDateFrom" placeholder="{{'transactionDateFrom'| translate}}" (dateChange)="updateAllPaidLowerCommissions()" readonly>
                <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                <mat-datepicker #pickerFrom></mat-datepicker>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{'transactionDateTo' | translate}}</mat-label>
                <input matInput [matDatepicker]="pickerTo"  (click)="pickerTo.open()" [formControl]="transactionDateTo" placeholder="{{'transactionDateTo'| translate}}" (dateChange)="updateAllPaidLowerCommissions()" readonly>
                <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                <mat-datepicker #pickerTo></mat-datepicker>
            </mat-form-field>

            <button mat-icon-button color="primary" (click)="updateAllPaidLowerCommissions()">
                <mat-icon>search</mat-icon>
            </button>
            
            <button mat-icon-button color="primary" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="mainMenuButtonExcel" [disabled]="dataSource?.length < 1">
                <mat-icon>save_alt</mat-icon>
            </button>
            <mat-menu #mainMenuButtonExcel="matMenu">
                <div class="table-column-list-block">
                    <section class="button-download">
                        <button mat-raised-button color="primary"  (click)="exportToExcel()" >
                            {{'export' | translate}}
                            <mat-icon>save_alt</mat-icon>
                        </button>   
                    </section>
                    <span (click)="$event.stopPropagation()" *ngFor="let element of columnDefinitionsForExcel;index as i">
                        <mat-slide-toggle color="primary" (change)="element.isShow = !element.isShow" [checked]="element.isShow == true"></mat-slide-toggle>
                        {{element.label}}
                        <br>
                    </span>
                </div>
            </mat-menu>
        </div>
    </form>

    <mat-progress-bar mode="indeterminate" *ngIf="!isCompleted"></mat-progress-bar>

    <table mat-table [dataSource]="datasourceTableForPagination" class="mat-elevation-z0">

        <!-- reference Column -->
        <ng-container matColumnDef="reference">
            <th mat-header-cell *matHeaderCellDef> {{'reference' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.reference}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        
        <!-- fromAccountName Column -->
        <ng-container matColumnDef="fromAccountName">
            <th mat-header-cell *matHeaderCellDef> {{'fromAccount' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.fromAccountName }} </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <!-- accountToName Column -->
        <ng-container matColumnDef="accountToName">
            <th mat-header-cell *matHeaderCellDef> {{'toAccount' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.accountToName }} </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <!-- serviceName Column -->
        <ng-container matColumnDef="serviceName">
            <th mat-header-cell *matHeaderCellDef> {{'serviceName' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.serviceName}}
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <!-- commissionAmount Column -->
        <ng-container matColumnDef="commissionAmount">
            <th mat-header-cell *matHeaderCellDef> {{'commission' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.commissionAmount | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <!-- isPaid Column -->
        <ng-container matColumnDef="isPaid">
            <th mat-header-cell *matHeaderCellDef> {{'isPaid' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.isPaid.toString() | translate}}
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <!-- paidDate Column -->
        <ng-container matColumnDef="paidDate">
            <th mat-header-cell *matHeaderCellDef> {{'paidDate' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.paidDate | date: 'dd/MM/yyyy HH:mm:ss'}}
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="getDisplayedColumns(); sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: getDisplayedColumns();"></tr>
        <tr mat-footer-row *matFooterRowDef="getDisplayedColumns()"></tr>
    </table>
    <mat-paginator [pageSize]="100" [pageSizeOptions]="[10, 25, 100, 500, 1000, 5000, 10000]"></mat-paginator>
</div>