<div fxLayout="column" fxLayoutAlign="center center">
    <h1 class="mat-headline">{{'edit-personal-data'| translate}}</h1>
</div>
<mat-progress-bar mode="indeterminate" *ngIf="!isCompleted"></mat-progress-bar>
<div *ngIf="isCompleted">
    <form [formGroup]="agentForm" novalidate (ngSubmit)="update(agentForm.value)">
        <mat-dialog-content class="mat-typography">
            <h3 *ngIf="item">{{'agent' | translate}} : {{item.agent.company}}</h3>

          <mat-form-field fxFill>
            <input matInput placeholder="{{'agentId' | translate}}" formControlName="agentId">
            <mat-error *ngIf="f.agentId.errors">
              <div *ngIf="f.agentId.errors.required"> {{'agentId-is-req'| translate}}</div>
              <div *ngIf="f.agentId.errors.pattern">{{'agentId-changed-error' | translate}}{{item.agent.id}}</div>
            </mat-error>
          </mat-form-field>

            <mat-form-field fxFill>
                <input matInput placeholder="{{'lname' | translate}}" formControlName="lname">
                <mat-error *ngIf="agentForm.controls['lname'].hasError('required')">
                    {{'lname-is-req'| translate}}
                </mat-error>
            </mat-form-field>

            <mat-form-field fxFill>
                <input matInput placeholder="{{'name' | translate}}" formControlName="fname">
                <mat-error *ngIf="agentForm.controls['fname'].hasError('required')">
                    {{'name-is-req'| translate}}
                </mat-error>
            </mat-form-field>

            <mat-form-field fxFill>
                <input matInput placeholder="{{'pname' | translate}}" formControlName="pname">
            </mat-form-field>

            <mat-form-field fxFill>
                <input matInput placeholder="{{'company' | translate}}" formControlName="company">
                <mat-error *ngIf="agentForm.controls['company'].hasError('required')">
                    {{'company-is-req'| translate}}
                </mat-error>
            </mat-form-field>

          <mat-form-field fxFill>
            <input matInput placeholder="{{'companyBin' | translate}}" formControlName="companyBin">
            <mat-error *ngIf="f.companyBin.errors">
              <div *ngIf="f.companyBin.errors.required"> {{'companyBin-is-req'| translate}}</div>
              <div *ngIf="f.companyBin.errors.pattern">{{'companyBin-changed-error' | translate}}{{item.agent.companyBin}}</div>
            </mat-error>
          </mat-form-field>

            <mat-form-field fxFill>
                <mat-select placeholder="{{'region' | translate}}" formControlName="region">
                    <mat-option *ngFor="let item of regions" [value]="item.code">
                    {{ item.title }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="agentForm.controls['region'].hasError('required')">
                    {{'region-is-req' | translate}}
                </mat-error>
            </mat-form-field>

            <mat-form-field fxFill>
                <mat-select placeholder="{{'agent-city' | translate}}" formControlName="cityId">
                    <mat-option *ngFor="let item of cities" [value]="item.code">
                    {{ item.title }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="agentForm.controls['cityId'].hasError('required')">
                    {{'city-is-req' | translate}}
                </mat-error>
            </mat-form-field>

            <mat-form-field fxFill>
                <input matInput placeholder="{{'address'| translate}}" formControlName="address">
                <mat-error *ngIf="agentForm.controls['address'].hasError('required')">
                  {{'address-is-req'| translate}}
                </mat-error>
            </mat-form-field>

          <mat-form-field fxFill>
            <input matInput placeholder="{{'phoneNumber'| translate}}" formControlName="phone">
            <mat-error *ngIf="f.phone.errors">
              <div *ngIf="f.phone.errors.required"> {{'phone-is-req'| translate}}</div>
              <div *ngIf="f.phone.errors.pattern">{{'phone-number-pattern' | translate}}</div>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFill>
            <input type="email" matInput placeholder="{{'email' | translate}}" formControlName="email">
            <mat-error *ngIf="f.email.errors">
              <div *ngIf="f.email.errors.required">{{'email-is-required' | translate}}</div>
              <div *ngIf="f.email.errors.email">{{'email-must-be-valid' | translate}}</div>
            </mat-error>
          </mat-form-field>

            <mat-form-field fxFill>
                <input matInput placeholder="{{'kkm-id'| translate}}" formControlName="kkmId">
                <mat-hint align="end">{{'agent-fiscalization-hint' | translate}}</mat-hint>
                <mat-error *ngIf="agentForm.controls['kkmId'].errors">
                    <div *ngIf="agentForm.controls['kkmId'].errors?.pattern">{{'kkm-id-invalid-pattern'| translate}}</div>
                    <div *ngIf="agentForm.controls['kkmId'].errors?.firstRequired">{{'kkm-id-required'| translate}}</div>
                </mat-error>
            </mat-form-field>

            <mat-form-field fxFill>
                <input matInput placeholder="{{'kkm-serial-number'| translate}}" formControlName="kkmSerialNumber">
                <mat-hint align="end">{{'agent-fiscalization-hint' | translate}}</mat-hint>
                <mat-error *ngIf="agentForm.controls['kkmSerialNumber'].errors">
                    <div *ngIf="agentForm.controls['kkmSerialNumber'].errors?.secondRequired">{{'kkm-serial-number-required'| translate}}</div>
                </mat-error>
            </mat-form-field>

            <mat-form-field fxFill>
                <mat-select placeholder="{{'kkm-owner-id' | translate}}" formControlName="ownerId">
                    <mat-option value="">{{'choose-owner-id' | translate}}</mat-option>
                    <mat-option *ngFor="let item of fiscalizationOrganizations" [value]="item.code | lowercase">
                    {{ item.title }}
                    </mat-option>
                </mat-select>
                <mat-hint align="end">{{'kkm-owner-hint' | translate}}</mat-hint>
                <mat-error *ngIf="agentForm.controls['ownerId'].errors">
                    <div *ngIf="agentForm.controls['ownerId'].errors?.pattern">{{'owner-id-invalid-pattern'| translate}}</div>
                    <div *ngIf="agentForm.controls['ownerId'].errors?.secondRequired">{{'owner-id-required'| translate}}</div>
                </mat-error>
            </mat-form-field>

          <mat-form-field fxFill>
            <input matInput placeholder="{{'terminalId'| translate}}" formControlName="terminalId">
            <mat-hint align="end">{{'terminal-id-hint' | translate}}</mat-hint>
          </mat-form-field>

        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-button mat-dialog-close>{{'cancel'| translate}}</button>
            <button mat-flat-button color="primary" type="submit" [disabled]="!agentForm.valid"
                cdkFocusInitial>{{'save'| translate}}</button>
        </mat-dialog-actions>
    </form>
</div>
