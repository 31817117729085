import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IResponse } from '../interfaces/iresponse';
import { IAccount } from '../interfaces/iaccount';
import * as moment from 'moment';


@Injectable({
  providedIn: 'root'
})
export class AccountService {
  host: string;

  constructor(private http: HttpClient) {
    this.host = environment.api_url + '/api/accounts'
  }

  accounts(): Observable<IResponse<IAccount[]>> {
    let url = this.host;
    return this.http.get<IResponse<IAccount[]>>(url);
  }
}
