import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged, pairwise } from 'rxjs/operators';
import { MustMatch } from 'src/app/helpers/must-match';
import { AuthService } from 'src/app/services/auth.service';
import { DictionaryService } from 'src/app/services/dictionary.service';
import { RefundsService } from 'src/app/services/refunds.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-refund-request',
  templateUrl: './edit-refund-request.component.html',
  styleUrls: ['./edit-refund-request.component.css']
})
export class EditRefundRequestComponent implements OnInit {

  isCompleted: boolean = false;
  isRefundProcessing: boolean = false;
  statuses = [];
  prevAmount: number = 0;
  prevAmount2: number = 0;
  @ViewChild('refundSum', {static: false})refundSum: ElementRef;
  @ViewChild('refundSum2', {static: false})refundSum2: ElementRef;
  globalLocation = environment.globalLocation;
  globalCurrencyCode = environment.globalCurrencyCode;
  
  refundForm = this.fb.group({
    id: [this.item.refund.id, Validators.required],
    canceledAmount: [0, [Validators.required, Validators.min(0)]],
    canceledAmountRepeat: [0, [Validators.required, Validators.min(0)]],
    userComment: [this.item.refund.userComment, Validators.required],
    transactionId: [this.item.refund.transactionId, Validators.required],
    statusId: [this.item.refund.statusId, Validators.required],
    paynetReference: [this.item.refund.paynetReference, Validators.required],
    agentId: [this.item.refund.agentId, Validators.required],
    amount: [this.item.refund.transactionAmount*100, Validators.required],
  },
  {
    validator: MustMatch('canceledAmount', 'canceledAmountRepeat')
  });

  constructor(@Inject(MAT_DIALOG_DATA) public item: any,
  private fb: FormBuilder,
  public snackBar: MatSnackBar,
  private dictionarySrv: DictionaryService,
  private refundSrv: RefundsService,
  public dialog: MatDialog,
  private translate: TranslateService,
  private authService: AuthService)  {
     // функция нужна для получения пред. значения суммы к оплате
     this.refundForm.controls["canceledAmount"].valueChanges.pipe(
      distinctUntilChanged(),
      pairwise() // gets a pair of old and new value
    ).subscribe(([oldValue, newValue])=>{
      this.prevAmount = oldValue;
    })

    // функция нужна для получения пред. значения суммы к оплате
    this.refundForm.controls["canceledAmountRepeat"].valueChanges.pipe(
      distinctUntilChanged(),
      pairwise() // gets a pair of old and new value
    ).subscribe(([oldValue, newValue])=>{
      this.prevAmount2 = oldValue;
    })
   }

  ngOnInit(): void {
    this.getRefundStatuses();
    console.log(this.item);
  }

  getRefundStatuses() {
    this.dictionarySrv.getRefundStatuses().subscribe(
      (data: any) => { 
        this.statuses = data; 
        this.refundForm.controls['statusId'].setValue(this.statuses.find(f => f.code == this.item.refund.statusId).code);
      },
      error => console.log(error)
    ).add(() => { this.isCompleted = true });
  }

  // convenience getter for easy access to form fields
  get f() { return this.refundForm.controls; }
  public hasError = (controlName: string, errorName: string) => {
    return this.refundForm.controls[controlName].hasError(errorName);
  }

  public update = (form) => {
    if (this.refundForm.valid) {
      this.execute(form);
    }
  }

  private execute = (data) => {
    this.isRefundProcessing = true;
    let json: any = {
      transactionId: Number(data.transactionId),
      statusId: Number(data.statusId),
      userComment: data.userComment,
      paynetReference: Number(data.paynetReference),
      agentId: data.agentId,
      amount: Number(data.amount),
      canceledAmount: Math.round((Number(Number(data.canceledAmount).toFixed(2)) * 100)),
    }

    this.refundSrv.refund(json, data.id)
      .subscribe(
        data => {
          const dialogRef = this.dialog.closeAll();
          let message = this.translate.instant('success-refund-request');
          this.openSnackBar(message, 'x');
        },
        error => {
          this.isRefundProcessing = false;
          if (error.status === 404 || error.status === 400) {
            let message = error.error;
            this.openSnackBar(message, 'x');
          }
          else if (error.status === 409) {
            let message = this.translate.instant('an-error-occurred-while-processing');
            this.openSnackBar(message, 'x');
          }
          else{
              if (error.status === 500) {
                let message = this.translate.instant('service-is-temporarily-unavailable');
                this.openSnackBar(message, 'x');
              }
              else {
                let message = this.translate.instant('an-error-occurred-while-processing');
                this.openSnackBar(message, 'x');
              }
          }
        }
      );
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  // автоматом убирает 0 при наборе суммы к оплате
  onKeypressAmountEvent(event: any, refillFieldName: string){
      if(Number(this.prevAmount2) == 0 && refillFieldName == 'canceledAmountRepeat'){
          this.refundForm.controls[refillFieldName].patchValue(Number(event.key));
          this.refundSum2.nativeElement.setSelectionRange(1, 1, 'none');
      }
      else if(Number(this.prevAmount) == 0 && refillFieldName == 'canceledAmount'){
        this.refundForm.controls[refillFieldName].patchValue(Number(event.key));
        this.refundSum.nativeElement.setSelectionRange(1, 1, 'none');
      }
  }

}
