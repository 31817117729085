export const environment = {
  production: true,
  api_url: window["env"]["apiUrl"] ||'https://dev-agentportalapi.onoi.me', 
  api_identity: window["env"]["apiIdentity"] || 'https://dev-cashboxauth.onoi.me/api',
  api_kassa_url: window["env"]["apiKassaUrl"] ||'https://dev-cashboxapiclient.onoi.me/api/ru', 
  api_static: window["env"]["apiStatic"] ||'https://s3.yurta.me/',
  auth:{
    sign_out_url: window["env"]["authSignOutUrl"] || 'https://dev-cashboxauth.onoi.me/connect/endsession',
    url: window["env"]["authUrl"] || 'https://dev-cashboxauth.onoi.me/connect/token',
    userinfo_url: window["env"]["userInfoUrl"] || 'https://dev-cashboxauth.onoi.me/connect/userinfo',
    client_id: window["env"]["clientId"] || 'kg-cashbox.client.back-office',
    client_secret: window["env"]["clientSecret"] || '69901c67-bcba-4126-9e66-20a2c85e8ca8',
    grant_type: window["env"]["grantType"] || 'password',
    scope: window["env"]["scope"] || 'openid profile kg-cashbox.api.back-office'
  },
  version: '3.0.0',
  globalLocation: window["env"]["globalLocation"] || 'kg',
  globalCurrencyCode: window["env"]["globalCurrencyCode"] || 'C',
  globalCurrencySymbol: window["env"]["globalCurrencySymbol"] || 'c',
  defaultLang: window["env"]["defaultLang"] || 'ru'
};