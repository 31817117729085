import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ITariffs } from 'src/app/interfaces/itariffs';
import { AgentService } from 'src/app/services/agent.service';


@Component({
  selector: 'app-tariff-change',
  templateUrl: './tariff-change.component.html',
  styleUrls: ['./tariff-change.component.css']
})
export class TariffChangeComponent implements OnInit {

  tariffs: ITariffs[] = [];
  isCompleted: boolean = false;
  templates = [];
  tariffForm = this.fb.group({
    agentId: [this.item.agent.id, Validators.required],
    templateId: [this.item.agent.templateId, Validators.required],
  });
  new_data = [];

  constructor(@Inject(MAT_DIALOG_DATA) public item: any,
    private fb: FormBuilder,
    public snackBar: MatSnackBar,
    private translate: TranslateService,
    private agentSrv: AgentService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.getTemplates();
  }


  getTemplates() {
    this.isCompleted = false;
    this.agentSrv.tariffs(this.item.agent.id).subscribe(
      (data: any) => { 
        this.templates = data.sort((a,b) => b.weight - a.weight);
        this.get_children(data,1);
      },
      error => {
        if (error.status === 404) {
          let message = this.translate.instant('not-found');
          this.openSnackBar(message, 'x');
        }
        else
          if (error.status === 400) {
            let message = this.translate.instant('an-error-occurred-while-processing');
            this.openSnackBar(message, 'x');
          }
          else
            if (error.status === 500) {
              let message = this.translate.instant('service-is-temporarily-unavailable');
              this.openSnackBar(message, 'x');
            }
            else {
              let message = this.translate.instant('an-error-occurred-while-processing');
              this.openSnackBar(message, 'x');
            }
      }
    ).add(() => { this.isCompleted = true });
  }

  get_children(data,level){
    var i=0
    while(i<data.length)
    {
      var current_parent = data[i];
      current_parent.level = level;
      
      this.new_data.push(current_parent)
      if(current_parent.tariffsChild?.length < 0){

      }
      else if(current_parent.tariffsChild?.length > 0){
        var next_level = current_parent.level + 1;
        this.get_children(current_parent.tariffsChild, next_level);
      }
      i++
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.tariffForm.controls; }
  public hasError = (controlName: string, errorName: string) => {
    return this.tariffForm.controls[controlName].hasError(errorName);
  }

  public update = (form) => {
    if (this.tariffForm.valid) {
      this.execute(form);
    }
  }

  private execute = (data) => {
    let json: any = {
      agentId: data.agentId,
      templateId: Number(data.templateId)
    }

    this.agentSrv.updateTemplate(json)
      .subscribe(
        data => {
          this.openSnackBar("Тарифный план изменен", 'x');
          this.dialog.closeAll();
        },
        error => {
          if (error.status === 409) {
            let message = this.translate.instant('an-error-occurred-while-processing');
            this.openSnackBar(message, 'x');
          }
          else
            if (error.status === 400) {
              let message = this.translate.instant(error?.error?.message);
              this.openSnackBar(message, 'x');
            }
            else
              if (error.status === 500) {
                let message = this.translate.instant('service-is-temporarily-unavailable');
                this.openSnackBar(message, 'x');
              }
              else {
                let message = this.translate.instant('an-error-occurred-while-processing');
                this.openSnackBar(message, 'x');
              }
        }
      );
  }


  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }
}

