import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IProfileReport } from '../interfaces/reports/iprofile-report';
import * as moment from 'moment';
import { IRegistration } from '../interfaces/iregistration';
import { IAgent } from '../interfaces/iagent'; 
import { ILowerCommissionAgents } from '../interfaces/ilower-commission-agents'; 
import { IPayNKCommission } from '../interfaces/ipay-nk-commission';

@Injectable({
  providedIn: 'root'
})
export class AgentService {
  host: string;

  constructor(private http: HttpClient) {
    this.host = environment.api_url + '/api/agents'
  }

  agents(agentId: string, cityId: number, dateFrom: Date, dateTo: Date): Observable<any[]> {
    let url = this.host;
    let params = new HttpParams();
    if (agentId != null) {
      params = params.append('agentId', agentId);
    }
    if (cityId != null && cityId != undefined) {
      params = params.append('cityId', cityId.toString());
    }
    if (dateFrom != null && dateFrom != undefined) {
      params = params.append('dateFrom', moment(dateFrom).format("YYYY-MM-DDT00:00:00.000") + "Z");
    }
    if (dateTo != null && dateTo != undefined) {
      params = params.append('dateTo', moment(dateTo).format("YYYY-MM-DDT23:59:59.000") + "Z");
    }
    return this.http.get<any[]>(url, { params: params });
  }

  updateTemplate(data: any): Observable<any> {
    let url = this.host + '/template';
    return this.http.put(url, data);
  }

  // Получить все шаблоны тарифов
  tariffs(agentId: string): Observable<any> {
    let url = this.host+'/'+agentId+"/tariffs";
    return this.http.get<any[]>(url);
  };

  // Получить все шаблоны тарифов для субов
  subTariffs(agentId: string): Observable<any> {
    let url = this.host+'/'+agentId+"/sub-tariffs";
    return this.http.get<any[]>(url);
  };

  newProfiles(dateFrom: Date, dateTo: Date):Observable<IProfileReport[]>
  {
    let url = this.host+"/report/new-agents";
    let params = new HttpParams();
    if (dateFrom != null && dateFrom != undefined) {
      params = params.append('dateFrom', moment(dateFrom).format("YYYY-MM-DDT00:00:00.000") + "Z");
    }
    if (dateTo != null && dateTo != undefined) {
      params = params.append('dateTo', moment(dateTo).format("YYYY-MM-DDT23:59:59.000") + "Z");
    }
    return this.http.get<IProfileReport[]>(url, { params: params });
  }
  
  idleProfiles(dateFrom: Date, dateTo: Date):Observable<IProfileReport[]>
  {
    let url = this.host+"/report/idle-agents";
    let params = new HttpParams();
    if (dateFrom != null && dateFrom != undefined) {
      params = params.append('dateFrom', moment(dateFrom).format("YYYY-MM-DDT00:00:00.000") + "Z");
    }
    if (dateTo != null && dateTo != undefined) {
      params = params.append('dateTo', moment(dateTo).format("YYYY-MM-DDT23:59:59.000") + "Z");
    }
    return this.http.get<IProfileReport[]>(url, { params: params });
  }

  cashOff(data: any, agentId: number): Observable<any> {
    let url = this.host + "/" + agentId + "/cash-off";
    return this.http.post(url, data);
  }
  
  
  refill(data: any, agentId: number): Observable<any> {
    let url = this.host + "/" + agentId + "/refill";
    return this.http.post(url, data);
  }

  createSubAgent(data: IRegistration): Observable<any> {
    return this.http.post(this.host + '/sub', data);
  }

  search(query:string): Observable<any[]> {
    const url = this.host + '/' + query;
    return this.http.get<any[]>(url);
  }

  updateAgent(data: IAgent): Observable<any> {
    return this.http.put(this.host, data);
  }

  blockUser(data: any): Observable<any> {
    let url = this.host + '/block-user';
    return this.http.post<any>(url, data);
  }

  unblockUser(agentId: string): Observable<any> {
    let url = this.host + '/unblock-user';
    let json =
    {
      AgentId: agentId
    }
    return this.http.post<any>(url, json);
  }
  
  getAgentsTree(agentId: string):Observable<any>{
    let url = this.host + "/agents-tree/" + agentId;
    return this.http.get(url);
  }

  getAgentsNKTree(month: string, year: string):Observable<ILowerCommissionAgents[]>{
    let url = this.host + "/agents-nk-tree";

    let json =
    {
      month: Number(month),
      year: Number(year)
    }
    return this.http.post<ILowerCommissionAgents[]>(url, json);
  }

  payLowerCommissionToAgents(agentsNK: IPayNKCommission): Observable<any> 
  {
    let url =  environment.api_url + "/api/transactions/execute-transactions"; 
    return this.http.post<any>(url, agentsNK); 
  } 
}