<div fxLayout="column" fxLayoutAlign="center center">
    <h1 class="mat-headline">{{'settings-news'| translate}}</h1>
</div>
<mat-progress-bar mode="indeterminate" *ngIf="!isCompleted"></mat-progress-bar>
<div fxLayout="column" fxLayoutAlign="none" *ngIf="isCompleted">
    <div fxLayoutAlign="end">
        <a color="primary" mat-button (click)="openNewsAddDialog()">
            <mat-icon>add</mat-icon>
            {{'add-news' | translate}}
        </a>
    </div>

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z0">

        <!-- image Column -->
        <ng-container matColumnDef="image">
          <th mat-header-cell *matHeaderCellDef style="text-align: center"> {{'image' | translate}} </th>
          <td mat-cell *matCellDef="let element">
            <img src="{{staticApiUrl}}ukassa/{{element.image}}" style="width: 200px;" alt="">
          </td>
        </ng-container>

        <!-- title Column -->
        <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef> {{'title' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.title}} </td>
        </ng-container>

        <!-- newsDate Column -->
        <ng-container matColumnDef="newsDate">
            <th mat-header-cell *matHeaderCellDef> {{'create-time' | translate}} </th>
            <td mat-cell *matCellDef="let element">{{element.newsDate | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
        </ng-container>

        <!-- action Column -->
        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> {{'actionTitle' | translate}} </th>
            <td mat-cell *matCellDef="let element">
                <section class="template-section">
                    <button mat-icon-button color="primary" [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="openNewsEditDialog(element)">
                          <mat-icon>edit</mat-icon>
                          <span>{{'edit' | translate}}</span>
                        </button>

                        <button mat-menu-item (click)="deleteNews(element)">
                            <mat-icon>delete_forever</mat-icon>
                            <span>{{'delete' | translate}}</span>
                          </button>
                    </mat-menu>
                </section>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>
