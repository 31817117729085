<mat-progress-bar mode="indeterminate" *ngIf="!isCompleted"></mat-progress-bar>

<div *ngIf="isCompleted">
  <mat-card fxLayout="row" class="mat-card-header">
    <mat-card  fxFlex="40%" fxLayoutAlign="center center" class="mat-th">{{'fio' | translate}}</mat-card> 
    <mat-card  fxFlex="20%" fxLayoutAlign="center center" class="mat-th">{{'phone' | translate}}</mat-card> 
    <mat-card  fxFlex="20%" fxLayoutAlign="center center" class="mat-th">{{'balance' | translate}}</mat-card> 
    <mat-card  fxFlex="20%" fxLayoutAlign="center center" class="mat-th">{{'tariff-rates' | translate}}</mat-card>
  </mat-card>
  
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="agents-mat-tree">
    <!-- This is the tree node template for leaf nodes -->
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
      <!-- use a disabled button to provide padding for tree leaf -->
      <mat-card fxLayout="row" fxFill>
        <mat-card  fxFlex="40%" [style.padding-left.px]="[ 30 * (node.level+1)]">
          <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.fName">
            <mat-icon class="mat-icon-rtl-mirror">
            </mat-icon>
            <span class="mat-body">
            {{node.lname}} {{(node.fname ? node.fname : node.company)}}   <br>
            <span class="mat-small">{{node.city}}</span></span>
          </button>
        </mat-card>
        <mat-card fxLayoutAlign="center center" fxFlex="20%" class="mat-td">{{node.phone}}</mat-card>
        <mat-card fxLayoutAlign="center center" fxFlex="20%" class="mat-td">{{node.balance | number : '1.2-2'}}</mat-card>
        <mat-card fxLayoutAlign="center center" fxFlex="20%" class="mat-td">{{node.templateName}}</mat-card>
      </mat-card>
    </mat-tree-node>
  
    <!-- This is the tree node template for expandable nodes -->
    <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
      <mat-card fxLayout="row" fxFill>
        <mat-card  fxFlex="40%" [style.padding-left.px]="[ 30 * (node.level+1)]">
          <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.fName">
            <mat-icon class="mat-icon-rtl-mirror">
              {{treeControl.isExpanded(node) ? 'remove' : 'add'}}
            </mat-icon>
            <span class="mat-body">
              <b>{{node.lname}} {{(node.fname ? node.fname : node.company)}} </b>   <br>
            <span class="mat-small">{{node.city}}</span></span>
          </button>
        </mat-card>
        <mat-card fxLayoutAlign="center center" fxFlex="20%" class="mat-td">{{node.phone}}</mat-card>
        <mat-card fxLayoutAlign="center center" fxFlex="20%" class="mat-td">{{node.balance | number : '1.2-2'}}</mat-card>
        <mat-card fxLayoutAlign="center center" fxFlex="20%" class="mat-td">{{node.templateName}}</mat-card>
      </mat-card>
    </mat-tree-node>
  </mat-tree>

    <mat-card fxLayout="row" fxFill>
      <mat-card  fxFlex="40%">
        <button mat-icon-button>
          <mat-icon class="mat-icon-rtl-mirror"></mat-icon>
          <span class="mat-body">
            {{'total' | translate}}: 
          </span>
        </button>
      </mat-card>
      <mat-card fxLayoutAlign="center center" fxFlex="20%" class="mat-td"></mat-card>
      <mat-card fxLayoutAlign="center center" fxFlex="20%" class="mat-td">{{getTotalBalance() | number : '1.2-2'}}</mat-card>
      <mat-card fxLayoutAlign="center center" fxFlex="20%" class="mat-td"></mat-card>
    </mat-card>
  
   <section class="button-download">
    <button mat-icon-button color="primary" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="mainMenuButtonExcel">
        <mat-icon>save_alt</mat-icon>
    </button>
    <mat-menu #mainMenuButtonExcel="matMenu">
        <div class="table-column-list-block">
            <section class="mat-menu-button">
                <button mat-raised-button color="primary" fxLayoutAlign="center center"  (click)="exportToExcel()" >
                    {{'export' | translate}}
                    <mat-icon>save_alt</mat-icon>
                </button>   
            </section>
            <span (click)="$event.stopPropagation()" *ngFor="let element of columnDefinitionsForExcel;index as i">
                <mat-slide-toggle color="primary" (change)="element.isShow = !element.isShow" [checked]="element.isShow == true"></mat-slide-toggle>
                {{element.label}}
                <br>
            </span>
        </div>
    </mat-menu>
  </section>
</div>


