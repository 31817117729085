import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { IAccount } from 'src/app/interfaces/iaccount';
import { IService } from 'src/app/interfaces/iservice';
import { IRegionReport } from 'src/app/interfaces/reports/iregion-report';
import { AgentService } from 'src/app/services/agent.service';
import { DictionaryService } from 'src/app/services/dictionary.service';
import { ExportService } from 'src/app/services/export.service';
import { ReportService } from 'src/app/services/report.service';
import { ServicesService } from 'src/app/services/services.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-region-report',
  templateUrl: './region-report.component.html',
  styleUrls: ['./region-report.component.css']
})
export class RegionReportComponent implements OnInit {

  isCompleted: boolean = false;
  displayedColumns: string[] = ['agentName', 'cityName', 'transactionsCount', 'canceledCount', 'cashFlow', 'acceptedAmount', 'canceledAmount', 'upperCommissionAmount', 'lowerCommissionIncome', 'lowerCommissionOutcome', 'canceledLowerCommission', 'itoCommissionIncome', 'itoCommissionOutcome', 'expectedLowerCommissionDelta', 'lowerCommissionDelta', 'itoDelta', 'grSum'];
  dataSource: IRegionReport[] = [];

  public reportData: IRegionReport[] = [];
  columnDefinitionsForExcel = [];
  dateFrom = new FormControl(new Date(new Date().setDate(new Date().getDate() - 7)));
  dateTo = new FormControl(new Date());

  cityId = new FormControl();
  cities = [];

  public accounts: IAccount[] = [];
  agentId = new FormControl();
  public agentAutoComplete$: Observable<any> = null;
  mode: string;

  services: any[] = [];
  selectedService = new FormControl();
  public serviceAutoComplete$: Observable<any> = null;
  // глобальные параметры валюты приложения
  globalLocation = environment.globalLocation;
  globalCurrencyCode = environment.globalCurrencyCode;

  constructor(
    private reportSrv: ReportService,
    private exportSrv: ExportService,
    private translate: TranslateService,
    public snackBar: MatSnackBar,
    public agentsSrv: AgentService,
    private dictionarySrv: DictionaryService,
    private agentSrv: AgentService,
    private servicesSrv: ServicesService,
    private route: ActivatedRoute
  ) { }

  lookup(value: any): Observable<any> {  
    if (typeof value === 'object')
    {
      value = value.title;
    } 
    
    return this.agentSrv.search(value.toLowerCase()).pipe(
        // map the item property of the results as our return object
        map(results => results),
        // catch errors
        catchError(_ => {
          return of(null);
        })
      );
    }

    lookupServices(value: any): Observable<any> {  
      if (typeof value === 'object')
      {
        value = value.alias;
      } 
  
      return this.servicesSrv.search(value.toLowerCase()).pipe(
          // map the item property of the results as our return object
          map(results => results),
          // catch errors
          catchError(_ => {
            return of(null);
          })
        );
  }

  ngOnInit(): void {
    this.agentAutoComplete$ = this.agentId.valueChanges.pipe(
      startWith(''),
      // delay emits
      debounceTime(300),
      // use switch map so as to cancel previous subscribed events, before creating new once
      switchMap(value => {
        if (value !== '') {
          // lookup from agents
          return this.lookup(value);
        } else {
          // if no value is pressent, return null
          return of(null);
        }
      })
    );

    this.serviceAutoComplete$ = this.selectedService.valueChanges.pipe(
      startWith(''),
      // delay emits
      debounceTime(300),
      // use switch map so as to cancel previous subscribed events, before creating new once
      switchMap(value => {
        if (value !== '') {
          // lookup from services
          return this.lookupServices(value);
        } else {
          // if no value is pressent, return null
          return of(null);
        }
      })
    );

    this.route.paramMap.pipe(
      switchMap(params => params.getAll('mode'))
    )
      .subscribe(data => this.mode = data);
      
    this.load();
  }

  load() {
    this.getCities();
    this.getAccounts();
  }

  getAccounts() {
    this.accounts = [];
    this.dictionarySrv.agents().subscribe(data => {
        this.accounts = data;
        if(data != null){
          this.agentId.setValue(this.accounts[0]);
          this.getReport(data[0]['code']);
        }
      },
      error => {
        if (error.status === 404) {
          let message = this.translate.instant('no-data');
          this.openSnackBar(message, 'x');
        }
        else
          if (error.status === 400) {
            let message = this.translate.instant('bad-request');
            this.openSnackBar(message, 'x');
          }
          else
            if (error.status === 500) {
              let message = this.translate.instant('service-is-temporarily-unavailable');
              this.openSnackBar(message, 'x');
            }
            else {
              let message = this.translate.instant('an-error-occurred-while-processing');
              this.openSnackBar(message, 'x');
            }
      }
      );
  }

  getCities() {
    this.dictionarySrv.getCities().subscribe(
      (data: any) => { this.cities = data; },
      error => console.log(error)
    )
  }

  getReport(userId = 0) {
    this.isCompleted = false;
    this.dataSource = [];

    var curCityId = null;
    if (this.cityId.value != null && this.cityId.value != undefined) {
        curCityId = this.cityId.value;
    }

    var curUserId = null;
    if (userId != 0) {
        curUserId = userId;
    }
    else if (this.agentId.value != null && this.agentId.value != undefined) {
        curUserId = this.agentId.value.code;
    }

    var curServiceId = null;
    if (this.selectedService.value != null && this.selectedService.value != undefined) {
        curServiceId = this.selectedService.value.id;
    }
    this.columnDefinitionsForExcel = [];
    this.reportSrv.regionReport(curServiceId, curCityId, curUserId, this.dateFrom.value, this.dateTo.value, this.mode)
      .subscribe(
        data => {
          this.reportData = data;
          this.dataSource = this.reportData;
          if(this.dataSource[0]){
            this.columnDefinitionsForExcel = this.exportSrv.getAllColumnsExcel(Object.keys(this.dataSource[0]));
          }
        },
        error => {
          if (error.status === 404) {
            let message = this.translate.instant('no-data');
            this.openSnackBar(message, 'x');
          }
          else
            if (error.status === 400) {
              let message = this.translate.instant('an-error-occurred-while-processing');
              this.openSnackBar(message, 'x');
            }
            else
              if (error.status === 500) {
                let message = this.translate.instant('service-is-temporarily-unavailable');
                this.openSnackBar(message, 'x');
              }
              else {
                let message = this.translate.instant('an-error-occurred-while-processing');
                this.openSnackBar(message, 'x');
              }
        }
      ).add(() => { this.isCompleted = true });
  }

  displayFn(account: any): string {
    return account && account.title ? account.title : '';
  }

  displayFnService(service: IService): string {
    return service && service.alias ? service.alias : '';
  }

  getTotalSuccesTransaction() {
    return this.dataSource.map(t => t.transactionsCount).reduce((acc, value) => acc + value, 0);
  }
  getTotalCanceledTransaction() {
    return this.dataSource.map(t => t.canceledCount).reduce((acc, value) => acc + value, 0);
  }
  getTotalCashFlow() {
    return this.dataSource.map(t => t.cashFlow).reduce((acc, value) => acc + value, 0);
  }
  getTotalAcceptedAmount() {
    return this.dataSource.map(t => t.acceptedAmount).reduce((acc, value) => acc + value, 0);
  }
  getTotalUpperCommissionAmount() {
    return this.dataSource.map(t => t.upperCommissionAmount).reduce((acc, value) => acc + value, 0);
  }
  getTotalLowerCommissionOutcome() {
    return this.dataSource.map(t => t.lowerCommissionOutcome).reduce((acc, value) => acc + value, 0);
  }
  getTotalLowerCommissionIncome() {
    return this.dataSource.map(t => t.lowerCommissionIncome).reduce((acc, value) => acc + value, 0);
  }
  getTotalLowerCommissionDelta() {
    return this.dataSource.map(t => t.lowerCommissionDelta).reduce((acc, value) => acc + value, 0);
  }
  getTotalExpectedLowerCommissionDelta() {
    return this.dataSource.map(t => t.expectedLowerCommissionDelta).reduce((acc, value) => acc + value, 0);
  }
  getTotalItoCommissionOutcome() {
    return this.dataSource.map(t => t.itoCommissionOutcome).reduce((acc, value) => acc + value, 0);
  }
  getTotalItoCommissionIncome() {
    return this.dataSource.map(t => t.itoCommissionIncome).reduce((acc, value) => acc + value, 0);
  }
  getTotalITODelta() {
    return this.dataSource.map(t => t.itoDelta).reduce((acc, value) => acc + value, 0);
  }
  getTotalGRSum() {
    return this.dataSource.map(t => t.grSum).reduce((acc, value) => acc + value, 0);
  }
  getTotalCanceledAmount() {
    return this.dataSource.map(t => t.canceledAmount).reduce((acc, value) => acc + value, 0);
  }
  getTotalCanceledLowerCommission() {
    return this.dataSource.map(t => t.canceledLowerCommission).reduce((acc, value) => acc + value, 0);
  }
  exportToExcel() {
    this.exportSrv.exportToExcel(this.dataSource, 'Report', 'filename.xlsx', this.columnDefinitionsForExcel);
  }

  public updateReport() {
    this.getReport(0);
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  clearSearch(){
    this.agentId.setValue("");
      this.getReport();   
  }

  clearSearchService(){
    this.selectedService.setValue("");
      this.getReport();   
  }

}
