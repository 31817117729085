<div fxLayout="column" fxLayoutAlign="center center">
    <h1 class="mat-headline">{{'agents-settings'| translate}}</h1>
</div>
<mat-progress-bar mode="indeterminate" *ngIf="!isCompleted"></mat-progress-bar>
<div fxLayout="column" fxLayoutAlign="none" *ngIf="isCompleted">
    <div fxLayoutAlign="center center" fxLayout="row"  fxLayout.xs="column" fxLayoutGap="10px" fxLayoutGap.xs="0">
        <mat-form-field fxFlex="20" fxFlex.xs="60">
            <mat-select placeholder="{{'agent-city' | translate}}" [formControl]="cityId" (selectionChange)="searchAgents()">
                <mat-option [value]="">
                    {{ 'choose-city' | translate}}
                    </mat-option>
                <mat-option *ngFor="let item of cities" [value]="item.code">
                {{ item.title }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field fxFlex="20"  fxFlex.xs="60">
            <mat-label>{{'created-date-from' | translate}}</mat-label>
            <input matInput [matDatepicker]="pickerFrom" (click)="pickerFrom.open()" [formControl]="dateFrom" placeholder="dateFrom" (dateChange)="searchAgents()" readonly>
            <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
            <mat-datepicker #pickerFrom></mat-datepicker>
        </mat-form-field>

        <mat-form-field fxFlex="20" fxFlex.xs="60">
            <mat-label>{{'created-date-to' | translate}}</mat-label>
            <input matInput [matDatepicker]="pickerTo"  (click)="pickerTo.open()" [formControl]="dateTo" placeholder="{{'dateFrom'| translate}}" (dateChange)="searchAgents()" readonly>
            <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
            <mat-datepicker #pickerTo></mat-datepicker>
        </mat-form-field>
    
        <mat-form-field fxFlex="20" fxFlex.xs="60">
            <input type="text" placeholder="{{'agent'| translate}}" matInput [formControl]="agentId"
                [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" autoActiveFirstOption (optionSelected)="searchAgents()">
                <mat-option *ngFor="let option of agentAutoComplete$ | async; let index = index" [value]="option">
                    {{option.title}}
                </mat-option>
            </mat-autocomplete>
            <button *ngIf="agentId != null" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
                <mat-icon>close</mat-icon>
              </button>
        </mat-form-field>

        <span>
            <button mat-icon-button color="primary" (click)="searchAgents()">
                <mat-icon>search</mat-icon>
            </button>
        
            <button mat-icon-button color="primary" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="mainMenuButton">
                <mat-icon>settings</mat-icon>
            </button>
        </span>

        <mat-menu #mainMenuButton="matMenu">
            <div class="table-column-list-block">
                <span (click)="$event.stopPropagation()" *ngFor="let element of columnDefinitions;index as i">
                    <mat-slide-toggle color="primary" [(ngModel)]="element.isShow"></mat-slide-toggle>
                    {{element.label}}
                    <br>
                </span>
            </div>
        </mat-menu>
    </div>


    <table mat-table [dataSource]="dataSource" class="mat-elevation-z0 agents-table">

        <!-- id Column -->
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> {{'id' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>
        <!-- phone Column -->
        <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef> {{'phone' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
        </ng-container>
        <!-- fio Column -->
        <ng-container matColumnDef="fio">
            <th mat-header-cell *matHeaderCellDef> {{'fio' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.lName}} {{ (element.fName ? element.fName : element.company)}} </td>
        </ng-container>
        <!-- parent Column -->
        <ng-container matColumnDef="parentAgent">
            <th mat-header-cell *matHeaderCellDef> {{'parentAgent' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.parentProfileName}} </td>
        </ng-container>
        <!-- cityName Column -->
        <ng-container matColumnDef="cityName">
            <th mat-header-cell *matHeaderCellDef> {{'agent-city' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.cityName }} </td>
        </ng-container>
        <!-- address Column -->
        <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef> {{'address' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.address }} </td>
        </ng-container>
        <!-- created Column -->
        <ng-container matColumnDef="created">
            <th mat-header-cell *matHeaderCellDef> {{'created' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.created | date:"dd/MM/yyyy HH:mm:ss"}} </td>
        </ng-container>

         <!-- template Column -->
         <ng-container matColumnDef="template">
            <th mat-header-cell *matHeaderCellDef> {{'template' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.template}} </td>
        </ng-container>

        <!-- templateId Column -->
         <ng-container matColumnDef="templateId">
            <th mat-header-cell *matHeaderCellDef> {{'tariffId' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.templateId}} </td>
        </ng-container>
        
        <!-- balance Column -->
        <ng-container matColumnDef="balance">
            <th mat-header-cell *matHeaderCellDef> {{'balance' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.balance | number : '1.2-2'}} </td>
        </ng-container>

        <!-- lockout Column -->
        <ng-container matColumnDef="lockout">
            <th mat-header-cell *matHeaderCellDef> {{'lockout-date' | translate}} </th>
            <td mat-cell *matCellDef="let element"> 
                <span *ngIf="element.lockoutEnabled">
                    {{element.lockoutEnd | date:"dd/MM/yyyy HH:mm:ss"}}
                </span>
            </td>
        </ng-container>

        <!-- email Column -->
        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef> {{'email' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.email}} </td>
        </ng-container>

        <!-- terminalId Column -->
        <ng-container matColumnDef="terminalId">
            <th mat-header-cell *matHeaderCellDef> {{'terminalId' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.terminalId}} </td>
        </ng-container>
        

        <!-- action Column -->
        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> {{'settings' | translate}} </th>
            <td mat-cell *matCellDef="let element" class="agents-table-action-td">
                <section class="template-section">
                    <button mat-icon-button color="primary" [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu" class="agents-action-mat-menu">
                        <button *ngIf="authService.isAccountant" mat-menu-item (click)="openAgentRefillDialog(element)">
                            <mat-icon>add_shopping_cart</mat-icon>
                            <span>{{'agent-refill-short' | translate}}</span>
                        </button>
                        <a href="/reports/transactions-report?AgentId={{element.id}}&Type=888" style="text-decoration: none;">
                            <button mat-menu-item>
                                <mat-icon>list_alt</mat-icon>
                                <span>{{'agent-refill-history' | translate}}</span>
                            </button>
                        </a>
                        <button *ngIf="authService.isAccountant" mat-menu-item (click)="openAgentReturnDialog(element)">
                          <mat-icon>money_off</mat-icon>
                          <span>{{'agent-cashoff' | translate}}</span>
                        </button>                       
                        <!-- <button mat-menu-item (click)="openAgentDialog(element)">
                            <mat-icon>money_off</mat-icon>
                            <span>{{'agent-debeting' | translate}}</span>
                          </button> -->
                        <button *ngIf="authService.isAdmin" mat-menu-item (click)="openAgentDialog(element)">
                            <mat-icon>list</mat-icon>
                            <span>{{'change-tariff' | translate}}</span>
                        </button>

                        <button *ngIf="authService.isAdmin" mat-menu-item (click)="openAddSubAgentDialog(element)">
                            <mat-icon>person_add</mat-icon>
                            <span>{{'adding-subagent' | translate}}</span>
                        </button>
                        <button *ngIf="authService.isAdmin" mat-menu-item (click)="openAgentEditDialog(element)">
                            <mat-icon>edit</mat-icon>
                            <span>{{'edit-personal-data' | translate}}</span>
                        </button>

                        <button *ngIf="authService.isAdmin && !element.lockoutEnabled" mat-menu-item (click)="blockUser(element)">
                            <mat-icon>lock</mat-icon>
                            <span>{{'block-agent' | translate}}</span>
                        </button>

                        <button *ngIf="authService.isAdmin && element.lockoutEnabled" mat-menu-item (click)="unblockUser(element)">
                            <mat-icon>lock_open</mat-icon>
                            <span>{{'unblock-agent' | translate}}</span>
                        </button>

                        <button mat-menu-item (click)="openAgentTreeDialog(element)">
                            <mat-icon>device_hub</mat-icon>
                            <span>{{'view-agent-tree' | translate}}</span>
                          </button>

                        <button *ngIf="authService.isAdmin" mat-menu-item (click)="openRolesDialog(element)">
                          <mat-icon>group</mat-icon>
                          <span>{{'change-roles' | translate}}</span>
                        </button>

                    </mat-menu>
                </section>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="getDisplayedColumns(); sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: getDisplayedColumns();"></tr>
    </table>
</div>