<div fxLayout="column" fxLayoutAlign="center center">
    <h1 class="mat-headline">{{'block-agent'| translate}}</h1>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="!isCompleted"></mat-progress-bar>
  <div *ngIf="isCompleted">
    <form [formGroup]="blockAgentForm" novalidate (ngSubmit)="update(blockAgentForm.value)">
        <mat-dialog-content class="mat-typography">
            <h3 *ngIf="item">{{'agent' | translate}} : {{item.agent.company}}</h3>
            
            <mat-form-field fxFill>
                <input matInput type="text" placeholder="{{'block-days' | translate}}" formControlName="blockDays">

                <mat-error *ngIf="blockAgentForm.controls['blockDays'].hasError('required') || blockAgentForm.controls['blockDays'].hasError('min')">
                    {{'block-days-is-req' | translate}}
                </mat-error>
            </mat-form-field>

        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-button mat-dialog-close>{{'cancel'| translate}}</button>
            <button mat-flat-button color="primary" type="submit" [disabled]="!blockAgentForm.valid"
                cdkFocusInitial>{{'block'| translate}}</button>
        </mat-dialog-actions>
    </form>
  </div>