import { Component, Inject, OnInit } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AgentService } from 'src/app/services/agent.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ExportService } from 'src/app/services/export.service';

@Component({
  selector: 'app-agents-tree',
  templateUrl: './agents-tree.component.html',
  styleUrls: ['./agents-tree.component.css']
})
export class AgentsTreeComponent implements OnInit {
  TreeData: any[] = [];
  private _transformer = (node: any, level: number) => {
    node.expandable = !!node.subs && node.subs.length > 0;
    node.level = level;
    return node;
  }

  treeControl = new FlatTreeControl<any>(
    node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.level, node => node.expandable, node => node.subs);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  isCompleted: boolean = false;
  columnDefinitionsForExcel = [];
  constructor(public agentsService: AgentService,
    @Inject(MAT_DIALOG_DATA) public agentInfo: any,
    private translate: TranslateService,
    public snackBar: MatSnackBar,
    private exportSrv: ExportService) {
  }

  hasChild = (_: number, node: any) => node.expandable;

  ngOnInit(): void {
    this.getAgents();
  }

  getAgents() {
    this.columnDefinitionsForExcel = [];
    this.agentsService.getAgentsTree(this.agentInfo.agent.id).subscribe((
      data: any[]) => {
      this.TreeData = data;
      this.dataSource.data = this.TreeData;
      if(data[0]){
        this.columnDefinitionsForExcel = this.exportSrv.getAllColumnsExcel(Object.keys(data[0]));
      }
    },
      error => {
        if (error.status === 404) {
          let message = this.translate.instant('not-found');
          this.openSnackBar(message, 'x');
        }
        else
          if (error.status === 400) {
            let message = this.translate.instant('an-error-occurred-while-processing');
            this.openSnackBar(message, 'x');
          }
          else
            if (error.status === 500) {
              let message = this.translate.instant('service-is-temporarily-unavailable');
              this.openSnackBar(message, 'x');
            }
            else {
              let message = this.translate.instant('an-error-occurred-while-processing');
              this.openSnackBar(message, 'x');
            }
      }
    ).add(() => { this.isCompleted = true })
  }

  getTotalBalance() {
    let arrayAgents = [];
    this.getRecursiveAgents(arrayAgents, this.dataSource.data);
    return arrayAgents.map(t => t.balance).reduce((acc, value) => acc + value, 0);
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  exportToExcel() {
    let totalArray = this.dataSource.data;
    let arrayAgents = [];
    this.getRecursiveAgents(arrayAgents, totalArray);
    let checkFio = obj => obj.def === "fio";
    if(!this.columnDefinitionsForExcel.some(checkFio)){
      this.columnDefinitionsForExcel.push({ def: "fio", isShow: true, label: this.translate.instant('fio')});
    }
    this.exportSrv.exportToExcel(arrayAgents, 'Report', 'filename.xlsx', this.columnDefinitionsForExcel);
  }
 
  getRecursiveAgents(arrayAgents, agents) {
    agents.forEach(element => {
      let stringSpace = "";
      let level = element['level'];
      for (var i = 0; i < level * 10; i++) {
        stringSpace += " ";
      }
      element['fio'] = stringSpace + " " + (element['lname'] ?? "") + " " + (element['fname'] ?? element['company'] ?? "") + " " + (element['pname'] ?? "");
      arrayAgents.push(Object.assign({'fio':  element['fio']}, element));
      if (element['subs'] && element['subs'] != null) {
        element['subs'].forEach(element2 => {
          let stringSpace = "";
          let level = element2['level'];
          for (var i = 0; i < level * 10; i++) {
            stringSpace += " ";
          }
          element2['fio'] = stringSpace + " " + (element2['lname'] ?? "") + " " + (element2['fname'] ?? element2['company'] ?? "") + " " + (element2['pname'] ?? "");
          arrayAgents.push(Object.assign({'fio': element2['fio']}, element2));
          return this.getRecursiveAgents(arrayAgents, element2['subs']);
        });
      }
    });
    return arrayAgents;
  }
}
