<div class="change-role-main">
  <div class="change-role-container">
    <div class="change-role-title-container">
      <h1 class="change-role-title">{{ 'change-role-title'| translate }}</h1>
      <h2 class="change-role-agent">{{ agent.agent.company }}
        ({{ agent.agent.lName }} {{ agent.agent.fName }} {{ agent.agent.pName }})</h2>
    </div>
    <div class="line-separator"></div>
    <mat-progress-bar mode="indeterminate" *ngIf="!isCompleted"></mat-progress-bar>
    <div class="change-role-roles-container">
      <div *ngIf="!isError && isCompleted" class="change-role-roles-title">
        <h3 class="change-role-title">{{ 'change-role-roles-title'| translate }}</h3>
      </div>
      <div *ngIf="!isError && isCompleted" class="line-separator-small"></div>
      <div [ngClass]="{'change-role-no-overflow': isError}" class="change-role-roles-wrapper">
        <mat-selection-list [ngClass]="{'change-role-hide': isError || !isCompleted}" class="change-role-roles-list" #rolesList>
          <ng-container *ngIf="!isError">
            <mat-list-option color="primary" class="change-role-roles-item" *ngFor="let role of roles" [value]="role"
                             [selected]="userRoles.includes(role)">
              {{ role }}
            </mat-list-option>
          </ng-container>
        </mat-selection-list>
        <div *ngIf="isError && this.serverErrorFullMessage" class="change-role-error-container">
          <mat-icon class="change-role-error-icon">
            error
          </mat-icon>
          <div class="change-role-error-message">
            <span>{{ this.siteErrorMessage }}</span>
          </div>
          <div class="change-role-error-message">
            <span>{{ this.serverErrorFullMessage }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="line-separator"></div>
  <div class="change-role-finish-buttons">
    <button class="change-role-button change-role-button-cancel" (click)="closeAddAgentDialog()">
      <span>{{ 'cancel'| translate }}</span>
    </button>
    <button *ngIf="!isError" class="change-role-button change-role-button-save"
            (click)="saveRoles(rolesList.selectedOptions.selected)">
      <span>{{ 'save'| translate }}</span>
    </button>
  </div>
</div>
