<mat-card>
    <h1 class="mat-headline">{{'log-in'| translate}}</h1>
    <mat-card-content>
        <div *ngIf="error">Введен неверный логин или пароль</div>
        <mat-form-field class="full-width-input">
            <input matInput placeholder="{{'email' | translate}}" type="email" [formControl]="username" required>
        </mat-form-field>
        <mat-form-field class="full-width-input">
            <input [type]="hide ? 'password' : 'text'" matInput placeholder="{{'password' | translate}}" [formControl]="password" required>
            <mat-icon matSuffix color="primary" (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </mat-form-field>
        <mat-spinner [style.display]="showSpinner ? 'block' : 'none'"></mat-spinner>
    </mat-card-content>
    <mat-card-actions>
        <button mat-flat-button fxFill type="button" (click)="login()" color="primary">{{'log-in'| translate}}</button>
        <small> <a mat-button [routerLink]="'/recovery'">{{'recovery' | translate}}?</a></small>
    </mat-card-actions>
</mat-card>