import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  constructor(
    private translate: TranslateService
  ) { }

  exportToExcel(data: any, sheetName: string, reportName: string, columns: any = null) {  
    let result = this.translateExcel(data, columns);
    this.createExport(result, sheetName, reportName);
  }

  createExport(translatedData:any,  sheetName: string, reportName: string){
      let workSheet = XLSX.utils.json_to_sheet(translatedData);   
      let wscols = [{ wpx: 150 }, { wpx: 200 }, { wpx: 150 }, { wpx: 150 }];
      workSheet['!cols'] = wscols; // set cell width
      const workBook: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, sheetName);
      XLSX.writeFile(workBook, reportName); 
  }

  translateExcel(data:any, columnsExcel: any = null){
    let totalArray = [];
     let translateArr = this.translate.translations[environment.defaultLang];
     let divisionParams = ["lowerPercent", "itoFix", "itoPercent", "lowerFix"];
     let selectedColumns = columnsExcel ? columnsExcel.filter(f => f.isShow == true).map(cd => cd.def) : null;
     data.forEach(function (dataValue, dataKey) {
      let newDataValue = {};
      Object.entries(dataValue).forEach(([key, val]) => {
        if (selectedColumns == null || selectedColumns.indexOf(key) !== -1){
          if (divisionParams.includes(key)) {
            val = Number(val) / 100;
          }
          else{
            if (key.toLowerCase() == "paynetreference") {
              val = val?.toString();
            }
            else if (key.toLowerCase() == "parameters") {
              let parameter_str = "";
              if (val != null) {
                Object.entries(val).forEach(([paramsKey, paramsValue]) => {
                  parameter_str += (translateArr[paramsKey.toLowerCase()] ?? paramsKey) + ": " + paramsValue + " ";
                });
              }
              val = parameter_str;
            }
          }
          
          if(translateArr[key] in newDataValue){
            newDataValue[(translateArr[key] ?? key) + " 2"] = val;
          }
          else{
            newDataValue[translateArr[key] ?? key] = val;
          }

        }
      });
      totalArray.push(newDataValue);
    });
    return totalArray;
  }

  getAllColumnsExcel (data:any){
    let columnsExcel = [];
    let translateArr = this.translate.translations[environment.defaultLang];
    let excludedParams = ["currency", "kkmSerialNumber", "kkmId", "subs", "fromAccount", "cityId", "expandable"];
    data.forEach(function (dataValue, dataKey) { 
      if(!excludedParams.includes(dataValue)){
        columnsExcel.push({ def: dataValue, isShow: true, label: translateArr[dataValue] ?? dataValue});  
      }           
    });
    return columnsExcel;
  }
}
