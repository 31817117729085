import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ILowerAdvanced } from 'src/app/interfaces/ilower-advanced';
import { AgentService } from 'src/app/services/agent.service';
import { AuthService } from 'src/app/services/auth.service';
import { UpperCommissionComponent } from '../rates/upper-commission/upper-commission.component';
import { RatesService } from 'src/app/services/rates.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ExportService } from 'src/app/services/export.service';
import { ServicesService } from 'src/app/services/services.service';
import { IService } from 'src/app/interfaces/iservice';
import { MatPaginator, } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TariffsService } from 'src/app/services/tariffs.service';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TariffRatesTreeComponent } from './tariff-rates-tree/tariff-rates-tree.component';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-all-rates',
  templateUrl: './all-rates.component.html',
  styleUrls: ['./all-rates.component.css']
})
export class AllRatesComponent implements OnInit {
  isCompleted: boolean = true;

  uppers: any[] = [];
  services: any[] = [];
  lowers: ILowerAdvanced[] = [];
  displayedColumns: string[] = ['serviceId', 
                                'serviceName',
                                'tariffName',
                                'isEnable', 
                                'itoPercent', 
                                'itoFix',
                                'lowerPercent',
                                'lowerFix', 
                                'updatedAt',
                                'paynetFixedAt',
                                'ukassaFixedAt',
                                'tariffTree'];
  dataSource: MatTableDataSource<ILowerAdvanced>;
  selectedService = new FormControl();
  isPaynetFixedAt = new FormControl();
  isUkassaFixedAt = new FormControl();
  public serviceAutoComplete$: Observable<any> = null;
  columnDefinitionsForExcel = [];
  // глобальные параметры валюты приложения
  globalLocation = environment.globalLocation;
  globalCurrencyCode = environment.globalCurrencyCode;
  
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(public authService: AuthService,
    private translate: TranslateService, 
    private servicesSrv: ServicesService,
    private exportSrv: ExportService,
    private tariffsSrv: TariffsService,
    public dialog: MatDialog,
  ) { }

  lookup(value: any): Observable<any> {  
    if (typeof value === 'object')
    {
      value = value.alias;
    } 

    return this.servicesSrv.search(value.toLowerCase()).pipe(
        // map the item property of the results as our return object
        map(results => results),
        // catch errors
        catchError(_ => {
          return of(null);
        })
      );
   }

  ngOnInit(): void {
    this.isPaynetFixedAt.setValue(-1);
    this.isUkassaFixedAt.setValue(-1);
    this.load();

    this.serviceAutoComplete$ = this.selectedService.valueChanges.pipe(
      startWith(''),
      // delay emits
      debounceTime(300),
      // use switch map so as to cancel previous subscribed events, before creating new once
      switchMap(value => {
        if (value !== '') {
          // lookup from services
          return this.lookup(value);
        } else {
          // if no value is pressent, return null
          return of(null);
        }
      })
    );
  }

  load() {
    //this.getServices();
    this.getCurrentRates();
  }

  getCurrentRates() {
    this.dataSource = null;
    this.lowers = [];
    
    this.isCompleted = false;
    
    var curServiceId = 0;
    if (this.selectedService.value != null && this.selectedService.value != undefined) {
        curServiceId = this.selectedService.value.id;
    }
    this.columnDefinitionsForExcel = [];
    this.tariffsSrv.ratesAdvanced(curServiceId, this.isPaynetFixedAt?.value, this.isUkassaFixedAt?.value).subscribe(
      (data: ILowerAdvanced[]) => {
        this.lowers = data;
        this.dataSource = new MatTableDataSource<ILowerAdvanced>(this.lowers?.sort((a, b) => a.serviceId - b.serviceId));
        this.dataSource.paginator = this.paginator;
        if(this.dataSource.filteredData[0]){
          this.columnDefinitionsForExcel = this.exportSrv.getAllColumnsExcel(Object.keys(this.dataSource.filteredData[0]));
        }
      },
      error => console.log(error)
    ).add(() => { this.isCompleted = true })
  }

  // View upper commission
  openAgentUpperCommissionDialog(item: any) 
  {
    const dialogRef = this.dialog.open(UpperCommissionComponent,
      { data: { service: item } });

    dialogRef.afterClosed().subscribe(result => {
    });  
  }

  openTariffTreeDialog(item: any) {
    const dialogRef = this.dialog.open(TariffRatesTreeComponent,
      { data: { tariff: item },
      maxHeight: '95vh' });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

   exportToExcel() {
    this.exportSrv.exportToExcel(this.dataSource.filteredData, 'Report', 'filename.xlsx', this.columnDefinitionsForExcel);
  }

  displayFn(service: IService): string {
    return service && service.alias ? service.alias : '';
  }

  getServices(){
    this.servicesSrv.get(-1,-1,null).subscribe(
      (data: any) => { 
        this.services = data?.sort((a, b) => a.serviceName - b.serviceName);
       },
      error => console.log(error)
    )
  }

  public getAllCurrentRates(){
    this.getCurrentRates();
  }
}
