import {Component, Inject, OnInit} from '@angular/core';
import {AuthService} from 'src/app/services/auth.service';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-roles-change',
  templateUrl: './roles-change.component.html',
  styleUrls: ['./roles-change.component.css']
})
export class RolesChangeComponent implements OnInit {
  roles: string[] = [];
  userRoles: string[] = [];
  isCompleted = false;
  isError = false;
  siteErrorMessage = '';
  serverErrorMessage = '';
  serverErrorCode = '';
  serverErrorStatusText = '';
  serverErrorFullMessage = '';

  constructor(public authService: AuthService,
              @Inject(MAT_DIALOG_DATA) public agent: any,
              public dialog: MatDialog,
              private translate: TranslateService,
              public snackBar: MatSnackBar) {
  }

  async ngOnInit() {
    this.isCompleted = false;

    await this.getAllRoles().then(async (allRoles) => {
      this.roles = allRoles;
      await this.getUserRoles().then(userRoles => {
        this.userRoles = userRoles;
      });
    });

    this.isCompleted = true;
  }

  async getAllRoles(): Promise<string[]> {
    return await this.authService.getRoles().toPromise()
      .then(allRoles => {
        return allRoles;
      })
      .catch(errorResponse => {
        this.serverErrorMessage = (typeof errorResponse.error === 'string') ? errorResponse.error : this.translate.instant('change-roles-unknown-server-error');
        this.serverErrorCode = errorResponse.status;
        this.serverErrorStatusText = errorResponse.statusText;
        this.siteErrorMessage = this.translate.instant('change-roles-get-roles-error');
        this.serverErrorFullMessage = `${this.translate.instant('change-roles-server-error-message')}${this.translate.instant('change-roles-server-error-code')}${this.serverErrorCode} ${this.serverErrorStatusText}. ${this.serverErrorMessage}`;
        this.isError = true;
      });
  }

  async getUserRoles(): Promise<string[]> {
    return await this.authService.getUserRoles(this.agent.agent.id).toPromise()
      .then(userRoles => {
        return userRoles;
      })
      .catch(errorResponse => {
        this.serverErrorMessage = (typeof errorResponse.error === 'string') ? errorResponse.error : this.translate.instant('change-roles-unknown-server-error');
        this.serverErrorCode = errorResponse.status;
        this.serverErrorStatusText = errorResponse.statusText;
        this.siteErrorMessage = this.translate.instant('change-roles-user-roles-error');
        this.serverErrorFullMessage = `${this.translate.instant('change-roles-server-error-message')}${this.translate.instant('change-roles-server-error-code')}${this.serverErrorCode} ${this.serverErrorStatusText}. ${this.serverErrorMessage}`;
        this.isError = true;
      });
  }

  saveRoles(selectedOptions: any) {
    const selectedRoles: string[] = selectedOptions.map((option: any) => option.value);
    let message: string;

    if (selectedRoles.toString() === this.userRoles.toString()) {
      message = this.translate.instant('change-roles-save-no-changes');
      this.openSnackBar(message, '');
      this.closeAddAgentDialog();
      return;
    }

    this.authService.saveUserRoles(this.agent.agent.id, selectedRoles).subscribe(() => {
      message = this.translate.instant('change-roles-save-success');
      this.openSnackBar(message, '');
      this.closeAddAgentDialog();
    }, saveError => {
      this.serverErrorMessage = saveError.error;
      this.serverErrorCode = saveError.status;
      this.serverErrorStatusText = saveError.statusText;
      message = `${this.translate.instant('change-roles-save-error')}. ${this.translate.instant('change-roles-server-error-message')}${this.translate.instant('change-roles-server-error-code')}${this.serverErrorCode} ${this.serverErrorStatusText}. ${this.serverErrorMessage}`;
      this.openSnackBar(message, '');
    });
  }

  closeAddAgentDialog() {
    this.dialog.closeAll();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }
}
