import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { GetMonthList } from 'src/app/helpers/month-list';
import { AgentService } from 'src/app/services/agent.service';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css'],
})
export class ConfirmDialogComponent implements OnInit {

  payableAgents: string[] =[];
  objectKeys: any[] = [];
  isCompleted: boolean = false;
  confirmBtnStatus = false;
  monthList = GetMonthList();

  constructor(
    @Inject(MAT_DIALOG_DATA) public agents: any,
    private agentsSrv: AgentService,
    private translate: TranslateService, 
    public snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<ConfirmDialogComponent>
  ) {}

  ngOnInit(): void {
      this.objectKeys = Object.values(this.agents.agents);
      this.objectKeys.forEach(obj => 
      {
        this.payableAgents.push(obj.id);
      });
  }

  payLowerCommission() {
    this.confirmBtnStatus = true;
    this.agentsSrv.payLowerCommissionToAgents({agents: this.payableAgents,month: Number(this.agents.month), year: Number(this.agents.year)}).subscribe(
      () => {
        this.dialogRef.close(true);
        let message = this.translate.instant('pay-commission-succeded');
        this.openSnackBar(message, 'x');
      },
      (error) => {
        this.confirmBtnStatus = false;
        if (error.status === 404) {
          let message = this.translate.instant('not-found');
          this.openSnackBar(message, 'x');
        } 
        else if (error.status === 400) {
          let message = this.translate.instant(error?.error?.error, { agent: error?.error?.agent });
          this.openSnackBar(message, 'x'); 
        } 
        else if (error.status === 500) {
          let message = this.translate.instant('service-is-temporarily-unavailable');
          this.openSnackBar(message, 'x');
        } 
        else {
          let message = this.translate.instant('an-error-occurred-while-processing');
          this.openSnackBar(message, 'x');         
        }
      }
    ).add(() => { this.isCompleted = true }); 
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }
}
