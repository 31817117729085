<div fxLayout="column" fxLayoutAlign="center center">
    <h1 class="mat-headline">{{'agent-cashoff'| translate}}</h1>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="!isCompleted"></mat-progress-bar>
  <div *ngIf="isCompleted">
    <form [formGroup]="cashoffForm" novalidate (ngSubmit)="update(cashoffForm.value)">
        <mat-dialog-content class="mat-typography">
            <h3 *ngIf="item">{{'agent' | translate}} : {{item.agent.company}}</h3>
            
            <mat-form-field fxFill>
                <input matInput type="text" placeholder="{{'cashoff-sum' | translate}}" formControlName="cashoffSum" currencyMask>

                <mat-error *ngIf="cashoffForm.controls['cashoffSum'].hasError('required') || cashoffForm.controls['cashoffSum'].hasError('min')">
                    {{'cashoff-sum-is-req' | translate}}
                </mat-error>
            </mat-form-field>

            <mat-form-field fxFill>
                <textarea matInput placeholder="{{'comment' | translate}}" formControlName="comment"></textarea>
            </mat-form-field>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-button mat-dialog-close>{{'cancel'| translate}}</button>
            <button mat-flat-button color="primary" type="submit" [disabled]="!cashoffForm.valid"
                [mat-dialog-close]="true" cdkFocusInitial>{{'cashoff'| translate}}</button>
        </mat-dialog-actions>
    </form>
  </div>