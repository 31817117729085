import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ICategory } from '../interfaces/icategory';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {
  host: string;

  constructor(private http: HttpClient) {
    this.host = environment.api_url + '/api/categories'
  }

  get(): Observable<ICategory[]> {
    let url = this.host;
    return this.http.get<ICategory[]>(url);
  }

  move(categoryId: number, direction: number): Observable<any> {
    let url = this.host + '/' + categoryId + '/' + direction;
    return this.http.put<any[]>(url, null);
  };
}
