<div fxLayout="column" fxLayoutAlign="center center">
    <h1 class="mat-headline" *ngIf="item.news.id != 0">{{'news-editing' | translate}} {{item.news?.title}}</h1>
    <h1 class="mat-headline" *ngIf="item.news.id == 0">{{'news-adding' | translate}}</h1>
</div>
<mat-progress-bar *ngIf="!isCompleted" mode="indeterminate"></mat-progress-bar>
<form [formGroup]="newsForm" novalidate (ngSubmit)="createOrUpdateNews(newsForm.value)">
    <mat-card class="mat-elevation-z0">
        <mat-card-content>

            <div>
                <div>
                    <mat-form-field fxFill>
                        <input matInput placeholder="{{'news-title'| translate}}" formControlName="title">
                        <mat-error *ngIf="newsForm.controls['title'].hasError('required')">
                            {{'title-is-req' | translate}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div>
                <div>
                    <mat-form-field fxFill>
                        <input type="text" placeholder="{{'agent'| translate}}" matInput [formControl]="agentId"
                            [matAutocomplete]="auto">
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" autoActiveFirstOption>
                            <mat-option *ngFor="let option of agentAutoComplete$ | async; let index = index"
                                [value]="option">
                                {{option.title}}
                            </mat-option>
                        </mat-autocomplete>
                        <button *ngIf="agentId != null" mat-button matSuffix mat-icon-button aria-label="Clear"
                            (click)="clearSearch()">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
            </div>

            <div>
                <div>
                    <angular-editor id="editor1" placeholder="{{'content' | translate}}" formControlName="text">
                    </angular-editor>
                </div>
                <div>
                  <label for="fileInput" class="fileInputLabel">{{'loadImage' | translate}}</label>
                  <input id="fileInput" type="file" accept=".png, .jpg, .jpeg" (change)="onFileSelected($event)" style="display: none;">
                </div>
            </div>

        </mat-card-content>
        <mat-dialog-actions align="end">
            <button mat-button mat-dialog-close>{{'cancel'| translate}}</button>
            <button mat-flat-button color="primary" type="submit" [disabled]="!newsForm.valid" [mat-dialog-close]="true"
                cdkFocusInitial>
                <span *ngIf="item.news.id != 0">{{'save'| translate}}</span>
                <span *ngIf="item.news.id == 0">{{'add'| translate}}</span>
            </button>
        </mat-dialog-actions>
    </mat-card>
</form>
