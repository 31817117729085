
<div fxLayout="column" fxLayoutAlign="center center">
    <h1 class="mat-headline">{{'manage-upper-commissions'| translate}}</h1>
</div>

<div class="container" fxLayout fxLayout.xs="column" fxLayoutAlign="center center" fxLayoutGap="10px"
    fxLayoutGap.xs="0">
    <mat-form-field fxFlex="40">
        <input type="text" placeholder="{{'agent'| translate}}" matInput [formControl]="agentId"
            [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" autoActiveFirstOption (optionSelected)="getUpperCommissionsByAgent()">
            <mat-option *ngFor="let option of agentAutoComplete$ | async; let index = index" [value]="option">
                {{option.title}}
            </mat-option>
        </mat-autocomplete>
        
        <mat-hint class="color-red">
            {{'upper-commissions-list-hint' | translate}}
        </mat-hint>
        <button *ngIf="agentId != null" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
            <mat-icon>close</mat-icon>
          </button>
    </mat-form-field>

    <button mat-icon-button color="primary" (click)="getUpperCommissionsByAgent()">
        <mat-icon>search</mat-icon>
    </button>
</div>

<mat-progress-bar mode="indeterminate" *ngIf="!isCompleted"></mat-progress-bar>
<div fxLayout="column" fxLayoutAlign="none">

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z0">

        <!-- serviceId Column -->
        <ng-container matColumnDef="serviceId">
            <th mat-header-cell *matHeaderCellDef> {{'serviceId' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.serviceId}} </td>
        </ng-container>

        <!-- serviceName Column -->
        <ng-container matColumnDef="serviceName">
            <th mat-header-cell *matHeaderCellDef> {{'alias' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.serviceName }} </td>
        </ng-container>
        
        <!-- minFix Column -->
        <ng-container matColumnDef="minFix">
            <th mat-header-cell *matHeaderCellDef> {{'min-fix' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.minFix }} </td>
        </ng-container>

        <!-- maxFix Column -->
        <ng-container matColumnDef="maxFix">
            <th mat-header-cell *matHeaderCellDef> {{'max-fix' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.maxFix }} </td>
        </ng-container>

        <!-- minPercent Column -->
        <ng-container matColumnDef="minPercent">
            <th mat-header-cell *matHeaderCellDef> {{'min-percent' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.minPercent }} </td>
        </ng-container>

        <!-- maxPercent Column -->
        <ng-container matColumnDef="maxPercent">
            <th mat-header-cell *matHeaderCellDef> {{'max-percent' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.maxPercent }} </td>
        </ng-container>

        <!-- isAvailableInPaynet Column -->
        <ng-container matColumnDef="isAvailableInPaynet">
            <th mat-header-cell *matHeaderCellDef> {{'isAvailableInPaynet' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.isAvailableInPaynet.toString() | translate}} </td>
        </ng-container>
        
        <!-- isActive Column -->
        <ng-container matColumnDef="isEnable">
            <th mat-header-cell *matHeaderCellDef> {{'isEnable' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.isEnable.toString() | translate}}
            </td>
        </ng-container>

        <!-- action Column -->
        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> {{'settings' | translate}} </th>
            <td mat-cell *matCellDef="let element">
                <div>
                    <button mat-icon-button color="primary" (click)="openAgentUpperCommissionEditDialog(element)">
                        <mat-icon>menu</mat-icon>
                    </button>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>