import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { IReportTransactions } from 'src/app/interfaces/reports/itransactions';
import { AgentService } from 'src/app/services/agent.service';
import { ReportService } from 'src/app/services/report.service';
import { MatDialog } from '@angular/material/dialog';
import { DictionaryService } from 'src/app/services/dictionary.service';
import { ExportService } from 'src/app/services/export.service';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { IAccount } from 'src/app/interfaces/iaccount';
import { ServicesService } from 'src/app/services/services.service';
import { IService } from 'src/app/interfaces/iservice';
import { TransactionDetailsComponent } from './transaction-details/transaction-details.component';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.css']
})
export class TransactionsComponent implements OnInit {
  isCompleted: boolean = false;

  columnDefinitions = [
    { def: 'status', isShow: true, 'label': 'Статус' },
    { def: 'fromToAccountName', isShow: true, label: 'Отправитель' },
    { def: 'createdAt', isShow: true, label: 'Создано' },
    { def: 'completedAt', isShow: true, label: 'Выполнено' },
    { def: 'accepted', isShow: true, label: 'Принято' },
    { def: 'amount', isShow: true, label: 'Зачислено' },
    { def: 'commission', isShow: true, label: 'ВК' },
    { def: 'canceledAmount', isShow: true, label: 'Отменено' },
    { def: 'serviceInfo', isShow: true, label: 'Услуга' },
    { def: 'description', isShow: false, label: 'Описание' },
    { def: 'commissionRates', isShow: true, label: 'Комиссии' },
    { def: 'id', isShow: true, label: 'ID транзакции' },
    { def: 'paynetReference', isShow: false, label: 'ID транзакции в Paynet' },
    { def: 'isFiscal', isShow: false, label: 'Фискальный признак' },
    { def: 'IsPaymentInOwnFavor', isShow: true, label: 'Платеж в пользу агента' },
    { def: 'action', isShow: true, label: 'Действия' },
  ];

  columnDefinitionsForExcel = [];

  dataSource: IReportTransactions[] = [];

  services: any[] = [];
  public transactions: IReportTransactions[] = [];
  public accounts: IAccount[] = [];
  public types: any[] = [];
  public transactionTypes: any[] = [];
  public transactionStatuses: any[] = [];

  selectedAgent = new FormControl();
  public agentAutoComplete$: Observable<any> = null;

  selectedService = new FormControl();
  public serviceAutoComplete$: Observable<any> = null;

  dateFrom = new FormControl(new Date(new Date().setDate(new Date().getDate() - 4)));
  dateTo = new FormControl(new Date());
  transactionType = new FormControl();
  transactionStatus = new FormControl();
  abonentParam = new FormControl();
  public fileName: string;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  datasourceTableForPagination: MatTableDataSource<IReportTransactions>;
  // глобальные параметры валюты приложения
  globalLocation = environment.globalLocation;
  globalCurrencyCode = environment.globalCurrencyCode;
  globalCurrencySymbol = environment.globalCurrencySymbol;

  constructor(private reportSrv: ReportService,
    private dictionarySrv: DictionaryService,
    private translate: TranslateService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private exportSrv: ExportService,
    private agentSrv: AgentService,
    private servicesSrv: ServicesService,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['AgentId']) {
        this.selectedAgent.setValue({ code: params['AgentId'] });
      }
      if (params['Type']) {
        this.transactionType.setValue(params['Type']);
      }
    });

  }

  lookup(value: any): Observable<any> {
    if (typeof value === 'object') {
      value = value.title;
    }

    return this.agentSrv.search(value.toLowerCase()).pipe(
      // map the item property of the results as our return object
      map(results => results),
      // catch errors
      catchError(_ => {
        return of(null);
      })
    );
  }

  lookupServices(value: any): Observable<any> {
    if (typeof value === 'object') {
      value = value.alias;
    }

    return this.servicesSrv.search(value.toLowerCase()).pipe(
      // map the item property of the results as our return object
      map(results => results),
      // catch errors
      catchError(_ => {
        return of(null);
      })
    );
  }

  ngOnInit(): void {
    this.load();

    this.agentAutoComplete$ = this.selectedAgent.valueChanges.pipe(
      startWith(''),
      // delay emits
      debounceTime(300),
      // use switch map so as to cancel previous subscribed events, before creating new once
      switchMap(value => {
        if (value !== '') {
          // lookup from agents
          return this.lookup(value);
        } else {
          // if no value is pressent, return null
          return of(null);
        }
      })
    );

    this.serviceAutoComplete$ = this.selectedService.valueChanges.pipe(
      startWith(''),
      // delay emits
      debounceTime(300),
      // use switch map so as to cancel previous subscribed events, before creating new once
      switchMap(value => {
        if (value !== '') {
          // lookup from services
          return this.lookupServices(value);
        } else {
          // if no value is pressent, return null
          return of(null);
        }
      })
    );
  }

  getDisplayedColumns(): string[] {
    return this.columnDefinitions
      .filter(cd => cd.isShow == true)
      .map(cd => cd.def);
  }

  updateTableColumns(i, isShow) {
    this.columnDefinitions[i].isShow = !isShow;
  }

  load() {
    this.getTransactionType();
    this.getTransactionStatuses();
    this.getAccounts();
  }

  getReport(userId = "") {
    var curUserId = null;
    if (userId.toString().length > 0) {
      curUserId = userId;
    }
    else if (this.selectedAgent.value != null && this.selectedAgent.value != undefined) {
      curUserId = this.selectedAgent.value.code;
    }

    var curServiceId = null;
    if (this.selectedService.value != null && this.selectedService.value != undefined) {
      curServiceId = this.selectedService.value.id;
    }

    this.isCompleted = false;
    this.dataSource = [];
    this.columnDefinitionsForExcel = [];
    this.datasourceTableForPagination = new MatTableDataSource<IReportTransactions>();
    this.reportSrv.transactionsReport(curServiceId, curUserId, this.dateFrom.value, this.dateTo.value, this.transactionType?.value, this.transactionStatus?.value, this.abonentParam.value)
      .subscribe(
        data => {
          this.transactions = data;
          this.dataSource = this.transactions;
          this.datasourceTableForPagination = new MatTableDataSource<IReportTransactions>(data)
          this.datasourceTableForPagination.paginator = this.paginator;
          if(this.dataSource[0]){
            this.columnDefinitionsForExcel = this.exportSrv.getAllColumnsExcel(Object.keys(this.dataSource[0]));
          }
        },
        error => {
          if (error.status === 404) {
            let message = this.translate.instant('no-data');
            this.openSnackBar(message, 'x');
          }
          else
            if (error.status === 400) {
              let message = this.translate.instant('an-error-occurred-while-processing');
              this.openSnackBar(message, 'x');
            }
            else
              if (error.status === 500) {
                let message = this.translate.instant('service-is-temporarily-unavailable');
                this.openSnackBar(message, 'x');
              }
              else {
                let message = this.translate.instant('an-error-occurred-while-processing');
                this.openSnackBar(message, 'x');
              }
        }
      ).add(() => { this.isCompleted = true });
  }

  getAccounts() {
    this.accounts = [];
    this.dictionarySrv.agents().subscribe(data => {
      this.accounts = data;

      if (this.selectedAgent.value != null && this.selectedAgent.value != undefined) {
        let readyAgent = data.filter(cd => cd.code == this.selectedAgent.value.code);
        if (readyAgent[0]) {
          this.selectedAgent.setValue(readyAgent[0]);
        }
      }
      this.getReport();
    },
      error => {
        if (error.status === 404) {
          let message = this.translate.instant('no-data');
          this.openSnackBar(message, 'x');
        }
        else
          if (error.status === 400) {
            let message = this.translate.instant('bad-request');
            this.openSnackBar(message, 'x');
          }
          else
            if (error.status === 500) {
              let message = this.translate.instant('service-is-temporarily-unavailable');
              this.openSnackBar(message, 'x');
            }
            else {
              let message = this.translate.instant('an-error-occurred-while-processing');
              this.openSnackBar(message, 'x');
            }
      }
    );
  }

  getTransactionType() {
    this.dictionarySrv.transactionTypes().subscribe(
      (data: any[]) => {
        this.transactionTypes = data;
      },
      error => console.log(error)
    )
  }

  getTransactionStatuses() {
    this.dictionarySrv.transactionStatuses().subscribe(
      (data: any[]) => {
        this.transactionStatuses = data;
      },
      error => console.log(error)
    )
  }

  displayFn(account: any): string {
    return account && account.title ? account.title : '';
  }

  displayFnService(service: IService): string {
    return service && service.alias ? service.alias : '';
  }

  getTotalAccepted() {
    return this.dataSource.map(t => t.Accepted).reduce((acc, value) => acc + value, 0);
  }
  getTotalUserAmount() {
    return this.dataSource.map(t => t.Amount).reduce((acc, value) => acc + value, 0);
  }
  getTotalUpper() {
    return this.dataSource.map(t => t.Commission).reduce((acc, value) => acc + value, 0);
  }
  getTotalCanceledAmount() {
    return this.dataSource.map(t => t.CanceledAmount).reduce((acc, value) => acc + value, 0);
  }

  public updateReport() {
    this.getReport();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  exportToExcel() {
    this.exportSrv.exportToExcel(this.dataSource, 'Report', 'filename.xlsx', this.columnDefinitionsForExcel);
  }

  clearSearch() {
    this.selectedAgent.setValue("");
    this.getReport();
  }

  clearSearchParam() {
    this.abonentParam.setValue(null);
    this.getReport();
  }

  clearSearchService() {
    this.selectedService.setValue(null);
    this.getReport();
  }

  openTransactionDetailDialog(item: any) {
    const dialogRef = this.dialog.open(TransactionDetailsComponent,
      {
        data: { transaction: item },
        autoFocus: false,
        maxHeight: '95vh',
        panelClass: 'transactions-details-dialog'
      });
  }

  checkTransactionsCommission() {
    this.transactions.forEach(function (item, key) {

    });
  }

}
