import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BothRequired } from 'src/app/helpers/both-required';
import { IRegistration } from 'src/app/interfaces/iregistration';
import { AgentService } from 'src/app/services/agent.service';
import { DictionaryService } from 'src/app/services/dictionary.service';
import { TariffsService } from 'src/app/services/tariffs.service';
import { appConfig } from 'src/assets/config/application-config';

@Component({
  selector: 'app-add-agent',
  templateUrl: './add-agent.component.html',
  styleUrls: ['./add-agent.component.css']
})
export class AddAgentComponent implements OnInit {
  iinLengthCheck = appConfig["add_agent_iin_length"];
  phonePrefix = appConfig["add_agent_phone_prefix"];
  phoneLengthMax = appConfig["add_agent_phone_length"];

  addAgentForm = this.fb.group({
    email: [null, [Validators.required, Validators.email]],
    lname: [null, Validators.required],
    name: [null, Validators.required],
    pname: [null],
    phone: [null, [Validators.required, Validators.pattern(appConfig["add_agent_phone_pattern"])]],
    address: [null, Validators.required],
    iin: [null, [Validators.required, Validators.pattern(appConfig["add_agent_iin_pattern"])]],
    region: [null, Validators.required],
    cityId: [null, Validators.required],
    parentAgentId: [null, Validators.required],
    parentAgentName: [null],
    template: [null, Validators.required],
    company: [null],
    kkmId: [null, [Validators.pattern('^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$')]],
    kkmSerialNumber: [null],
    ownerId: [null],
    terminalId: [null],
  },
  {
    validators: [BothRequired('kkmId', 'kkmSerialNumber'), BothRequired('kkmId', 'ownerId')]
  });

  hide = true;
  hasUnitNumber = false;

  regions = [];
  cities = [];
  templates = [];
  agents: any[] = [];
  fiscalizationOrganizations = [];
  isCompleted: boolean = false;
  constructor(@Inject(MAT_DIALOG_DATA) public item: any,
    private fb: FormBuilder,
    private router: Router,
    private agentSrv: AgentService,
    private tariffsSrv: TariffsService,
    public snackBar: MatSnackBar,
    private translate: TranslateService,
    private dictionarySrv: DictionaryService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.getRegions();
    this.getCities();
    this.getFiscalizationOrganizations();
    this.getSubTemplates();
    this.addAgentForm.controls['parentAgentId'].setValue(this.item.agent.id);
    this.addAgentForm.controls['parentAgentName'].setValue(this.item.agent.company);
  }

  getRegions() {
    this.dictionarySrv.getRegions().subscribe(
      (data: any) => { this.regions = data; },
      error => console.log(error)
    )
  }

  getCities() {
    this.dictionarySrv.getCities().subscribe(
      (data: any) => { this.cities = data; },
      error => console.log(error)
    )
  }

  getFiscalizationOrganizations() {
    this.dictionarySrv.getFiscalizationOrganizations().subscribe(
      (data: any) => { this.fiscalizationOrganizations = data; },
      error => console.log(error)
    )
  }

  getSubTemplates() {
    this.agentSrv.subTariffs(this.item.agent.id).subscribe(
      (data: any) => { this.templates = data; },
      error => {
        if (error.status === 404) {
          let message = this.translate.instant('not-found');
          this.openSnackBar(message, 'x');
        }
        else
          if (error.status === 400) {
            let message = this.translate.instant('an-error-occurred-while-processing');
            this.openSnackBar(message, 'x');
          }
          else
            if (error.status === 500) {
              let message = this.translate.instant('service-is-temporarily-unavailable');
              this.openSnackBar(message, 'x');
            }
            else {
              let message = this.translate.instant('an-error-occurred-while-processing');
              this.openSnackBar(message, 'x');
            }
      }
    ).add(() => { this.isCompleted = true });
  }

  // convenience getter for easy access to form fields
  get f() { return this.addAgentForm.controls; }

  public hasError = (controlName: string, errorName: string) => {
    return this.addAgentForm.controls[controlName].hasError(errorName);
  }

  public create = (addAgentForm) => {
    if (this.addAgentForm.valid) {
      this.executeCreation(addAgentForm);
    }
  }

  private executeCreation = (data) => {
    this.isCompleted = false;
    let json: IRegistration = {
      lname: data.lname,
      fname: data.name,
      pname: data.pname,
      company: data.company,
      companyBin: data.iin.toString(),
      address: data.address,
      email: data.email,
      iin: data.iin.toString(),
      phone: Number(data.phone),
      templateId: Number(data.template),
      regionId: Number(data.region),
      cityId: Number(data.cityId),
      parentId: data.parentAgentId,
      kkmId: data.kkmId,
      kkmSerialNumber: data.kkmSerialNumber,
      ownerId: data.ownerId,
      terminalId: data.terminalId
    }

    this.agentSrv.createSubAgent(json)
      .subscribe(
        data => {
          // Агент успешно создан!
          window.location.href = "/settings/agents";
        },
        error => {
          if (error.status === 409) {
            let message = this.translate.instant('user-is-already-registered');
            this.openSnackBar(message, 'x');
          }
          else
            if (error.status === 400) {
              let message = this.translate.instant(error?.error?.message);
              this.openSnackBar(message, 'x');
            }
            else
              if (error.status === 500) {
                let message = this.translate.instant('service-is-temporarily-unavailable');
                this.openSnackBar(message, 'x');
              }
              else {
                let message = this.translate.instant('an-error-occurred-while-processing');
                this.openSnackBar(message, 'x');
              }
        }
      ).add(() => { this.isCompleted = true });
  }


  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  displayFn(account: any): string {
    return account && account.company ? account.company : '';
  }

  closeAddAgentDialog(){
    const dialogRef = this.dialog.closeAll();
  }

}
