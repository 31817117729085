import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { IService } from '../interfaces/iservice';
import { query } from '@angular/animations';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {
  host: string;

  constructor(private http: HttpClient) {
    this.host = environment.api_url + '/api/services'
  }

  get(isPaynetFixed: number, isUkassaFixed: number, serviceId: number): Observable<IService[]> {
    let url = this.host;
    let json = {
      isPaynetFixed: Number(isPaynetFixed) ,
      isUkassaFixed: Number(isUkassaFixed),
      serviceId: Number(serviceId)
    }
    return this.http.post<any>(url, json);
  }

  update(id: number, enable: boolean): Observable<any> {
    let url = this.host + '/' + id + '/' + enable;
    return this.http.put<any[]>(url, null);
  };

  upper(IdService: number): Observable<any> {
    let url = this.host + '/' + IdService + '/upper';
    return this.http.get<any>(url);
  };

  search(query:string): Observable<any[]> {
    const url = this.host + '/' + query;
    return this.http.get<any[]>(url);
  }

  searchAll(query:string): Observable<any[]> {
    const url = this.host + '/' + query + '/all';
    return this.http.get<any[]>(url);
  }
}

