import { Component, OnInit } from '@angular/core';
import { IAccountBalance } from 'src/app/interfaces/reports/iaccount-balance';
import { FormControl } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { AccountService } from 'src/app/services/account.service';

import { ViewChild, ElementRef } from '@angular/core';
import { ExportService } from 'src/app/services/export.service';
import { IAccount } from 'src/app/interfaces/iaccount';
import { AgentService } from 'src/app/services/agent.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DictionaryService } from 'src/app/services/dictionary.service';
import { ReportService } from 'src/app/services/report.service';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-account-balance',
  templateUrl: './account-balance.component.html',
  styleUrls: ['./account-balance.component.css']
})
export class AccountBalanceComponent implements OnInit {

  displayedColumns: string[] = ['companyBIN', 'company', 'balance'];
  dataSource: IAccountBalance[] = [];
  public accountBalances: IAccountBalance[] = [];
  public accounts: IAccount[] = [];

  agentId = new FormControl();
  dateFrom = new FormControl(new Date());
  dateTo = new FormControl(new Date());
  public agentAutoComplete$: Observable<any> = null;
  isCompleted: boolean = false;
  columnDefinitionsForExcel = [];
  // глобальные параметры валюты приложения
  globalLocation = environment.globalLocation;
  globalCurrencyCode = environment.globalCurrencyCode;

  constructor(
    private agentSrv: AgentService,
    private authSrv: AuthService,
    private accountSrv: AccountService,
    private exportSrv: ExportService,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private dictionarySrv: DictionaryService,
    private reportSrv: ReportService
  ) { }

  lookup(value: any): Observable<any> {  
    if (typeof value === 'object')
    {
      value = value.title;
    } 
    
    return this.agentSrv.search(value.toLowerCase()).pipe(
        // map the item property of the results as our return object
        map(results => results),
        // catch errors
        catchError(_ => {
          return of(null);
        })
      );
    }
    
  ngOnInit(): void {
    if (!this.authSrv.checkAuthenticated()){
      return;  
    }
    this.load();
    
    this.agentAutoComplete$ = this.agentId.valueChanges.pipe(
      startWith(''),
      // delay emits
      debounceTime(300),
      // use switch map so as to cancel previous subscribed events, before creating new once
      switchMap(value => {
        if (value !== '') {
          // lookup from agents
          return this.lookup(value);
        } else {
          // if no value is pressent, return null
          return of(null);
        }
      })
    );

  }
  load() {
    this.getReport();
    this.getAccounts();
  }
  getReport() {
    this.isCompleted = false;
    this.dataSource = [];

    var curAgentId = null;
    if (this.agentId.value != null && this.agentId.value != undefined) {
        curAgentId = this.agentId.value.code;
    }
    this.columnDefinitionsForExcel = [];
    this.reportSrv.balances(this.dateTo.value, this.dateFrom.value, curAgentId).subscribe(data => {
      this.dataSource = data;
      if(this.dataSource[0]){
        this.columnDefinitionsForExcel = this.exportSrv.getAllColumnsExcel(Object.keys(this.dataSource[0]));
      }
      },
      error => {
        if (error.status === 404) {
          let message = this.translate.instant('no-data');
          this.openSnackBar(message, 'x');
        }
        else
          if (error.status === 400) {
            let message = this.translate.instant('bad-request');
            this.openSnackBar(message, 'x');
          }
          else
            if (error.status === 500) {
              let message = this.translate.instant('service-is-temporarily-unavailable');
              this.openSnackBar(message, 'x');
            }
            else {
              let message = this.translate.instant('an-error-occurred-while-processing');
              this.openSnackBar(message, 'x');
            }
      }
      ).add(() => { this.isCompleted = true });
  }


  
  getAccounts() {
    this.accounts = [];
    this.dictionarySrv.agents().subscribe(data => {
      this.accounts = data;
      },
      error => {
        if (error.status === 404) {
          let message = this.translate.instant('no-data');
          this.openSnackBar(message, 'x');
        }
        else
          if (error.status === 400) {
            let message = this.translate.instant('bad-request');
            this.openSnackBar(message, 'x');
          }
          else
            if (error.status === 500) {
              let message = this.translate.instant('service-is-temporarily-unavailable');
              this.openSnackBar(message, 'x');
            }
            else {
              let message = this.translate.instant('an-error-occurred-while-processing');
              this.openSnackBar(message, 'x');
            }
      }
      );
  }

  displayFn(account: IAccount): string {
    return account && account.title ? account.title : '';
  }
  /** Gets the total cost of all balances. */
  getTotal() {
    return this.dataSource.map(t => t.balance).reduce((acc, value) => acc + value, 0);
  }

  exportToExcel() {
    this.exportSrv.exportToExcel(this.dataSource, 'Report', 'filename.xlsx', this.columnDefinitionsForExcel);
  }

  public dateChanged()
  {
    this.getReport();
  }

  public accountChanged()
  {
    this.getReport();
  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  clearSearch(){
    this.agentId.setValue("");
      this.getReport();   
  }
}
