<h2 mat-dialog-title>{{'confirm-action' | translate}}</h2>
<mat-dialog-content class="mat-typography">
  <h3>{{'lower-commissions-rewrite-warning' | translate}}</h3>
    <div *ngIf="data.incorrectServiceList">
        <p *ngFor="let service of data.incorrectServiceList; let i = index">{{i+1}}) {{service.serviceName}}</p>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{'cancel' | translate}}</button>
  <button mat-button [mat-dialog-close]="true" (click)="confirmRewrite()" cdkFocusInitial>{{'confirm' | translate}}</button>
</mat-dialog-actions>