import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { IService } from '../interfaces/iservice';

@Injectable({
  providedIn: 'root'
})
export class TariffsService {

  host: string;

  constructor(private http: HttpClient) {
    this.host = environment.api_url + '/api/tariffs'
  }

  ratesAdvanced(serviceId: number, isPaynetFixedAt: number, isUkassaFixedAt: number): Observable<any> {
    let url = this.host + '/tariff-values';

    let json: any = {
      serviceId: serviceId,
      isPaynetFixedAt: Number(isPaynetFixedAt),
      isUkassaFixedAt: Number(isUkassaFixedAt)
    }
    return this.http.post<any[]>(url, json);
  };

  getTariffs(): Observable<any> {
    let url = this.host;
    return this.http.get<any[]>(url);
  };

  getTariff(tariffId: number): Observable<any> {
    let url = this.host + '/' + tariffId + '/info';
    return this.http.get<any[]>(url);
  };

  getTariffsTree(): Observable<any> {
    let url = this.host + '/tree';
    return this.http.get<any[]>(url);
  };

  deleteTariff(tariffId: number): Observable<any> {
    const url = this.host + '/delete/' + tariffId;
    return this.http.delete<any[]>(url);
  }

  restoreTariff(tariffId: number): Observable<any> {
    const url = this.host + '/restore';
    return this.http.post<any[]>(url, tariffId);
  }

  getServicesLowers(tariffId: number): Observable<any> {
    let url = this.host + '/' + tariffId + '/services-lowers';
    return this.http.get<any[]>(url);
  };

  updateTariffValues(json: any, tariffId: number){
      let url = this.host + '/update-tariff/' + tariffId;
      return this.http.post<any[]>(url, json);
  }

  addTariff(json: any){
    let url = this.host + '/add-tariff';
    return this.http.post<any[]>(url, json);
  }

  upperCommissionsByAgent(account: string){
    let url = this.host + '/upper-commissions';

    let params = new HttpParams();

    if (account != null && account != undefined) {
      params = params.append('AgentId', account.toString());
    }

    return this.http.get<any[]>(url, { params: params });
  }

  upperCommissionsByServiceAgent(serviceId: number, agentId: string){
    let url = this.host + '/upper-commissions-by-service';

    let params = new HttpParams();

    if (agentId != null && agentId != undefined) {
      params = params.append('agentId', agentId.toString());
    }

    if (serviceId != null && serviceId != undefined) {
      params = params.append('serviceId', serviceId.toString());
    }
    return this.http.get<any>(url, { params: params });
  }


  updateUpperCommissionsByService(json:any, serviceId: number){
    let url = this.host + '/update-upper-commission/' + serviceId;
      return this.http.post<any[]>(url, json);
  }

  getRatesTariffTree(tariffId: number, serviceId: number){
    let url = this.host + '/tariff-rates-tree/' + tariffId + '/' + serviceId;
      return this.http.get<any[]>(url);
  }

  search(query:string): Observable<any[]> {
    const url = this.host + '/search/' + query;
    return this.http.get<any[]>(url);
  }
}
