import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { IAccount } from 'src/app/interfaces/iaccount';
import { AgentService } from 'src/app/services/agent.service';
import { NewsService } from 'src/app/services/news.service';

@Component({
  selector: 'app-edit-news',
  templateUrl: './edit-news.component.html',
  styleUrls: ['./edit-news.component.css']
})

export class EditNewsComponent implements OnInit {
  selectedFile: File;
  agentId = new FormControl();
  public agentAutoComplete$: Observable<any> = null;

  isCompleted: boolean = false;
  newsForm = this.fb.group({
    id: [this.item.news.id],
    title: [this.item.news.title, Validators.required],
    text: [this.item.news.text]
  });

  constructor(@Inject(MAT_DIALOG_DATA) public item: any,
  private agentSrv: AgentService,
  private fb: FormBuilder,
  private newsSrv: NewsService,
  public snackBar: MatSnackBar,
  private translate: TranslateService) { }

  ngOnInit(): void {
    if(this.item.news.id > 0){
      this.getNews();
    }
    else{
      this.isCompleted = true;
    }

    this.agentAutoComplete$ = this.agentId.valueChanges.pipe(
      startWith(''),
      // delay emits
      debounceTime(300),
      // use switch map so as to cancel previous subscribed events, before creating new once
      switchMap(value => {
        if (value !== '') {
          // lookup from agents
          return this.lookup(value);
        } else {
          // if no value is pressent, return null
          return of(null);
        }
      })
    );
  }

  lookup(value: any): Observable<any> {
    if (typeof value === 'object')
    {
      value = value.title;
    }

    return this.agentSrv.search(value.toLowerCase()).pipe(
        // map the item property of the results as our return object
        map(results => results),
        // catch errors
        catchError(_ => {
          return of(null);
        })
      );
    }

  getNews(){
    this.isCompleted = false;
    this.newsSrv.getNews(this.item.news.id).subscribe(
      (data: any) => {
        this.newsForm.controls['title'].setValue(data.title);
        this.newsForm.controls['text'].setValue(data.text);
        this.agentId.setValue({code: data.agentId, title: data.agentName});
      },
      error => console.log(error)
    ).add(() => { this.isCompleted = true });
  }

  get f() { return this.newsForm.controls; }
  public hasError = (controlName: string, errorName: string) => {
    return this.newsForm.controls[controlName].hasError(errorName);
  }

  public createOrUpdateNews = (form) => {
    if (this.newsForm.valid) {
      this.execute(form);
    }
  }

  private execute = (data) => {
    this.isCompleted = false;
    const form = new FormData();
    form.append('title', data.title);
    form.append('text', data.text);
    if (this.agentId !== null && data.agentId !== undefined) {
      form.append('agentId', data.agentId || null);
    }
    form.append('newsType', '1');
    form.append('imageFile', this.selectedFile);

    this.newsSrv.createOrUpdate(data.id,form)
    .subscribe(
      data => {
        let message = this.translate.instant('success-save-news');
        this.openSnackBar(message, 'x');
      },
      error => {
        if (error.status === 404 || error.status === 400) {
          let message = error.error;
          this.openSnackBar(message, 'x');
        }

        else if (error.status === 409) {
          let message = this.translate.instant('an-error-occurred-while-processing');
          this.openSnackBar(message, 'x');
        }
        else{
            if (error.status === 500) {
              let message = this.translate.instant('service-is-temporarily-unavailable');
              this.openSnackBar(message, 'x');
            }
            else {
              let message = this.translate.instant('an-error-occurred-while-processing');
              this.openSnackBar(message, 'x');
            }
        }
      }
    ).add(() => { this.isCompleted = true });

  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  clearSearch(){
    this.agentId.setValue(null);
  }

  displayFn(account: IAccount): string {
    return account && account.title ? account.title : '';
  }

  onFileSelected(event) {
    this.selectedFile = event.target.files[0];
  }
}
