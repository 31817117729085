import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { IAccount } from 'src/app/interfaces/iaccount';
import { ILower } from 'src/app/interfaces/ilower';
import { AgentService } from 'src/app/services/agent.service';
import { DictionaryService } from 'src/app/services/dictionary.service';
import { TariffsService } from 'src/app/services/tariffs.service';

@Component({
  selector: 'app-add-tariff',
  templateUrl: './add-tariff.component.html',
  styleUrls: ['./add-tariff.component.css']
})
export class AddTariffComponent implements OnInit {

  isCompleted: boolean = false;
  lowers: ILower[] = [];
  tariffForm: FormGroup;
  
  tariffInfo: any;
  data: any[];
  tariffId: number;
  incorrectServiceList: any[]; 
  public accounts: IAccount[] = [];
  agentId = new FormControl();
  public agentAutoComplete$: Observable<any> = null;
  searchService = new FormControl();
  serviceStatus = new FormControl("1");
  previousIndexTarriff: number = -1;
  saveBtnActive: boolean = true;
  constructor(private fb: FormBuilder,
              public snackBar: MatSnackBar,
              private translate: TranslateService,
              private tariffsSrv: TariffsService,
              public dialog: MatDialog,
              private route: ActivatedRoute,
              private agentSrv: AgentService,
              private dictionarySrv: DictionaryService) { }

  lookup(value: any): Observable<any> {  
    if (typeof value === 'object')
    {
      value = value.title;
    } 
    
    return this.agentSrv.search(value.toLowerCase()).pipe(
        // map the item property of the results as our return object
        map(results => results),
        // catch errors
        catchError(_ => {
          return of(null);
        })
      );
    }

  ngOnInit(): void {
    this.agentAutoComplete$ = this.agentId.valueChanges.pipe(
      startWith(''),
      // delay emits
      debounceTime(300),
      // use switch map so as to cancel previous subscribed events, before creating new once
      switchMap(value => {
        if (value !== '') {
          // lookup from agents
          return this.lookup(value);
        } else {
          // if no value is pressent, return null
          return of(null);
        }
      })
    );

    this.getAccounts();
    this.load();
  }

  getAccounts() {
      this.accounts = [];
      this.dictionarySrv.agents().subscribe(data => {
          this.accounts = data;
        },
        error => {
          if (error.status === 404) {
            let message = this.translate.instant('no-data');
            this.openSnackBar(message, 'x');
          }
          else
            if (error.status === 400) {
              let message = this.translate.instant('bad-request');
              this.openSnackBar(message, 'x');
            }
            else
              if (error.status === 500) {
                let message = this.translate.instant('service-is-temporarily-unavailable');
                this.openSnackBar(message, 'x');
              }
              else {
                let message = this.translate.instant('an-error-occurred-while-processing');
                this.openSnackBar(message, 'x');
              }
        }
        );
  }

  load() {
    this.route.paramMap.pipe(
      switchMap(params => params.getAll('id'))
    )
      .subscribe(data => this.tariffId = +data);

    this.getTariff(this.tariffId);
  }


  get tariffValues() {
    return (<FormArray>this.tariffForm.get('tariffValues')).controls;
  }

  getTariff(tariffId) {
    this.tariffsSrv.getTariff(tariffId).subscribe(
      (data: any) => { 
        this.tariffInfo = data;
        this.getValuesByTariff(this.tariffInfo.id);
      },
      error => {
        this.isCompleted = true; 
        if (error.status === 409) {
          let message = this.translate.instant('an-error-occurred-while-processing');
          this.openSnackBar(message, 'x');
        }
        else
          if (error.status === 400) {
            let message = this.translate.instant('an-error-occurred-while-processing');
            this.openSnackBar(message, 'x');
          }
          else
            if (error.status === 500) {
              let message = this.translate.instant('service-is-temporarily-unavailable');
              this.openSnackBar(message, 'x');
            }
            else {
              let message = this.translate.instant('an-error-occurred-while-processing');
              this.openSnackBar(message, 'x');
            }
      }
    )
  }

  getValuesByTariff(tariffId) {
    this.tariffsSrv.getServicesLowers(tariffId).subscribe(
      (data: any) => { 
        this.lowers = data;
        this.tariffForm = this.fb.group({
            name: new FormControl("", Validators.required),
            userId: new FormControl(this.tariffInfo.userId),
            tariffValues: this.fb.array(this.getTariffValues())
        });
      },
      error => {
        if (error.status === 409) {
          let message = this.translate.instant('an-error-occurred-while-processing');
          this.openSnackBar(message, 'x');
        }
        else
          if (error.status === 400) {
            let message = this.translate.instant('an-error-occurred-while-processing');
            this.openSnackBar(message, 'x');
          }
          else
            if (error.status === 500) {
              let message = this.translate.instant('service-is-temporarily-unavailable');
              this.openSnackBar(message, 'x');
            }
            else {
              let message = this.translate.instant('an-error-occurred-while-processing');
              this.openSnackBar(message, 'x');
            }
      }
    ).add(() => { this.isCompleted = true })
  }

  // convenience getter for easy access to form fields
  get f() { return this.tariffForm.controls; }
  public hasError = (controlName: string, errorName: string) => {
    return this.tariffForm.controls[controlName].hasError(errorName);
  }

  public update = (formContent) => {
    if (this.tariffForm.valid) {
      this.execute(formContent);
    }
  }

  private execute = (data) => {
    this.saveBtnActive = false;
    var curUserId = null;
    this.incorrectServiceList = null;

    if(this.agentId.value != null && this.agentId.value != undefined) {
        curUserId = this.agentId.value.code;
    }

    let json: any = {
      name: data.name,
      parentId: this.tariffInfo.id,
      userId: curUserId,
      tariffValues: data.tariffValues
    }

    this.tariffsSrv.addTariff(json)
      .subscribe(
        data => {
          let message = this.translate.instant('tariff-success-added');
          this.openSnackBar(message, 'x');    
          window.location.href = "/settings/tariffs";
        },
        error => {
          this.saveBtnActive = true;
          if (error.status === 409) {
            let message = this.translate.instant('an-error-occurred-while-processing');
            this.openSnackBar(message, 'x');
          }
          else
            if (error.status === 400) {
              let message = this.translate.instant(error.error.message);
              this.openSnackBar(message, 'x');
              this.incorrectServiceList = error.error.incorrectServiceList;
            }
            else
              if (error.status === 500) {
                let message = this.translate.instant('service-is-temporarily-unavailable');
                this.openSnackBar(message, 'x');
              }
              else {
                let message = this.translate.instant('an-error-occurred-while-processing');
                this.openSnackBar(message, 'x');
              }
        }
      );
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }


  getTariffValues() {
    return this.lowers.map(lower => this.fb.group({
      serviceId: new FormControl(lower.serviceId, Validators.required),
      serviceName: new FormControl(lower.serviceName, Validators.required),
      isEnable: new FormControl(lower.isEnable, Validators.required),
      itoPercent: new FormControl(lower.itoPercent / 100, [Validators.required, Validators.max(lower.itoPercent / 100)]),
      itoFix: new FormControl(lower.itoFix / 100, [Validators.required, Validators.max(lower.itoFix / 100)]),
      lowerPercent: new FormControl(lower.lowerPercent / 100, [Validators.required, Validators.min(0), Validators.max(lower.lowerPercent / 100)]),
      lowerFix: new FormControl(lower.lowerFix / 100, [Validators.required, Validators.min(0), Validators.max(lower.lowerFix / 100)]),
      maxItoPercent: new FormControl(lower.itoPercent / 100),
      maxItoFix: new FormControl(lower.itoFix / 100),
      maxLowerPercent: new FormControl(lower.lowerPercent / 100),
      maxLowerFix: new FormControl(lower.lowerFix / 100),
      isEdit: new FormControl(false),
      isShow: new FormControl(lower.isEnable),
    }));
  }

  displayFn(account: any): string {
    return account && account.title ? account.title : '';
  }

  clearSearch(){
    this.agentId.setValue("");
    return false;
  }

  clearSearchService(){
    this.searchService.setValue("");
    this.searchByFilter();
  }

  setEditableCommissions(index){
    if(this.previousIndexTarriff == -1 || (<FormArray>this.tariffForm.get('tariffValues')).controls[this.previousIndexTarriff]?.valid){
      (<FormArray>this.tariffForm.get('tariffValues')).controls[this.previousIndexTarriff]?.get("isEdit").setValue(false);
      (<FormArray>this.tariffForm.get('tariffValues')).controls[index].get("isEdit").setValue(true);
      this.previousIndexTarriff = index;      
    }
  }

  saveCommissions(index){
    if((<FormArray>this.tariffForm.get('tariffValues')).controls[index].valid){
      (<FormArray>this.tariffForm.get('tariffValues')).controls[index].get("isEdit").setValue(false);
    }
  }

  searchByFilter(){   
    this.isCompleted = false;  
    let serviceStatus = this.serviceStatus.value;
    let search = this.searchService.value ?? "";
     (<FormArray>this.tariffForm.get('tariffValues')).controls.forEach(function(element, i) {
          if((element["controls"]["isEnable"].value == true && serviceStatus == 1) && element["controls"]["serviceName"].value.toLowerCase().indexOf(search.toLowerCase()) != -1){
             element["controls"]["isShow"].setValue(true);
          }
          else if ((element["controls"]["isEnable"].value == false && serviceStatus == 0) && element["controls"]["serviceName"].value.toLowerCase().indexOf(search.toLowerCase()) != -1){
              element["controls"]["isShow"].setValue(true);
          }
          else if ((serviceStatus == -1) && element["controls"]["serviceName"].value.toLowerCase().indexOf(search.toLowerCase()) != -1) {
            element["controls"]["isShow"].setValue(true);
          }
          else{
            element["controls"]["isShow"].setValue(false);
          }
      });
      setTimeout(() => {
        this.isCompleted = true; 
      }, 1);  
  }
}
