<div class="container">
    <p class="title-p">
        {{'nk-pay-confirmation-title'| translate}} 
        <br> <b> {{'date' | translate}}: {{monthList[agents.month] | translate}} {{agents.year}} </b>
    </p>
    <mat-list>
        <mat-list-item *ngFor="let agent of  objectKeys"> {{ agent.lname }} {{ agent.fname }} ({{ agent.phone }})</mat-list-item>
    </mat-list> 
    <mat-progress-bar mode="indeterminate" *ngIf="confirmBtnStatus"></mat-progress-bar>
    <div class="buttons">
        <button mat-button mat-dialog-close [disabled]="confirmBtnStatus">{{'cancel'| translate}}</button>
        <button mat-raised-button color="primary" (click)="payLowerCommission()" [disabled]="confirmBtnStatus">{{'apply'| translate}}</button> 
    </div>
</div>