<div fxLayout="column" fxLayoutAlign="center center">
    <h1 class="mat-headline">{{'balanceSummary'| translate}}</h1>
    <h4 class="mat-subheading-1">{{'balance-summary-description' | translate}}</h4>
</div>
<div class="container" fxLayout fxLayout.xs="column" fxLayoutAlign="center center" fxLayoutGap="10px"
    fxLayoutGap.xs="0">
    <mat-form-field fxFlex="40">
        <input type="text" placeholder="{{'agent'| translate}}" matInput [formControl]="agentId"
            [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" autoActiveFirstOption (optionSelected)="accountChanged()">
            <mat-option *ngFor="let option of agentAutoComplete$ | async; let index = index" [value]="option">
                {{option.title}}
            </mat-option>
        </mat-autocomplete>
        <button *ngIf="agentId != null" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
            <mat-icon>close</mat-icon>
          </button>
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{'dateFrom' | translate}}</mat-label>
        <input matInput [matDatepicker]="pickerFrom" (click)="pickerFrom.open()" [formControl]="dateFrom" placeholder="{{'dateFrom'| translate}}" (dateChange)="dateFromChanged()" readonly>
        <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
        <mat-datepicker #pickerFrom></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
        <mat-label>{{'dateTo' | translate}}</mat-label>
        <input matInput [matDatepicker]="pickerTo"  (click)="pickerTo.open()" [formControl]="dateTo" placeholder="{{'dateTo'| translate}}" (dateChange)="dateToChanged()" readonly>
        <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
        <mat-datepicker #pickerTo></mat-datepicker>
    </mat-form-field>

    <button mat-icon-button color="primary" (click)="getReport()">
        <mat-icon>search</mat-icon>
    </button>

    <button mat-icon-button color="primary" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="mainMenuButtonExcel" [disabled]="dataSource?.length < 1">
        <mat-icon>save_alt</mat-icon>
    </button>
    <mat-menu #mainMenuButtonExcel="matMenu">
        <div class="table-column-list-block">
            <section class="button-download">
                <button mat-raised-button color="primary"  (click)="exportToExcel()" >
                    {{'export' | translate}}
                    <mat-icon>save_alt</mat-icon>
                </button>   
            </section>
            <span (click)="$event.stopPropagation()" *ngFor="let element of columnDefinitionsForExcel;index as i">
                <mat-slide-toggle color="primary" (change)="element.isShow = !element.isShow" [checked]="element.isShow == true"></mat-slide-toggle>
                {{element.label}}
                <br>
            </span>
        </div>
    </mat-menu>
</div>
<mat-progress-bar mode="indeterminate" *ngIf="!isCompleted"></mat-progress-bar>
<div fxLayout="column" fxLayoutAlign="none">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z0">

        <!-- date Column -->
        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef> {{'date' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.date | date: 'dd/MM/yyyy'}} </td>        
        </ng-container>

        <!-- startBalance Column -->
        <ng-container matColumnDef="startBalance">
            <th mat-header-cell *matHeaderCellDef> {{'startBalance' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.startBalance | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </td>
        </ng-container>

        <!-- income Column -->
        <ng-container matColumnDef="income">
            <th mat-header-cell *matHeaderCellDef> {{'income' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.income | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </td>
        </ng-container>

        <!-- outcome Column -->
        <ng-container matColumnDef="outcome">
            <th mat-header-cell *matHeaderCellDef> {{'outcome' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.outcome | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </td>
        </ng-container>

        <!-- endBalance Column -->
        <ng-container matColumnDef="endBalance">
            <th mat-header-cell *matHeaderCellDef> {{'endBalance' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.endBalance | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>