import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IResponse } from '../interfaces/iresponse';
import { IAccountBalance } from '../interfaces/reports/iaccount-balance';
import { IAccount } from '../interfaces/iaccount';
import { IConsolidatedReport } from '../interfaces/reports/iconsolidated-report';
import { IConsolidatedReportExtended } from '../interfaces/reports/iconsolidated-report-extended';
import { IProvidersReport } from '../interfaces/reports/iproviders-report';
import { IIncomeReport } from '../interfaces/reports/iincome-report';
import * as moment from 'moment';
import { IReportTransactions } from '../interfaces/reports/itransactions';
import { IAgentsReport } from '../interfaces/reports/iagents-report';
import { IRegionReport } from '../interfaces/reports/iregion-report';


@Injectable({
  providedIn: 'root'
})
export class ReportService {
  host: string;

  constructor(private http: HttpClient) {
    this.host = environment.api_url + '/api/reports'
  }

  balances(dateTo: Date, dateFrom: Date, account: number): Observable<IAccountBalance[]> {
    let url = this.host + '/balances';
    let params = new HttpParams();
    if (dateFrom != null && dateFrom != undefined) {
      params = params.append('DateFrom', moment(dateFrom).format("YYYY-MM-DDT00:00:00.000") + "Z");
    }
    if (dateTo != null && dateTo != undefined) {
      params = params.append('DateTo', moment(dateTo).format("YYYY-MM-DDT23:59:59.000") + "Z");
    }
    if (account != null && account != undefined) {
      params = params.append('AgentId', account.toString());
    }
    return this.http.get<IAccountBalance[]>(url, { params: params });
  }

  currentBalances(account: number): Observable<IAccountBalance[]> {
    let url = this.host + '/current-balances';    
    let params = new HttpParams();
    if (account != null && account != undefined) {
      params = params.append('AgentId', account.toString());
    }
    return this.http.get<IAccountBalance[]>(url, { params: params });
  }

  balance(account: number, dateFrom: Date, dateTo: Date): Observable<IConsolidatedReport[]> {
    let url = this.host + '/consolidated-report';
    let params = new HttpParams();
    if (dateFrom != null && dateFrom != undefined) {
      params = params.append('DateFrom', moment(dateFrom).format("YYYY-MM-DDT00:00:00.000") + "Z");
    }
    if (dateTo != null && dateTo != undefined) {
      params = params.append('DateTo', moment(dateTo).format("YYYY-MM-DDT23:59:59.000") + "Z");
    }
    if (account != null && account != undefined) {
      params = params.append('AgentId', account.toString());
    }
    return this.http.get<IConsolidatedReport[]>(url, { params: params });
  }

  balanceExtended(account: number, dateFrom: Date, dateTo: Date): Observable<IConsolidatedReportExtended[]> {
    let url = this.host + '/consolidated-report-extended';

    let params = new HttpParams();
    if (dateFrom != null && dateFrom != undefined) {
      params = params.append('DateFrom', moment(dateFrom).format("YYYY-MM-DDT00:00:00.000") + "Z");
    }
    if (dateTo != null && dateTo != undefined) {
      params = params.append('DateTo', moment(dateTo).format("YYYY-MM-DDT23:59:59.000") + "Z");
    }
    if (account != null && account != undefined) {
      params = params.append('AgentId', account.toString());
    }

    return this.http.get<IConsolidatedReportExtended[]>(url, { params: params });
  }

  providersReport(account: number, dateFrom: Date, dateTo: Date): Observable<IProvidersReport[]> {
    var dateFromNew = null;
    var dateToNew = null;
    
    if(dateFrom != null){
      dateFromNew = moment(dateFrom).format("YYYY-MM-DDT00:00:00.000") + "Z";
    }
    
    if(dateTo != null){
      dateToNew = moment(dateTo).format("YYYY-MM-DDT23:59:59.000") + "Z";
    }
    let url = this.host  + '/providers-report';

    let json: any = {
      agentId: account,
      dateFrom: dateFromNew,   
      dateTo: dateToNew
    }    
    return this.http.post<IProvidersReport[]>(url, json);
  }

  // Отчет по транзакциям
  transactionsReport(serviceId: number, account: string, dateFrom: Date, dateTo: Date, type: number, status: number, abonentParam: string): Observable<IReportTransactions[]> {
    var dateFromNew = null;
    var dateToNew = null;
    
    if(dateFrom != null){
      dateFromNew = moment(dateFrom).format("YYYY-MM-DDT00:00:00.000") + "Z";
    }
    
    if(dateTo != null){
      dateToNew = moment(dateTo).format("YYYY-MM-DDT23:59:59.000") + "Z";
    }
    let url = this.host + '/transactions';

    if(status != null){
        status = Number(status);
    }
    if(type != null){
      type = Number(type);
    }
    
    let json: any = {
      agentId: account,
      type: type,     
      status: status,
      dateFrom: dateFromNew,   
      dateTo: dateToNew,
      serviceId: serviceId,
      params: abonentParam
    }    
    return this.http.post<IReportTransactions[]>(url, json);
  }

  // Отчет по агентам
  agentsReport(account: string, dateFrom: Date, dateTo: Date): Observable<IAgentsReport[]> {
    var dateFromNew = null;
    var dateToNew = null;
    
    if(dateFrom != null){
      dateFromNew = moment(dateFrom).format("YYYY-MM-DDT00:00:00.000") + "Z";
    }
    
    if(dateTo != null){
      dateToNew = moment(dateTo).format("YYYY-MM-DDT23:59:59.000") + "Z";
    }
    let url = this.host + '/agents';

    let json: any = {
      agentId: account,
      dateFrom: dateFromNew,   
      dateTo: dateToNew,
    }    
    return this.http.post<IAgentsReport[]>(url, json);
  }

  incomeReport(account: number, dateFrom: Date, dateTo: Date): Observable<IIncomeReport[]> {
    let url = this.host + '/income-report';

    let params = new HttpParams();
    if (dateFrom != null && dateFrom != undefined) {
      params = params.append('DateFrom', moment(dateFrom).format("YYYY-MM-DDT00:00:00.000") + "Z");
    }
    if (dateTo != null && dateTo != undefined) {
      params = params.append('DateTo', moment(dateTo).format("YYYY-MM-DDT23:59:59.000") + "Z");
    }
    if (account != null && account != undefined) {
      params = params.append('AgentId', account.toString());
    }

    return this.http.get<IIncomeReport[]>(url, { params: params });
  }

  paidLowerCommissionReport(serviceId: number, agentId: string, transactionDateFrom: Date, transactionDateTo: Date): Observable<any> {
    let url = this.host + '/paid-lower-commission-report';
    var transactionDateFromNew = null;
    var transactionDateToNew = null;
    var serviceIdNew = null;
    if(transactionDateFrom != null){
        transactionDateFromNew = moment(transactionDateFrom).format("YYYY-MM-DDT00:00:00.000") + "Z";
    }
    if(transactionDateTo != null){
        transactionDateToNew = moment(transactionDateTo).format("YYYY-MM-DDT23:59:59.999") + "Z";
    }
    if(serviceId != null){
      serviceIdNew = Number(serviceId);
    }

    let json =
    {
      transactionDateFrom: transactionDateFromNew,
      transactionDateTo: transactionDateToNew,
      serviceId: serviceIdNew,
      agentId: agentId
    }
    return this.http.post<any>(url, json);
  };

  regionReport(serviceId: number, cityId: number, agentId: string, dateFrom: Date, dateTo: Date, mode: string): Observable<IRegionReport[]> {
    let url = this.host + '/region-report';
    // if(mode == "beta"){
    //     url = this.host + '/region-report/beta';
    // }
    

    var dateFromNew = null;
    var dateToNew = null;
    var cityIdNew = 0;
    var agentIdNew = null;
    var serviceIdNew = null;

    if(dateFrom != null){
      dateFromNew = moment(dateFrom).format("YYYY-MM-DDT00:00:00.000") + "Z";
    }
    
    if(dateTo != null){
      dateToNew = moment(dateTo).format("YYYY-MM-DDT23:59:59.000") + "Z";
    }

    if (agentId != null && agentId != undefined) {
        agentIdNew = agentId.toString();
    } 

    if (serviceId != null && serviceId != undefined) {
        serviceIdNew = Number(serviceId);
    } 

    if (cityId != null && cityId != undefined) {
        cityIdNew = Number(cityId);
    }
    
    let json: any = {
      agentId: agentIdNew,
      cityId: cityIdNew,
      dateFrom: dateFromNew,   
      dateTo: dateToNew,
      serviceId: serviceIdNew
    }    
    return this.http.post<IRegionReport[]>(url, json);
  }

  whatsAppReport(dateFrom: Date, dateTo: Date, account?: string, cityId?: number){
    
    let url = this.host + '/whats-app-report';
    var dateFromNew = null;
    var dateToNew = null;

    if(dateFrom != null){
      dateFromNew = moment(dateFrom).format("YYYY-MM-DDT00:00:00.000") + "Z";
    }
    
    if(dateTo != null){
      dateToNew = moment(dateTo).format("YYYY-MM-DDT23:59:59.000") + "Z";
    }

    let json: any = {
      agentId: account,
      cityId: cityId ? Number(cityId) : null,
      dateFrom: dateFromNew,   
      dateTo: dateToNew,
    }  
    return this.http.post<any>(url, json);
  }
}
