import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { IRefund } from 'src/app/interfaces/irefund';
import { DictionaryService } from 'src/app/services/dictionary.service';
import { ExportService } from 'src/app/services/export.service';
import { RefundsService } from 'src/app/services/refunds.service';
import { EditRefundRequestComponent } from './edit-refund-request/edit-refund-request.component';

@Component({
  selector: 'app-refund-requests',
  templateUrl: './refund-requests.component.html',
  styleUrls: ['./refund-requests.component.css']
})
export class RefundRequestsComponent implements OnInit {

  isCompleted: boolean = false;
  dataSource: IRefund[] = [];
  columnDefinitionsForExcel = [];

  columnDefinitions = [
    { def: 'id', isShow: false, 'label': 'Номер заявки' }, 
    { def: 'transactionInfo', isShow: true, 'label': 'Данные транзакции' }, 
    { def: 'agentInfo', isShow: true, label: 'Данные агента' },
    { def: 'transactionAmount', isShow: true, label: 'Сумма к зачислению'  },
    { def: 'canceledAmount', isShow: true, label: 'Сумма фактической отмены'  },
    { def: 'agentComment', isShow: true, label: 'Комментарий агента' },
    { def: 'userComment', isShow: true, label: 'Комментарий сотрудника' },
    { def: 'userName', isShow: false, label: 'Сотрудник' },
    { def: 'createdAt', isShow: true, label: 'Дата создания заявки' },  
    { def: 'updatedAt', isShow: false, label: 'Дата изменения' },
    { def: 'paynetReference', isShow: false, label: 'Референс платежа' },
    { def: 'canceledAt', isShow: true, label: 'Дата отмены' },
    { def: 'statusName', isShow: true, label: 'Статус заявки' },
    { def: 'action', isShow: true, label: 'Действия'  }
  ];

  id = new FormControl();
  statusId = new FormControl();
  statuses = [];

  dateFrom = new FormControl(new Date(new Date().setDate(new Date().getDate() - 10)));
  dateTo = new FormControl(new Date());
  refundStatusesColors = { 1: "sended", 2: "in-progress", 3: "partly-canceled", 4: "canceled", 5: "rejected" };

  constructor(private translate: TranslateService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private dictionarySrv: DictionaryService,
    private refundsSrv: RefundsService,
    private exportSrv: ExportService
  ) { }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.getRefundStatuses();
    this.getRefundRequests();
  }

  getRefundStatuses() {
    this.dictionarySrv.getRefundStatuses().subscribe(
      (data: any) => { this.statuses = data; },
      error => console.log(error)
    )
  }

  getRefundRequests() {
    this.isCompleted = false;
    this.dataSource=[];
    this.columnDefinitionsForExcel = [];

    this.refundsSrv.get(this.id.value ?? 0, this.statusId.value ?? 0, this.dateFrom.value,  this.dateTo.value).subscribe(
      (data: any[]) => {
        this.dataSource = data;
        if(this.dataSource[0]){
          this.columnDefinitionsForExcel = this.exportSrv.getAllColumnsExcel(Object.keys(this.dataSource[0]));
        }
      },
      error => {
        if (error.status === 404) {
          let message = this.translate.instant('not-found');
          this.openSnackBar(message, 'x');
        }
        else
          if (error.status === 400) {
            let message = this.translate.instant('an-error-occurred-while-processing');
            this.openSnackBar(message, 'x');
          }
          else
            if (error.status === 500) {
              let message = this.translate.instant('service-is-temporarily-unavailable');
              this.openSnackBar(message, 'x');
            }
            else {
              let message = this.translate.instant('an-error-occurred-while-processing');
              this.openSnackBar(message, 'x');
            }
      }
     ).add(() => { this.isCompleted = true });
}

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  searchRefundRequests(){
    this.getRefundRequests();
  }

  clearSearch(){
      this.id.setValue("");
      this.getRefundRequests();   
  }

  getDisplayedColumns(): string[] {
    return this.columnDefinitions
      .filter(cd => cd.isShow == true)
      .map(cd => cd.def);
  }

  exportToExcel() {
    this.exportSrv.exportToExcel(this.dataSource, 'Refund', 'filename.xlsx', this.columnDefinitionsForExcel);
  }

  // Edit refund request
  openRefundEditDialog(item: any) {
    const dialogRef = this.dialog.open(EditRefundRequestComponent,
      { data: { refund: item } });

    dialogRef.afterClosed().subscribe(result => {
      this.getRefundRequests();
    });
  }
}
