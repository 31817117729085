import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ILower } from 'src/app/interfaces/ilower';
import { AgentService } from 'src/app/services/agent.service';
import { AuthService } from 'src/app/services/auth.service';
import { UpperCommissionComponent } from './upper-commission/upper-commission.component';
import { RatesService } from 'src/app/services/rates.service';
import { FormControl, Validators } from '@angular/forms';
import { ExportService } from 'src/app/services/export.service';
import { TariffsService } from 'src/app/services/tariffs.service';
import { environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { ITariffs } from 'src/app/interfaces/itariffs';

@Component({
  selector: 'app-rates',
  templateUrl: './rates.component.html',
  styleUrls: ['./rates.component.css']
})
export class RatesComponent implements OnInit {
  isCompleted: boolean = true;

  uppers: any[] = [];
  tariffs: any[] = [];
  lowers: ILower[] = [];
  displayedColumns: string[] = ['serviceId', 'isEnable', 'serviceName', 'itoPercent', 'itoFix', 'lowerPercent', 'lowerFix'];
  dataSource: any[] = [];
  columnDefinitionsForExcel = [];
  // глобальные параметры валюты приложения
  globalLocation = environment.globalLocation;
  globalCurrencyCode = environment.globalCurrencyCode;
  public tariffAutoComplete$: Observable<any> = null;
  selectedTariff = new FormControl(null, Validators.required);

  constructor(public authService: AuthService, 
    private ratesSrv: RatesService,
    private exportSrv: ExportService,
    public dialog: MatDialog,
    private tariffsSrv: TariffsService,
  ) { }

  lookup(value: any): Observable<any> {  
    if (typeof value === 'object')
    {
      value = value.name;
    } 

    return this.tariffsSrv.search(value.toLowerCase()).pipe(
        // map the item property of the results as our return object
        map(results => results),
        // catch errors
        catchError(_ => {
          return of(null);
        })
      );
   }

  ngOnInit(): void {
    this.load();

    this.tariffAutoComplete$ = this.selectedTariff.valueChanges.pipe(
      startWith(''),
      // delay emits
      debounceTime(300),
      // use switch map so as to cancel previous subscribed events, before creating new once
      switchMap(value => {
        if (value !== '') {
          // lookup from services
          return this.lookup(value);
        } else {
          // if no value is pressent, return null
          return of(null);
        }
      })
    );
  }

  load() {
    this.getTariffs();
    this.validateForm();
    /*  this.getCurrentRates(); */
  }

  public validateForm = () => {
    if (this.selectedTariff.valid) {
        this.getCurrentRates();
    }
  }

  getCurrentRates() {
    this.dataSource = [];
    this.lowers = [];
    
    this.isCompleted = false;
    var curTariffId = 0;
    if (this.selectedTariff.value != null && this.selectedTariff.value != undefined) {
      curTariffId = this.selectedTariff.value.id;
    }

    this.columnDefinitionsForExcel = [];
    this.ratesSrv.rates(curTariffId).subscribe(
      (data: ILower[]) => {
        this.lowers = data;
        this.dataSource = this.lowers?.sort((a, b) => a.serviceId - b.serviceId);
        if(this.dataSource[0]){
          this.columnDefinitionsForExcel = this.exportSrv.getAllColumnsExcel(Object.keys(this.dataSource[0]));
        }
      },
      error => console.log(error)
    ).add(() => { this.isCompleted = true })
  }

  getTariffs() {
    this.tariffsSrv.getTariffs().subscribe(
      (data: any) => { 
        this.tariffs = data;
        if(data != null){
          this.selectedTariff.setValue(data[0]);
            this.getCurrentRates();
        }
       },
      error => console.log(error)
    )
  }

  // View upper commission
  openAgentUpperCommissionDialog(item: any) 
  {
    const dialogRef = this.dialog.open(UpperCommissionComponent,
      { data: { service: item } });

    dialogRef.afterClosed().subscribe(result => {
    });  
  }

   exportToExcel() {
    this.exportSrv.exportToExcel(this.dataSource, 'Report', 'filename.xlsx', this.columnDefinitionsForExcel);
  }

  displayFn(tariff: ITariffs): string {
    return tariff && tariff.name ? tariff.name : '';
  }

  clearSearchTariff() {
    this.selectedTariff.setValue("");
    this.getCurrentRates();
  }
}
