<div fxLayout="column" fxLayoutAlign="center center">
    <h1 class="mat-headline">{{'add-tariff'| translate}}</h1>
</div>
<mat-progress-bar mode="indeterminate" *ngIf="!isCompleted"></mat-progress-bar>
<div *ngIf="isCompleted && tariffInfo">
    <form [formGroup]="tariffForm" (ngSubmit)="update(tariffForm.value)">
        <div fxLayout="row" fxLayoutAlign="center" fxFill>  
            <div style="max-width: 1100px; margin: auto; width: 100%;">
                <div>
                    <label>{{'parent-tariff' | translate}}: {{tariffInfo?.name}}</label>
                </div>

                <div>
                    <mat-form-field fxFill>
                        <input matInput placeholder="{{'name' | translate}}" formControlName="name">
                        <mat-error *ngIf="tariffForm.controls['name'].hasError('required')">
                            {{'name-is-req'| translate}}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div>
                    <mat-form-field fxFill>
                        <input type="text" placeholder="{{'agent'| translate}}" matInput [formControl]="agentId"
                            [matAutocomplete]="auto">
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" autoActiveFirstOption>
                            <mat-option *ngFor="let option of agentAutoComplete$ | async; let index = index" [value]="option">
                                {{option.title}}
                            </mat-option>
                        </mat-autocomplete>
                        <button *ngIf="agentId != null" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
                            <mat-icon>close</mat-icon>
                          </button>
                    </mat-form-field>
                </div>
                <div class="mt-2">
                    <span class="weight-normal">{{'search-by-services' | translate}}</span>
                </div>

                <div  fxFill fxLayoutGap="20px">
                    <mat-form-field fxFlex="50%" >
                        <input type="text" placeholder="{{'search-service'| translate}}" matInput [formControl]="searchService" (change)="searchByFilter()">
                        <button type="button" *ngIf="searchService != null" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="clearSearchService()">
                            <mat-icon>close</mat-icon>
                          </button>
                    </mat-form-field>

                    <mat-form-field fxFlex="50%" >
                        <mat-label>{{'status-service' | translate}}</mat-label>
                        <mat-select class="region-selection-width" placeholder="{{'serviceName' | translate}}" [formControl]="serviceStatus" (selectionChange)="searchByFilter()">
                            <mat-option value="-1" selected>{{'select-all' | translate}}</mat-option>
                            <mat-option value="1">{{'active' | translate}}</mat-option>
                            <mat-option value="0">{{'not-active' | translate}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <button mat-icon-button color="primary" type="button" class="search-button" (click)="searchByFilter()">
                        <mat-icon>search</mat-icon>
                    </button>
                </div>

                <div>
                    <table>
                        <tr>
                            <th fxFlex="14%" fxLayoutAlign="left">{{'id'| translate}}</th>
                            <th fxFlex="18%" fxLayoutAlign="left">{{'check-service-name'| translate}}</th>
                            <th fxFlex="14%" fxLayoutAlign="left">{{'itoFix'| translate}}</th>
                            <th fxFlex="14%" fxLayoutAlign="left">{{'itoPercent'| translate}}</th>
                            <th fxFlex="14%" fxLayoutAlign="left">{{'lowerFix'| translate}}</th>
                            <th fxFlex="14%" fxLayoutAlign="left">{{'lowerPercent'| translate}}</th>
                            <th fxFlex="10%" fxLayoutAlign="left"> {{'actionTitle'| translate}}</th>
                        </tr>
                        <div formArrayName="tariffValues" fxFill>
                            <div *ngFor="let tariffValue of tariffValues;let tariffValueIndex=index;">
                                <div [formGroupName]="tariffValueIndex"  *ngIf="tariffValue.get('isShow').value == true">
        
                                    <tr *ngIf="tariffValue.get('isEdit').value == false; else elseBlock">
                                        <td fxFlex="14%">{{tariffValue.get('serviceId').value}}
                                            <mat-icon *ngIf="tariffValue.get('isEnable').value == false" title="{{'service-disabled'| translate}}" class="service-disabled-icon">lock</mat-icon>
                                            <mat-icon *ngIf="tariffValue.get('isEnable').value == true" title="{{'service-enabled'| translate}}" class="service-enabled-icon">lock_open</mat-icon>
                                        </td>
                                        <td fxFlex="18%">
                                            {{tariffValue.get('serviceName').value}} 
                                            
                                        </td>
                                        <td fxFlex="14%">{{tariffValue.get('itoFix').value}}</td>
                                        <td fxFlex="14%">{{tariffValue.get('itoPercent').value}}</td>
                                        <td fxFlex="14%">{{tariffValue.get('lowerFix').value}}</td>
                                        <td fxFlex="14%">{{tariffValue.get('lowerPercent').value}}</td>
                                        <td fxFlex="10%">
                                            <button (click)="setEditableCommissions(tariffValueIndex)" class="ml-2" mat-icon-button color="primary">
                                                <mat-icon>edit</mat-icon>
                                            </button>
                                        </td>
                                    </tr>
                                    <ng-template #elseBlock>
                                        <tr>
                                            <td fxFlex="14%">{{tariffValue.get('serviceId').value}}
                                                <mat-icon *ngIf="tariffValue.get('isEnable').value == false" title="{{'service-disabled'| translate}}" class="service-disabled-icon">lock</mat-icon>
                                                <mat-icon *ngIf="tariffValue.get('isEnable').value == true" title="{{'service-enabled'| translate}}" class="service-enabled-icon">lock_open</mat-icon>
                                            </td>
                                            <td fxFlex="18%">{{tariffValue.get('serviceName').value}}</td>
                                            <td fxFlex="14%">
                                                <mat-form-field>
                                                    <input matInput formControlName="itoFix" value="" placeholder="{{'itoFix' | translate}}" type="number" step="0.1">
                                                    <mat-error *ngIf="tariffValue.controls['itoFix'].hasError('max')">
                                                        {{'max-value'| translate}}: {{tariffValue.controls['maxItoFix'].value}}
                                                    </mat-error>
                                                </mat-form-field>
                                            </td>
                                            <td fxFlex="14%">
                                                <mat-form-field>
                                                    <input matInput formControlName="itoPercent" value="" placeholder="{{'itoPercent' | translate}}" type="number" step="0.1">
                                                    <mat-error *ngIf="tariffValue.controls['itoPercent'].hasError('max')">
                                                        {{'max-value'| translate}}: {{tariffValue.controls['maxItoPercent'].value}}
                                                    </mat-error>
                                                </mat-form-field>
                                            </td>
                                            <td fxFlex="14%">
                                                <mat-form-field>
                                                    <input matInput formControlName="lowerFix" value="" placeholder="{{'lowerFix' | translate}}" type="number" step="0.1">
                                                    <mat-error *ngIf="tariffValue.controls['lowerFix'].hasError('min') || tariffValue.controls['lowerFix'].hasError('max')">
                                                        {{'min-max-range'| translate}}: 0 - {{tariffValue.controls['maxLowerFix'].value}}
                                                    </mat-error>
                                                </mat-form-field>
                                            </td>
                                            <td fxFlex="14%">
                                                <mat-form-field>
                                                    <input matInput formControlName="lowerPercent" value="" placeholder="{{'lowerPercent' | translate}}" type="number" step="0.1">
                                                    <mat-error *ngIf="tariffValue.controls['lowerPercent'].hasError('min') || tariffValue.controls['lowerPercent'].hasError('max')">
                                                        {{'min-max-range'| translate}}: 0 - {{tariffValue.controls['maxLowerPercent'].value}}
                                                    </mat-error>
                                                </mat-form-field>
                                            </td>
                                            <td fxFlex="10%">
                                                <button mat-icon-button color="primary" class="ml-2" type="button" [disabled] = "(tariffValue.get('isEdit').value == true) && (!tariffValue.get('lowerPercent').valid) || (!tariffValue.get('itoPercent').valid) || (!tariffValue.get('itoFix').valid) ||  (!tariffValue.get('lowerFix').valid) " (click)="saveCommissions(tariffValueIndex)">
                                                    <mat-icon>save</mat-icon>
                                                </button>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </table>
                </div>   

                <div class="add-tariff-btn-block">
                    <mat-error class="error-tariff-service-list" *ngIf="incorrectServiceList != null" fxLayoutGap="20px">
                        <label *ngFor="let service of incorrectServiceList">
                            {{service.serviceName}} <br>
                        </label>
                    </mat-error>
                    <mat-card-actions fxLayoutGap="10px" fxLayoutAlign="end">
                        <a href="/settings/tariffs">
                            <button mat-flat-button type="button">{{'cancel' | translate}}</button>
                        </a>
                        <button mat-flat-button color="primary" type="submit" [disabled]="!tariffForm.valid || !isCompleted || saveBtnActive == false">{{'add' | translate}}</button>
                    </mat-card-actions>
                </div>
            </div>
        </div>

    </form>
</div>