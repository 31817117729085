import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { IAccount } from 'src/app/interfaces/iaccount';
import { Observable, of } from 'rxjs';
import { AgentService } from 'src/app/services/agent.service';
import { catchError, debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { DictionaryService } from 'src/app/services/dictionary.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TariffsService } from 'src/app/services/tariffs.service';
import { UpperCommissionsEditComponent } from './upper-commissions-edit/upper-commissions-edit/upper-commissions-edit.component';

@Component({
  selector: 'app-upper-commissions',
  templateUrl: './upper-commissions.component.html',
  styleUrls: ['./upper-commissions.component.css']
})
export class UpperCommissionsComponent implements OnInit {

  isCompleted: boolean = true;

  displayedColumns: string[] = ['serviceId', 'serviceName', 'minFix', 'maxFix', 'minPercent', 'maxPercent', 'isAvailableInPaynet', 'isEnable', 'action'];
  dataSource: any[] = [];

  public accounts: IAccount[] = [];
  agentId = new FormControl();
  public agentAutoComplete$: Observable<any> = null;

  constructor(public dialog: MatDialog,
    private agentSrv: AgentService,
    private dictionarySrv: DictionaryService,
    private translate: TranslateService,
    public snackBar: MatSnackBar,
    private tariffsSrv: TariffsService
  ) { }

  lookup(value: any): Observable<any> {  
    if (typeof value === 'object')
    {
      value = value.title;
    } 
    
    return this.agentSrv.search(value.toLowerCase()).pipe(
        // map the item property of the results as our return object
        map(results => results),
        // catch errors
        catchError(_ => {
          return of(null);
        })
      );
    }

  ngOnInit(): void {
    this.agentAutoComplete$ = this.agentId.valueChanges.pipe(
      startWith(''),
      // delay emits
      debounceTime(300),
      // use switch map so as to cancel previous subscribed events, before creating new once
      switchMap(value => {
        if (value !== '') {
          // lookup from agents
          return this.lookup(value);
        } else {
          // if no value is pressent, return null
          return of(null);
        }
      })
    );

    this.load();
  }

  load() {
    this.getAccounts();
    this.getUpperCommissionsByAgent();   
  }

  getAccounts() {
    this.accounts = [];
    this.dictionarySrv.agents().subscribe(data => {
        this.accounts = data;
      },
      error => {
        if (error.status === 404) {
          let message = this.translate.instant('no-data');
          this.openSnackBar(message, 'x');
        }
        else
          if (error.status === 400) {
            let message = this.translate.instant('bad-request');
            this.openSnackBar(message, 'x');
          }
          else
            if (error.status === 500) {
              let message = this.translate.instant('service-is-temporarily-unavailable');
              this.openSnackBar(message, 'x');
            }
            else {
              let message = this.translate.instant('an-error-occurred-while-processing');
              this.openSnackBar(message, 'x');
            }
      });
  }

  getUpperCommissionsByAgent(){
    this.isCompleted = false;
    this.dataSource = [];

    var curUserId = null;
    if (this.agentId.value != null && this.agentId.value != undefined) {
        curUserId = this.agentId.value.code;
    }

    this.tariffsSrv.upperCommissionsByAgent(curUserId)
      .subscribe(
        data => {
          this.dataSource = data;
        },
        error => {
          if (error.status === 404) {
            let message = this.translate.instant('no-data');
            this.openSnackBar(message, 'x');
          }
          else
            if (error.status === 400) {
              let message = this.translate.instant('an-error-occurred-while-processing');
              this.openSnackBar(message, 'x');
            }
            else
              if (error.status === 500) {
                let message = this.translate.instant('service-is-temporarily-unavailable');
                this.openSnackBar(message, 'x');
              }
              else {
                let message = this.translate.instant('an-error-occurred-while-processing');
                this.openSnackBar(message, 'x');
              }
        }
      ).add(() => { this.isCompleted = true });
  }

  displayFn(account: any): string {
    return account && account.title ? account.title : '';
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  
  clearSearch(){
    this.agentId.setValue("");
    this.getUpperCommissionsByAgent();
  }

   // View upper commission
   openAgentUpperCommissionEditDialog(item: any) 
   {
     const dialogRef = this.dialog.open(UpperCommissionsEditComponent,
       { data: { service: item, agentId: this.agentId.value } });
 
     dialogRef.afterClosed().subscribe(result => {
        this.getUpperCommissionsByAgent();
     });
   }
}
