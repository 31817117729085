<div fxLayout="column" fxLayoutAlign="center center">
    <h1 class="mat-headline">{{'services-settings'| translate}}</h1>
</div>

<div fxLayout="column" fxLayoutAlign="none">
    <div fxLayoutAlign="center center">
        <mat-form-field fxFlex="40">
            <input type="text" placeholder="{{'selectService'| translate}}" matInput [formControl]="selectedService"
                [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" autoActiveFirstOption (optionSelected)="getServices()">
                <mat-option *ngFor="let service of serviceAutoComplete$ | async; let index = index" [value]="service">
                    {{service.alias}}
                </mat-option>
            </mat-autocomplete>
            <button *ngIf="selectedService != null" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
                <mat-icon>close</mat-icon>
              </button>
        </mat-form-field>

        <mat-form-field fxFlex="20">
            <mat-label>{{'isAvailableInPaynet' | translate}}</mat-label>
            <mat-select class="region-selection-width" placeholder="{{'isAvailableInPaynet' | translate}}" [formControl]="isPaynetFixed" (selectionChange)="getServices()">
                <mat-option value="-1">{{'select-all' | translate}}</mat-option>
                <mat-option value="1">{{'yes' | translate}}</mat-option>
                <mat-option value="0">{{'no' | translate}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field fxFlex="20">
            <mat-label>{{'enabledUkassa' | translate}}</mat-label>
            <mat-select class="region-selection-width" placeholder="{{'enabledUkassa' | translate}}" [formControl]="isUkassaFixed" (selectionChange)="getServices()">
                <mat-option value="-1">{{'select-all' | translate}}</mat-option>
                <mat-option value="1">{{'yes' | translate}}</mat-option>
                <mat-option value="0">{{'no' | translate}}</mat-option>
            </mat-select>
        </mat-form-field>

        <button mat-icon-button color="primary" (click)="getServices()">
            <mat-icon>search</mat-icon>
        </button>

        <button mat-icon-button color="primary" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="mainMenuButtonExcel" [disabled]="dataSource?.length < 1">
            <mat-icon>save_alt</mat-icon>
        </button>

        <mat-menu #mainMenuButtonExcel="matMenu">
            <div class="table-column-list-block">
                <section class="button-download">
                    <button mat-raised-button color="primary"  (click)="exportToExcel()" >
                        {{'export' | translate}}
                        <mat-icon>save_alt</mat-icon>
                    </button>   
                </section>
                <span (click)="$event.stopPropagation()" *ngFor="let element of columnDefinitionsForExcel;index as i">
                    <mat-slide-toggle color="primary" (change)="element.isShow = !element.isShow" [checked]="element.isShow == true"></mat-slide-toggle>
                    {{element.label}}
                    <br>
                </span>
            </div>
        </mat-menu>
    </div>

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z0">

        <!-- alias Column -->
        <ng-container matColumnDef="alias">
            <th mat-header-cell *matHeaderCellDef> {{'alias' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.name }} </td>
        </ng-container>

        <!-- serviceId Column -->
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> {{'serviceId' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>

        <!-- isAvailableInPaynet Column -->
        <ng-container matColumnDef="isAvailableInPaynet">
            <th mat-header-cell *matHeaderCellDef> {{'isAvailableInPaynet' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.isAvailableInPaynet.toString() | translate}} </td>
        </ng-container>

        <!-- updatedAt Column -->
        <ng-container matColumnDef="updatedAt">
            <th mat-header-cell *matHeaderCellDef> {{'update-time' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.updatedAt | date:"dd/MM/yyyy HH:mm:ss"}} </td>
        </ng-container>

        <!-- enabled Column -->
        <ng-container matColumnDef="enabled">
            <th mat-header-cell *matHeaderCellDef> {{'enabledUkassa' | translate}} </th>
            <td mat-cell *matCellDef="let element">
                <section class="template-section">
                    <mat-slide-toggle color="primary" (change)="update($event,element)" [checked]="element.enabled">            
                    </mat-slide-toggle>
                </section>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>