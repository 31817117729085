<div fxLayout="column" fxLayoutAlign="center center">
    <h1 class="mat-headline" *ngIf="item.pages.code != ''">{{'edit-page' | translate}} {{item.pages?.title}}</h1>
    <h1 class="mat-headline" *ngIf="item.pages.code == ''">{{'add-page' | translate}}</h1>
</div>
<mat-progress-bar *ngIf="!isCompleted" mode="indeterminate"></mat-progress-bar>
<form [formGroup]="pagesForm" novalidate (ngSubmit)="update(pagesForm.value)">
    <mat-card class="mat-elevation-z0">
    <mat-card-content>
        <div>
        <div>
            <mat-form-field fxFill>
            <input matInput placeholder="{{'title' | translate}}" formControlName="title">
            <mat-error *ngIf="pagesForm.controls['title'].hasError('required')">
                {{'title-is-req'| translate}}
            </mat-error>
            </mat-form-field>
        </div>
        </div>

        <div *ngIf="item.pages.code == ''">
            <div>
                <mat-form-field fxFill>
                <input matInput placeholder="{{'page-code' | translate}}" formControlName="code">
                <mat-error *ngIf="pagesForm.controls['code'].hasError('required')">
                    {{'page-code-is-req'| translate}}
                </mat-error>
                </mat-form-field>
            </div>
        </div>

        <div>
        <div>
            <angular-editor id="editor1" placeholder="{{'content' | translate}}" formControlName="content"></angular-editor>
        </div>
        </div>
  
    </mat-card-content>
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>{{'cancel'| translate}}</button>
        <button mat-flat-button color="primary" type="submit" [disabled]="!pagesForm.valid" [mat-dialog-close]="true" cdkFocusInitial>
            <span *ngIf="item.pages.code != ''">{{'save'| translate}}</span>
            <span *ngIf="item.pages.code == ''">{{'add'| translate}}</span>
        </button>
    </mat-dialog-actions>
    </mat-card>
</form>