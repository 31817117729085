import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  constructor(private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    public userService: UserService
  ) { }
  showSpinner: boolean;
  returnUrl: string;
  error = '';
  username = new FormControl();
  password =  new FormControl();
  hide = true;
  ngOnInit() {
    this.authService.logout();
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  login(): void {
    this.authService.login(this.username.value, this.password.value)
      .subscribe(
        data => {
          this.getCurrentUser();
          event.preventDefault();
          window.location.href = this.returnUrl; 
          //this.router.navigate([this.returnUrl]);
        },
        error => {
          this.error = error;
        }
      );
  }

  // Получаем текущего пользователя
  getCurrentUser() {

    this.userService.getUser().subscribe(r => {
      if (r.code == 0)
      {}

    });
  };
}
