import { Component, OnInit } from '@angular/core';
import { IProfileReport } from 'src/app/interfaces/reports/iprofile-report';
import { FormControl } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { ExportService } from 'src/app/services/export.service';
import { AgentService } from 'src/app/services/agent.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-profiles',
  templateUrl: './profiles.component.html',
  styleUrls: ['./profiles.component.css']
})
export class ProfilesComponent implements OnInit {

  displayedColumns: string[] = ['date', 'profileName', 'parentProfileName'];
  dataSource: IProfileReport[] = [];

  public profiles: IProfileReport[] = [];
  columnDefinitionsForExcel = [];
  selectedAccount = new FormControl();
  dateFrom = new FormControl(new Date(new Date().setDate(new Date().getDate() - 30)));
  dateTo = new FormControl(new Date());
  isCompleted: boolean = false;
  
  constructor(
    private translate: TranslateService, 
    private agentSrv: AgentService,
    private exportSrv: ExportService,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.load();
  }
  load() {
    this.getReport();
  }
  getReport() {
    this.isCompleted = false;

    if ((this.dateFrom.value != null && this.dateFrom.value != undefined)
      && (this.dateTo.value != null && this.dateTo.value != undefined)) {
      this.dataSource = [];
      this.columnDefinitionsForExcel = [];
      this.agentSrv.newProfiles(this.dateFrom.value, this.dateTo.value).subscribe(data => {
        this.dataSource = data;
        if(this.dataSource[0]){
          this.columnDefinitionsForExcel = this.exportSrv.getAllColumnsExcel(Object.keys(this.dataSource[0]));
        }
        },
        error => {
          if (error.status === 404) {
            let message = this.translate.instant('no-data');
            this.openSnackBar(message, 'x');
          }
          else
            if (error.status === 400) {
              let message = this.translate.instant('bad-request');
              this.openSnackBar(message, 'x');
            }
            else
              if (error.status === 500) {
                let message = this.translate.instant('service-is-temporarily-unavailable');
                this.openSnackBar(message, 'x');
              }
              else {
                let message = this.translate.instant('an-error-occurred-while-processing');
                this.openSnackBar(message, 'x');
              }
        }
        ).add(() => { this.isCompleted = true });
    }
  }
  

  getTotal() {
    return this.dataSource.length;
  }

  exportToExcel() {
    this.exportSrv.exportToExcel(this.dataSource, 'Report', 'filename.xlsx', this.columnDefinitionsForExcel);
  }

  public dateFromChanged() {
    this.getReport();
  }

  public dateToChanged() {
    this.getReport();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }
}
