import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { INews } from '../interfaces/inews';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class NewsService {
  host: string;
  constructor(private http: HttpClient) {
    this.host = environment.api_url + '/api/news'
  }

  get(): Observable<INews[]> {
    let url = this.host;
    return this.http.get<INews[]>(url);
  }

  getNews(id: number): Observable<any> {
    let url = this.host + '/' + id;
    return this.http.get<any>(url);
  }

  createOrUpdate(id: number, data): Observable<any> {
    const url = this.host + '/' + id + '/create-or-update';
    return this.http.post(url, data);
  }

  deleteNews(id: number): Observable<any> {
    const url = this.host + '/' + id;
    return this.http.delete(url);
  }

}

