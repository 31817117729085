<mat-progress-bar mode="indeterminate" *ngIf="!isCompleted"></mat-progress-bar>
<div *ngIf="isCompleted">
  <mat-card fxLayout="row" class="mat-card-header">
    <mat-card  fxFlex="100%" fxLayoutAlign="center center" class="mat-service-name">{{serviceName}}</mat-card> 

    <mat-card>
      <button mat-icon-button color="primary" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="mainMenuButtonExcel" [disabled]="dataSource?.data?.length < 1">
          <mat-icon>save_alt</mat-icon>
      </button>
      <mat-menu #mainMenuButtonExcel="matMenu">
          <div class="table-column-list-block">
              <section class="button-download">
                  <button mat-raised-button color="primary"  (click)="exportToExcel()" >
                      {{'export' | translate}}
                      <mat-icon>save_alt</mat-icon>
                  </button>   
              </section>
              <span (click)="$event.stopPropagation()" *ngFor="let element of columnDefinitionsForExcel;index as i">
                  <mat-slide-toggle color="primary" (change)="element.isShow = !element.isShow" [checked]="element.isShow == true"></mat-slide-toggle>
                  {{element.label}}
                  <br>
              </span>
          </div>
      </mat-menu>
    </mat-card>
  </mat-card>
  
  <mat-card fxLayout="row" class="mat-card-header">
    <mat-card  fxFlex="40%" fxLayoutAlign="center center" class="mat-th">{{'tariffName' | translate}}</mat-card> 
    <mat-card  fxFlex="15%" fxLayoutAlign="center center" class="mat-th">{{'itoFix' | translate}}</mat-card> 
    <mat-card  fxFlex="15%" fxLayoutAlign="center center" class="mat-th">{{'itoPercent' | translate}}</mat-card>
    <mat-card  fxFlex="15%" fxLayoutAlign="center center" class="mat-th">{{'lowerFix' | translate}}</mat-card>
    <mat-card  fxFlex="15%" fxLayoutAlign="center center" class="mat-th">{{'lowerPercent' | translate}}</mat-card>
  </mat-card>
  
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="agents-mat-tree">
      <!-- This is the tree node template for leaf nodes -->
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
        <!-- use a disabled button to provide padding for tree leaf -->
        <mat-card fxLayout="row" fxFill>
          <mat-card  fxFlex="40%" [style.padding-left.px]="[ 30 * (node.level+1)]">
            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.fName">
              <mat-icon class="mat-icon-rtl-mirror">
              </mat-icon>
              <span class="mat-body mat-td">
                {{node.tariffName}} 
              </span>
            </button>
          </mat-card>
  
          <mat-card fxLayoutAlign="center center" fxFlex="15%" class="mat-td">{{node.itoFix / 100}}</mat-card>
          <mat-card fxLayoutAlign="center center" fxFlex="15%" class="mat-td">{{node.itoPercent / 100}}</mat-card>
          <mat-card fxLayoutAlign="center center" fxFlex="15%" class="mat-td">{{node.lowerFix / 100}}</mat-card>
          <mat-card fxLayoutAlign="center center" fxFlex="15%" class="mat-td">{{node.lowerPercent / 100}}</mat-card>
          
        </mat-card>
      </mat-tree-node>
    
      <!-- This is the tree node template for expandable nodes -->
      <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
        <mat-card fxLayout="row" fxFill>
          <mat-card  fxFlex="40%" [style.padding-left.px]="[ 30 * (node.level+1)]">
            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.fName">
              <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'remove' : 'add'}}
              </mat-icon>
              <span class="mat-body">
               <b>{{node.tariffName}}</b>
              </span>
            </button>
          </mat-card>
  
          <mat-card fxLayoutAlign="center center" fxFlex="15%" class="mat-td">{{node.itoFix / 100}}</mat-card>
          <mat-card fxLayoutAlign="center center" fxFlex="15%" class="mat-td">{{node.itoPercent / 100}}</mat-card>
          <mat-card fxLayoutAlign="center center" fxFlex="15%" class="mat-td">{{node.lowerFix / 100}}</mat-card>
          <mat-card fxLayoutAlign="center center" fxFlex="15%" class="mat-td">{{node.lowerPercent / 100}}</mat-card>
  
        </mat-card>
      </mat-tree-node>
    </mat-tree>
</div>
