import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { IAccount } from 'src/app/interfaces/iaccount';
import { IAgentsReport } from 'src/app/interfaces/reports/iagents-report';
import { AgentService } from 'src/app/services/agent.service';
import { AuthService } from 'src/app/services/auth.service';
import { DictionaryService } from 'src/app/services/dictionary.service';
import { ExportService } from 'src/app/services/export.service';
import { ReportService } from 'src/app/services/report.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-agents-report',
  templateUrl: './agents-report.component.html',
  styleUrls: ['./agents-report.component.css']
})
export class AgentsReportComponent implements OnInit {

  isCompleted: boolean = false;
  displayedColumns: string[] = ['agentId', 'agentName', 'successCount', 'acceptedAmount', 'userAmount', 'upperCommissionAmount', 'lowerCommissionAmount', 'itoCommissionAmount', 'canceledCount', 'canceledAmount'];
  dataSource: IAgentsReport[] = [];

  public reportData: IAgentsReport[] = [];
  public accounts: IAccount[] = [];
  
  agentId = new FormControl();
  public agentAutoComplete$: Observable<any> = null;
  columnDefinitionsForExcel = [];
  dateFrom = new FormControl(new Date(new Date().setDate(new Date().getDate() - 30)));
  dateTo = new FormControl(new Date());
  // глобальные параметры валюты приложения
  globalLocation = environment.globalLocation;
  globalCurrencyCode = environment.globalCurrencyCode;
  
  constructor(
    private reportSrv: ReportService,
    private exportSrv: ExportService,
    private translate: TranslateService,
    public snackBar: MatSnackBar,
    public agentsSrv: AgentService,
    private dictionarySrv: DictionaryService
  ) { }

  lookup(value: any): Observable<any> {  
    if (typeof value === 'object')
    {
      value = value.title;
    } 
    
    return this.agentsSrv.search(value.toLowerCase()).pipe(
        // map the item property of the results as our return object
        map(results => results),
        // catch errors
        catchError(_ => {
          return of(null);
        })
      );
    }

  ngOnInit(): void {
    this.load();

    this.agentAutoComplete$ = this.agentId.valueChanges.pipe(
      startWith(''),
      // delay emits
      debounceTime(300),
      // use switch map so as to cancel previous subscribed events, before creating new once
      switchMap(value => {
        if (value !== '') {
          // lookup from agents
          return this.lookup(value);
        } else {
          // if no value is pressent, return null
          return of(null);
        }
      })
    );
  }

  load() {
    this.getAccounts();
  }

  getReport(userId = 0) {
    var curUserId = null;
    if (userId != 0) {
        curUserId = userId;
    }
    else if (this.agentId.value != null && this.agentId.value != undefined) {
        curUserId = this.agentId.value.code;
    }

    this.isCompleted = false;
    this.dataSource = [];
    this.columnDefinitionsForExcel = [];
    this.reportSrv.agentsReport(curUserId, this.dateFrom.value, this.dateTo.value)
      .subscribe(
        data => {
          this.reportData = data;
          this.dataSource = this.reportData;
          if(this.dataSource[0]){
            this.columnDefinitionsForExcel = this.exportSrv.getAllColumnsExcel(Object.keys(this.dataSource[0]));
          }
        },
        error => {
          if (error.status === 404) {
            let message = this.translate.instant('no-data');
            this.openSnackBar(message, 'x');
          }
          else
            if (error.status === 400) {
              let message = this.translate.instant('an-error-occurred-while-processing');
              this.openSnackBar(message, 'x');
            }
            else
              if (error.status === 500) {
                let message = this.translate.instant('service-is-temporarily-unavailable');
                this.openSnackBar(message, 'x');
              }
              else {
                let message = this.translate.instant('an-error-occurred-while-processing');
                this.openSnackBar(message, 'x');
              }
        }
      ).add(() => { this.isCompleted = true });
  }

  getAccounts() {
    this.accounts = [];
    this.dictionarySrv.agents().subscribe(data => {
        this.accounts = data;
        if(data != null){
          this.agentId.setValue(this.accounts[0]);
          this.getReport(data[0]['code']);
        }
      },
      error => {
        if (error.status === 404) {
          let message = this.translate.instant('no-data');
          this.openSnackBar(message, 'x');
        }
        else
          if (error.status === 400) {
            let message = this.translate.instant('bad-request');
            this.openSnackBar(message, 'x');
          }
          else
            if (error.status === 500) {
              let message = this.translate.instant('service-is-temporarily-unavailable');
              this.openSnackBar(message, 'x');
            }
            else {
              let message = this.translate.instant('an-error-occurred-while-processing');
              this.openSnackBar(message, 'x');
            }
      }
      );
  }

  displayFn(account: any): string {
    return account && account.title ? account.title : '';
  }

  getTotalSuccesTransaction() {
    return this.dataSource.map(t => t.successCount).reduce((acc, value) => acc + value, 0);
  }
  getTotalCanceledTransaction() {
    return this.dataSource.map(t => t.canceledCount).reduce((acc, value) => acc + value, 0);
  }
  getTotalAccepted() {
    return this.dataSource.map(t => t.acceptedAmount).reduce((acc, value) => acc + value, 0);
  }
  getTotalUserAmount() {
    return this.dataSource.map(t => t.userAmount).reduce((acc, value) => acc + value, 0);
  }
  getTotalUpper() {
    return this.dataSource.map(t => t.upperCommissionAmount).reduce((acc, value) => acc + value, 0);
  }
  getTotalLower() {
    return this.dataSource.map(t => t.lowerCommissionAmount).reduce((acc, value) => acc + value, 0);
  }
  getTotalITO() {
    return this.dataSource.map(t => t.itoCommissionAmount).reduce((acc, value) => acc + value, 0);
  }
  getTotalCanceledAmount() {
    return this.dataSource.map(t => t.canceledAmount).reduce((acc, value) => acc + value, 0);
  }
  exportToExcel() {
    this.exportSrv.exportToExcel(this.dataSource, 'Report', 'filename.xlsx', this.columnDefinitionsForExcel);
  }

  public dateFromChanged() {
    this.getReport(0);
  }

  public dateToChanged() {
    this.getReport(0);
  }

  public agentChanged() {
    this.getReport(0);
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  clearSearch(){
    this.agentId.setValue("");
      this.getReport();   
  }
}
