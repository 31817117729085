<div fxLayout="column" fxLayoutAlign="center center">
    <h1 class="mat-headline">{{'categories-settings'| translate}}</h1>
</div>

<div fxLayout="column" fxLayoutAlign="none">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z0">
    <!-- ordering Column -->
    <ng-container matColumnDef="ordering">
        <th mat-header-cell *matHeaderCellDef> {{'ordering' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.ordering }} </td>
    </ng-container>
        <!-- alias Column -->
        <ng-container matColumnDef="alias">
            <th mat-header-cell *matHeaderCellDef> {{'alias' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.alias }} </td>
        </ng-container>

        <!-- name Column -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> {{'category-name' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <!-- action Column -->
        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">                 
                <button mat-icon-button [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                   <button mat-menu-item (click)="move(element,0)">
                        <mat-icon>arrow_circle_up</mat-icon>
                        <span>{{'up'| translate}}</span>
                    </button>
                    <button mat-menu-item (click)="move(element,1)">
                        <mat-icon>arrow_circle_down</mat-icon>
                        <span>{{'down'| translate}}</span>
                    </button>
                </mat-menu>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>