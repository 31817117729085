import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged, pairwise } from 'rxjs/operators';
import { MustMatch } from 'src/app/helpers/must-match';
import { AgentService } from 'src/app/services/agent.service';
import { TransactionsService } from 'src/app/services/transactions.service';

@Component({
  selector: 'app-refill-agent',
  templateUrl: './refill-agent.component.html',
  styleUrls: ['./refill-agent.component.css']
})
export class RefillAgentComponent implements OnInit {

  isCompleted: boolean = false;
  isRefillProcessing: boolean = false;
  refillCodeEmail: string = null;
  isCodeSending: boolean = false;
  prevAmount: number = 0;
  prevAmount2: number = 0;
  @ViewChild('refillSum', {static: false})refillSum: ElementRef;
  @ViewChild('refillSum2', {static: false})refillSum2: ElementRef;
  refillForm = this.fb.group({
    agentId: [this.item.agent.id, Validators.required],
    refillSum: [0, [Validators.required, Validators.min(1)]],
    refillSumRepeat: [0, [Validators.required, Validators.min(1)]],
    refillCode: [null, Validators.required],
    comment: ""
  },
  {
    validator: MustMatch('refillSum', 'refillSumRepeat')
  });

  constructor(@Inject(MAT_DIALOG_DATA) public item: any,
    private fb: FormBuilder,
    public snackBar: MatSnackBar,
    private translate: TranslateService,
    private agentSrv: AgentService,
    private transactionsSrv: TransactionsService,
    public dialog: MatDialog
  ) { 
      // функция нужна для получения пред. значения суммы к оплате
      this.refillForm.controls["refillSum"].valueChanges.pipe(
        distinctUntilChanged(),
        pairwise() // gets a pair of old and new value
      ).subscribe(([oldValue, newValue])=>{
        this.prevAmount = oldValue;
      })

      // функция нужна для получения пред. значения суммы к оплате
      this.refillForm.controls["refillSumRepeat"].valueChanges.pipe(
        distinctUntilChanged(),
        pairwise() // gets a pair of old and new value
      ).subscribe(([oldValue, newValue])=>{
        this.prevAmount2 = oldValue;
      })
  }

  ngOnInit(): void {
      this.isCompleted = true;
  }

  // convenience getter for easy access to form fields
  get f() { return this.refillForm.controls; }
  public hasError = (controlName: string, errorName: string) => {
    return this.refillForm.controls[controlName].hasError(errorName);
  }

  public update = (form) => {
    if (this.refillForm.valid) {
      this.execute(form);
    }
  }

  private execute = (data) => {
    this.isRefillProcessing = true;
    let json: any = {
      amount: Math.round((Number(Number(data.refillSum).toFixed(2)) * 100)),
      comment: data.comment,
      oneTimeCode: data.refillCode
    }

    this.agentSrv.refill(json, data.agentId)
      .subscribe(
        data => {
          const dialogRef = this.dialog.closeAll();
          let message = this.translate.instant('success-refill-agent');
          this.openSnackBar(message, 'x');
        },
        error => {
          this.isRefillProcessing = false;
          if (error.status === 404 || error.status === 400) {
            let message = error.error;
            this.openSnackBar(message, 'x');
          }
          else if (error.status === 409) {
            let message = this.translate.instant('an-error-occurred-while-processing');
            this.openSnackBar(message, 'x');
          }
          else{
              if (error.status === 500) {
                let message = this.translate.instant('service-is-temporarily-unavailable');
                this.openSnackBar(message, 'x');
              }
              else {
                let message = this.translate.instant('an-error-occurred-while-processing');
                this.openSnackBar(message, 'x');
              }
          }
        }
      );
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  getRefillCode(){
      this.isCodeSending = true;
      this.transactionsSrv.getRefillCode().subscribe(
        data => {
            this.refillCodeEmail = data.email;
            this.isCodeSending = false;
            let message = this.translate.instant('refill-code-success-sended');
            this.openSnackBar(message, 'x');
        },
         error => {
          if (error.status === 404 || error.status === 400) {
            let message = error.error;
            this.openSnackBar(message, 'x');
          }
          else if (error.status === 409) {
            let message = this.translate.instant('an-error-occurred-while-processing');
            this.openSnackBar(message, 'x');
          }
          else{
              if (error.status === 500) {
                let message = this.translate.instant('service-is-temporarily-unavailable');
                this.openSnackBar(message, 'x');
              }
              else {
                let message = this.translate.instant('an-error-occurred-while-processing');
                this.openSnackBar(message, 'x');
              }
          }

          this.isCodeSending = false;
        }
      )
  }

   // автоматом убирает 0 при наборе суммы к оплате
   onKeypressAmountEvent(event: any, refillFieldName: string){
    if(Number(this.prevAmount2) == 0 && refillFieldName == 'refillSumRepeat'){
        this.refillForm.controls[refillFieldName].patchValue(Number(event.key));
        this.refillSum2.nativeElement.setSelectionRange(1, 1, 'none');
    }
    else if(Number(this.prevAmount) == 0 && refillFieldName == 'refillSum'){
        this.refillForm.controls[refillFieldName].patchValue(Number(event.key));
        this.refillSum.nativeElement.setSelectionRange(1, 1, 'none');
    }
  }

}
