<div fxLayout="column" fxLayoutAlign="center center">
    <h1 class="mat-headline">{{'adding-agent' | translate}}</h1>
</div>
<mat-progress-bar *ngIf="!isCompleted" mode="indeterminate"></mat-progress-bar>
<form [formGroup]="addAgentForm" novalidate (ngSubmit)="create(addAgentForm.value)">
    <mat-card class="mat-elevation-z0">
    <mat-card-content>
        <div>
        <div>
            <mat-form-field fxFill>
            <input type="email" matInput placeholder="{{'email' | translate}}" formControlName="email">
            <mat-error *ngIf="f.email.errors">
                <div *ngIf="f.email.errors.required">{{'email-is-required' | translate}}</div>
                <div *ngIf="f.email.errors.email">{{'email-must-be-valid' | translate}}</div>
            </mat-error>
            </mat-form-field>
        </div>
        </div>

        <div>
        <div>
            <mat-form-field fxFill>
            <input matInput placeholder="{{'lname' | translate}}" formControlName="lname">
            <mat-error *ngIf="addAgentForm.controls['lname'].hasError('required')">
                {{'lname-is-req'| translate}}
            </mat-error>
            </mat-form-field>
        </div>
        </div>

        <div>
        <div>
            <mat-form-field fxFill>
            <input matInput placeholder="{{'name' | translate}}" formControlName="name">
            <mat-error *ngIf="addAgentForm.controls['name'].hasError('required')">
                {{'name-is-req'| translate}}
            </mat-error>
            </mat-form-field>
        </div>
        </div>

        <div>
        <div>
            <mat-form-field fxFill>
            <input matInput placeholder="{{'pname' | translate}}" formControlName="pname">
            </mat-form-field>
        </div>
        </div>

        <div>
            <div>
                <mat-form-field fxFill>
                <mat-select placeholder="{{'agent-city' | translate}}" formControlName="cityId">
                    <mat-option *ngFor="let item of cities" [value]="item.code">
                    {{ item.title }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="addAgentForm.controls['cityId'].hasError('required')">
                    {{'city-is-req' | translate}}
                </mat-error>
                </mat-form-field>
            </div>

        </div>

        <div>
            <div>
                <mat-form-field fxFill>
                <input matInput placeholder="{{'phoneNumber'| translate}}" formControlName="phone" maxlength="{{phoneLengthMax}}">
                <mat-icon matSuffix>{{phonePrefix}}</mat-icon>
                <mat-error *ngIf="f.phone.errors">
                    <div *ngIf="f.phone.errors.required"> {{'phone-is-req'| translate}}</div>
                    <div *ngIf="f.phone.errors.pattern">{{'phone-number-pattern' | translate}}</div>
                </mat-error>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field fxFill>
                <mat-select placeholder="{{'region' | translate}}" formControlName="region">
                    <mat-option *ngFor="let item of regions" [value]="item.code">
                    {{ item.title }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="addAgentForm.controls['region'].hasError('required')">
                    {{'region-is-req' | translate}}
                </mat-error>
                </mat-form-field>
            </div>

            <div>
                <mat-form-field fxFill>
                    <input matInput placeholder="{{'parentAgent'| translate}}" formControlName="parentAgentName" readonly>
                </mat-form-field>
            </div>
        </div>
        <div>
            <div>
                <mat-form-field fxFill>
                <input matInput #iin maxlength="{{iinLengthCheck}}" placeholder="{{'iin' | translate}}" type="text" formControlName="iin">
                <mat-hint align="end">{{iin.value.length}} / {{iinLengthCheck}}</mat-hint>
                <mat-error *ngIf="f.iin.errors">
                    <div *ngIf="f.iin.errors.required">{{'iin-is-req' | translate}}</div>
                    <div *ngIf="f.iin.errors.pattern">{{'iin-must-be' | translate}}</div>
                </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div>
            <div>
                <mat-form-field fxFill>
                <input matInput placeholder="{{'address'| translate}}" formControlName="address">
                <mat-error *ngIf="addAgentForm.controls['address'].hasError('required')">
                    {{'address-is-req'| translate}}
                </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div>
            <mat-form-field fxFill>
            <mat-select placeholder="{{'template' | translate}}" formControlName="template">
                <mat-option *ngFor="let item of templates" [value]="item.id">
                {{ item.name }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="addAgentForm.controls['template'].hasError('required')">
                {{'template-is-req' | translate}}
            </mat-error>
            </mat-form-field>
        </div>

        <div>
            <div>
                <mat-form-field fxFill>
                    <input matInput placeholder="{{'company'| translate}}" formControlName="company">
                </mat-form-field>
            </div>
        </div>

        <div>
            <div>
                <mat-form-field fxFill>
                    <input matInput placeholder="{{'kkm-id'| translate}}" formControlName="kkmId">
                    <mat-hint align="end">{{'agent-fiscalization-hint' | translate}}</mat-hint>
                    <mat-error *ngIf="addAgentForm.controls['kkmId'].errors">
                        <div *ngIf="addAgentForm.controls['kkmId'].errors?.pattern">{{'kkm-id-invalid-pattern'| translate}}</div>
                        <div *ngIf="addAgentForm.controls['kkmId'].errors?.firstRequired">{{'kkm-id-required'| translate}}</div>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <div>
            <div>
                <mat-form-field fxFill>
                    <input matInput placeholder="{{'kkm-serial-number'| translate}}" formControlName="kkmSerialNumber">
                    <mat-hint align="end">{{'agent-fiscalization-hint' | translate}}</mat-hint>
                    <mat-error *ngIf="addAgentForm.controls['kkmSerialNumber'].errors">
                        <div *ngIf="addAgentForm.controls['kkmSerialNumber'].errors?.secondRequired">{{'kkm-serial-number-required'| translate}}</div>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <div>
            <div>
                <mat-form-field fxFill>
                    <mat-select placeholder="{{'kkm-owner-id' | translate}}" formControlName="ownerId">
                        <mat-option value="">{{'choose-owner-id' | translate}}</mat-option>
                        <mat-option *ngFor="let item of fiscalizationOrganizations" [value]="item.code">
                        {{ item.title }}
                        </mat-option>
                    </mat-select>
                    <mat-hint align="end">{{'kkm-owner-hint' | translate}}</mat-hint>
                    <mat-error *ngIf="addAgentForm.controls['ownerId'].errors">
                        <div *ngIf="addAgentForm.controls['ownerId'].errors?.pattern">{{'owner-id-invalid-pattern'| translate}}</div>
                        <div *ngIf="addAgentForm.controls['ownerId'].errors?.secondRequired">{{'owner-id-required'| translate}}</div>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <div>
            <div>
                <mat-form-field fxFill>
                    <input matInput placeholder="{{'terminalId'| translate}}" formControlName="terminalId">
                    <mat-hint align="end">{{'terminal-id-hint' | translate}}</mat-hint>
                </mat-form-field>
            </div>
        </div>

    </mat-card-content>
    <mat-card-actions fxLayoutGap="10px" fxLayoutAlign="end">
        <button mat-flat-button type="button" (click)="closeAddAgentDialog()">{{'close' | translate}}</button>
        <button mat-flat-button color="primary" type="submit" [disabled]="!addAgentForm.valid || !isCompleted">{{'adding-agent' | translate}}</button>
    </mat-card-actions>
    </mat-card>
</form>
