import { Component, Input, Output, NgModule, OnInit, SimpleChanges, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dynamic-button',
  templateUrl: './dynamic-button.component.html',
  styleUrls: ['./dynamic-button.component.css']
})


export class DynamicButtonComponent implements OnInit {

  constructor(private translate: TranslateService) { }

  firstName: string = this.translate.instant('include-subs');
  secondName: string = this.translate.instant('exclude-subs');
  shouldUpdate:boolean = false;


  @Input() isName: boolean   
  @Input() key: number
  @Output() childClickEvent = new EventEmitter<boolean>(); 

  ngOnInit(): void {
  }
  
  ngOnChanges(changes: SimpleChanges)
  {
    for (let propName in changes)
    {
      this.isName = changes[propName].currentValue
      if (this.isName !== undefined)
      {
      this.shouldUpdate = this.isName
      } 
    }
  } 

  setIncludeSubStatus(): void {
    this.shouldUpdate = !this.shouldUpdate; 
    this.childClickEvent.emit(this.shouldUpdate);
  }
  


}
