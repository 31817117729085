import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authservice: AuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const idToken = localStorage.getItem("id_token");
        if (idToken){
            request = request.clone({
                setHeaders:{
                    Authorization: `Bearer ${idToken}`,
                    'Access-Control-Allow-Origin': '*'
                   /*  'Access-Control-Allow-Origin': '*',
                    'Content-Type':'application/json' */
                }
            });

        }
        return next.handle(request);
    }
}
