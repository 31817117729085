import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IMenu } from './interfaces/imenu';
import { AuthService } from './services/auth.service';
import { UserService } from './services/user.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'cashbox-ui-backoffice';
  currentUser: any;
  version: string;
  visibilityUpButton: boolean = false;
  @ViewChild('scrollTop', { static: false }) scrollRef: ElementRef;
  isAuthenticated: boolean;
 
  reports: IMenu[] = [
    {
      title: 'account-balance-report',
      url: "/reports/account-balance",
    },
    {
      title: 'balanceSummary',
      url: "/reports/consolidated-report",
    },
    {
      title: 'consolidated-report',
      url: "/reports/consolidated-report-extended",
    }
  ];
    constructor(public authService: AuthService, 
      private userSrv: UserService,
      private translate: TranslateService,
      public snackBar: MatSnackBar
     ) {
      this.authService.isAuthenticated.subscribe(
        (isAuthenticated: boolean)  => this.isAuthenticated = isAuthenticated
      );
    }
  
    async ngOnInit() {
      this.isAuthenticated = await this.authService.checkAuthenticated();

      if(this.isAuthenticated){
        this.getAuthUser();
      }

      this.load();
      window.addEventListener('scroll', this.scrollToUpListener, true);
    }

    getAuthUser(){
       this.authService.getAuthUser().subscribe(
        (data: any) => {
          this.currentUser = data;
        },
        error => {
          let message = this.translate.instant('service-is-temporarily-unavailable');
          this.openSnackBar(message, 'x');
        }
      )
    }

    load()
    {
     this.version = environment.version;
    };

    logout(): void {
      this.authService.logout();   
    }

    openSnackBar(message: string, action: string) {
      this.snackBar.open(message, action, {
        duration: 5000,
      });
    }

    scrollToUpListener = (event: any): void => {
      const scrollNumber = event.srcElement.scrollTop;
      if (scrollNumber >= 100) {
        this.visibilityUpButton = true;
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
          this.visibilityUpButton = false;
        }
      }
      else {
        this.visibilityUpButton = false;
      }
    };

    scrollToUp() {
      this.scrollRef.nativeElement.scrollIntoView({
        behavior: "smooth"
    });
      this.visibilityUpButton = false;
    }
}
