import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { IPages } from '../interfaces/ipages';

@Injectable({
  providedIn: 'root'
})
export class PagesService {
  host: string;

  constructor(private http: HttpClient) {
    this.host = environment.api_url + '/api/pages'
  }

  get(): Observable<IPages[]> {
    let url = this.host;
    return this.http.get<IPages[]>(url);
  }

  getPage(code: string): Observable<any> {
    let url = this.host + '/' + code;
    return this.http.get<any>(url);
  }

  update(data: IPages): Observable<any> {
    let url = this.host;
    return this.http.post(url, data);
  };

  deletePage(code: string): Observable<any> {
    let url = this.host + "/" + code;
    return this.http.delete(url);
  }

}

