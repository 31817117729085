import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { AgentService } from 'src/app/services/agent.service';
import { DictionaryService } from 'src/app/services/dictionary.service';
import { ExportService } from 'src/app/services/export.service';
import { ReportService } from 'src/app/services/report.service';
import { environment } from 'src/environments/environment';
import { TransactionDetailsComponent } from '../transactions/transaction-details/transaction-details.component';


@Component({
  selector: 'app-whatsapp-report',
  templateUrl: './whatsapp-report.component.html',
  styleUrls: ['./whatsapp-report.component.css']
})


export class WhatsappReportComponent implements OnInit {
  
  displayedColumns: string[] = ['creator', 'sendDate', 'cityName', 'phoneNumber', 'status', 'statusMessage','transactionId'];
  dataSource = [];
  columnDefinitionsForExcel = [];

  dateFrom = new FormControl(new Date(new Date().setDate(new Date().getDate() - 30)));
  dateTo = new FormControl(new Date());
  agentId = new FormControl();
  public agentAutoComplete$: Observable<any> = null;

  cityId = new FormControl();
  cities = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  datasourceTableForPagination: MatTableDataSource<any>;
  isCompleted: boolean = false;
  // глобальные параметры валюты приложения
  globalLocation = environment.globalLocation;
  globalCurrencyCode = environment.globalCurrencyCode;
  
  constructor(
    private exportSrv: ExportService,
    private dictionarySrv: DictionaryService,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private reportSrv: ReportService,
    private agentSrv: AgentService,
    public dialog: MatDialog,
  ) { }

  lookup(value: any): Observable<any> {  
    if (typeof value === 'object')
    {
      value = value.title;
    } 
    
    return this.agentSrv.search(value.toLowerCase()).pipe(
        // map the item property of the results as our return object
        map(results => results),
        // catch errors
        catchError(_ => {
          return of(null);
        })
      );
    }

  ngOnInit(): void {
      this.load();

      this.agentAutoComplete$ = this.agentId.valueChanges.pipe(
        startWith(''),
        // delay emits
        debounceTime(300),
        // use switch map so as to cancel previous subscribed events, before creating new once
        switchMap(value => {
          if (value !== '') {
            // lookup from agents
            return this.lookup(value);
          } else {
            // if no value is pressent, return null
            return of(null);
          }
        })
      );
  }

  load() {
    this.getCities();
    this.getReport();
  }
  
  getReport() {
    this.isCompleted = false;

    var curUserId = null;
    
    if (this.agentId.value != null && this.agentId.value != undefined) {
        curUserId = this.agentId.value.code;
    }

    var curCityId = null;
    if (this.cityId.value != null && this.cityId.value != undefined) {
        curCityId = this.cityId.value;
    }

    this.dataSource = [];
    this.columnDefinitionsForExcel = [];
    this.reportSrv.whatsAppReport(this.dateFrom.value, this.dateTo.value, curUserId, curCityId).subscribe(data => {
          this.dataSource = data?.sort((a, b) => new Date(b.sendDate).getTime() - new Date(a.sendDate).getTime());
          this.datasourceTableForPagination = new MatTableDataSource<any>(data)
          this.datasourceTableForPagination.paginator = this.paginator;
          if(this.dataSource[0]){
            this.columnDefinitionsForExcel = this.exportSrv.getAllColumnsExcel(Object.keys(this.dataSource[0]));
          }
      },
      error => {
        if (error.status === 404) {
          let message = this.translate.instant('no-data');
          this.openSnackBar(message, 'x');
        }
        else
          if (error.status === 400) {
            let message = this.translate.instant('bad-request');
            this.openSnackBar(message, 'x');
          }
          else
            if (error.status === 500) {
              let message = this.translate.instant('service-is-temporarily-unavailable');
              this.openSnackBar(message, 'x');
            }
            else {
              let message = this.translate.instant('an-error-occurred-while-processing');
              this.openSnackBar(message, 'x');
            }
      }
    ).add(() => { this.isCompleted = true });

  }

  getCities() {
    this.dictionarySrv.getCities().subscribe(
      (data: any) => { this.cities = data; },
      error => console.log(error)
    )
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  exportToExcel() {
    this.exportSrv.exportToExcel(this.dataSource, 'Report', 'filename.xlsx', this.columnDefinitionsForExcel);  
  }

  public dateToChanged()
  {
    this.getReport();
  }
  
  public dateFromChanged()
  {
    this.getReport();
  }

  public accountChanged()
  {
    this.getReport();
  }

  displayFn(account: any): string {
    return account && account.title ? account.title : '';
  }

  clearSearch(){
    this.agentId.setValue("");
      this.getReport();   
  }

  openTransactionDetailDialog(item: any){
    item.Id = item.transactionId;
    const dialogRef = this.dialog.open(TransactionDetailsComponent,
      {
        data: { transaction: item },
        autoFocus: false,
        maxHeight: '95vh'
      });
  }

}
