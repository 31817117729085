<div fxLayout="column" fxLayoutAlign="center center">
    <h1 class="mat-headline">{{'pages-settings'| translate}}</h1>
</div>
<mat-progress-bar mode="indeterminate" *ngIf="!isCompleted"></mat-progress-bar>
<div fxLayout="column" fxLayoutAlign="none" *ngIf="isCompleted">
    <div fxLayoutAlign="end">
        <a color="primary" mat-button (click)="openPageAddDialog()">
            <mat-icon>add</mat-icon>
            {{'add-page'| translate}}
        </a>
    </div>

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z0">

        <!-- code Column -->
        <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef> {{'code' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.code }} </td>
        </ng-container>
        
        <!-- title Column -->
        <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef> {{'title' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.title}} </td>
        </ng-container>
        
        <!-- action Column -->
        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> {{'settings' | translate}} </th>
            <td mat-cell *matCellDef="let element">
                <section class="template-section">
                    <button mat-icon-button color="primary" [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="openPageEditDialog(element)">
                          <mat-icon>edit</mat-icon>
                          <span>{{'edit' | translate}}</span>
                        </button>  
                        
                        <button mat-menu-item (click)="deletePage(element)">
                            <mat-icon>delete_forever</mat-icon>
                            <span>{{'delete' | translate}}</span>
                          </button> 
                    </mat-menu>
                </section>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>