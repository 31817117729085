import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-tariff-confirm',
  templateUrl: './edit-tariff-confirm.component.html',
  styleUrls: ['./edit-tariff-confirm.component.css']
})
export class EditTariffConfirmComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
            private dialogRef: MatDialogRef<EditTariffConfirmComponent>) { }

  ngOnInit(): void {
  }

  confirmRewrite(){
    this.dialogRef.close(true);
  }

}
