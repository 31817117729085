<h2 class="mat-headline">{{'redefine-upper-commission'| translate}}</h2>
<form [formGroup]="upperForm" novalidate (ngSubmit)="update(upperForm.value)">
  <mat-dialog-content class="mat-typography">
    <div>
        <h3 *ngIf="item">
            {{'serviceName' | translate}} : {{item.service.serviceName}}
            <br>
            <mat-hint class="color-red" *ngIf="item.agentId == null" class="mat-small color-red">
                {{'default-upper-commissions-edit-hint' | translate}}
            </mat-hint>
        </h3>

        <div fxLayoutGap="20px" style="margin-bottom: 30px;">
            <label>{{'is-readonly-upper-commissions' | translate}}:</label>
            <mat-slide-toggle color="primary" [checked]="upperForm.get('isReadonly')['controls']" formControlName="isReadonly"></mat-slide-toggle>
        </div>

    </div>

    <div fxLayout="row">
        <div fxFlex="50%">
            <p>{{'limits-by-fix-sum'|translate}}</p>
            <div fxLayoutGap="20px">
                <mat-form-field fxFill fxFlex="40%">
                    <input matInput placeholder="{{'min-fix' | translate}}" formControlName="minFix" (keyup)="updateFormValidation('fix')">
                    <mat-error *ngIf="upperForm.controls['minFix'].hasError('required')">
                        {{'min-fix-req'| translate}}
                    </mat-error>
                    <mat-error *ngIf="upperForm.controls['minFix'].hasError('min')">
                        {{'min-fix-minimum'| translate}}: {{upperForm.controls['minFix'].errors.min.min}}
                    </mat-error>
                    <mat-error *ngIf="upperForm.controls['minFix'].hasError('max')">
                        {{'min-fix-maximum'| translate}}: {{upperForm.controls['minFix'].errors.max.max}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field fxFill fxFlex="40%">
                    <input matInput placeholder="{{'max-fix' | translate}}" formControlName="maxFix" (keyup)="updateFormValidation('fix')">
                    <mat-error *ngIf="upperForm.controls['maxFix'].hasError('required')">
                        {{'max-fix-is-req'| translate}}
                    </mat-error>

                    <mat-error *ngIf="upperForm.controls['maxFix'].hasError('min')">
                        {{'max-fix-minimum'| translate}}: {{upperForm.controls['maxFix'].errors.min.min}}
                    </mat-error>
                    <mat-error *ngIf="upperForm.controls['maxFix'].hasError('max')">
                        {{'max-fix-maximum'| translate}}: {{upperForm.controls['maxFix'].errors.max.max}}
                    </mat-error>
                    
                </mat-form-field>
            </div>
            
            <p>{{'redefine-upper-commission-fix-sum-desc'|translate}}</p>
            
            <button color="primary" mat-button (click)="addItemFix()" class="add-rule-btn">
                <mat-icon>add box</mat-icon>{{'add-fix-rule'| translate}}
            </button>

            <button color="primary" type="button" mat-button (click)="clearAllFix()">
                <mat-icon>remove_circle</mat-icon>
                Очистить всё
            </button>

            <div formArrayName="fix" *ngIf="isCompleted">
            <!-- loop throught units -->
                <div *ngFor="let item of upperForm.get('fix')['controls']; let i=index" >

                    <!-- row divider show for every nex row exclude if first row -->
                    <mat-divider *ngIf="upperForm.get('fix')['controls'].length > 1 && i > 0"></mat-divider><br> 

                    <!-- group name in this case row index -->
                    <div [formGroupName]="i" fxFill>
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="20px" fxLayoutAlign="left" >

                            <!-- from sum -->
                            <mat-form-field fxFlex="20%" fxFlex.xs="20">
                            <input currencyMask matInput placeholder="{{'from'| translate}}" formControlName="from" required>
                            </mat-form-field>

                            <!-- to sum -->
                            <mat-form-field fxFlex="20%" fxFlex.xs="grow">
                            <input currencyMask matInput placeholder="{{'to'| translate}}" formControlName="to" required>
                            </mat-form-field>

                            <!-- unit fix price input field, calculated and not editable -->
                            <div fxLayout.xs="row">
                            <mat-form-field>
                                <input currencyMask matInput placeholder="{{'sum'| translate}}" formControlName="sum">

                                <mat-hint>
                                    {{'min'| translate}}: {{upperForm.controls['minFix'].value}}, {{'max'| translate}}: {{upperForm.controls['maxFix'].value}}
                                </mat-hint>

                                <mat-error *ngIf="sumHasErrorRequired('fix', i)">
                                    {{'sum-is-req'| translate}}
                                </mat-error>

                                <mat-error *ngIf="sumHasErrorMin('fix', i)">
                                    {{'min-fix'| translate}}: {{upperForm.controls['minFix'].value}}
                                </mat-error>

                                <mat-error *ngIf="sumHasErrorMax('fix', i)">
                                    {{'max-fix'| translate}}: {{upperForm.controls['maxFix'].value}}
                                </mat-error>
                            </mat-form-field>

                            <button mat-icon-button color="primary" (click)="removeItemFix(i)">
                                <mat-icon>delete forever</mat-icon>
                            </button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div fxFlex="50%">
            <p>{{'limits-by-percent'|translate}}</p>
            <div fxLayoutGap="20px">
                <mat-form-field fxFill fxFlex="40%">
                    <input matInput placeholder="{{'min-percent' | translate}}" formControlName="minPercent" (keyup)="updateFormValidation('percent')">
                    <mat-error *ngIf="upperForm.controls['minPercent'].hasError('required')">
                        {{'min-percent-req'| translate}}
                    </mat-error>
                    <mat-error *ngIf="upperForm.controls['minPercent'].hasError('min')">
                        {{'min-percent-minimum'| translate}}: {{upperForm.controls['minPercent'].errors.min.min}}
                    </mat-error>
                    <mat-error *ngIf="upperForm.controls['minPercent'].hasError('max')">
                        {{'min-percent-maximum'| translate}}: {{upperForm.controls['minPercent'].errors.max.max}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field fxFill fxFlex="40%">
                    <input matInput placeholder="{{'max-percent' | translate}}" formControlName="maxPercent" (keyup)="updateFormValidation('percent')">
                    <mat-error *ngIf="upperForm.controls['maxPercent'].hasError('required')">
                        {{'max-percent-is-req'| translate}}
                    </mat-error>
                    <mat-error *ngIf="upperForm.controls['maxPercent'].hasError('min')">
                        {{'max-percent-minimum'| translate}}: {{upperForm.controls['maxPercent'].errors.min.min}}
                    </mat-error>
                    <mat-error *ngIf="upperForm.controls['maxPercent'].hasError('max')">
                        {{'max-percent-maximum'| translate}}: {{upperForm.controls['maxPercent'].errors.max.max}}
                    </mat-error>
                </mat-form-field>
            </div>
            
            <p>{{'redefine-upper-commission-percent-desc'|translate}}</p>

            <button color="primary" mat-button (click)="addItemPercent()" class="add-rule-btn">
            <mat-icon>add box</mat-icon>{{'add-percent-rule'| translate}}
            </button>

            <button color="primary" type="button" mat-button (click)="clearAllPercent()">
            <mat-icon>remove_circle</mat-icon>
            Очистить всё
            </button>

            <div formArrayName="percent" *ngIf="isCompleted">
                <!-- loop throught units -->
                <div *ngFor="let item of upperForm.get('percent')['controls']; let i=index" >

                    <!-- row divider show for every nex row exclude if first row -->
                    <mat-divider *ngIf="upperForm.get('percent')['controls'].length > 1 && i > 0"></mat-divider><br> 

                    <!-- group name in this case row index -->
                    <div [formGroupName]="i" fxFill>
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="20px" fxLayoutAlign="left" >

                            <!-- from sum -->
                            <mat-form-field fxFlex="20%" fxFlex.xs="20">
                            <input currencyMask matInput placeholder="{{'from'| translate}}" formControlName="from" required>
                            </mat-form-field>

                            <!-- to sum -->
                            <mat-form-field fxFlex="20%" fxFlex.xs="grow">
                            <input currencyMask matInput placeholder="{{'to'| translate}}" formControlName="to" required>
                            </mat-form-field>

                            <!-- unit percent price input field, calculated and not editable -->
                            <div fxLayout.xs="row">
                            <mat-form-field>
                                <input type="number" matInput placeholder="{{'percent'| translate}}" formControlName="sum">

                                <mat-hint style="display: flex;">
                                    {{'min'| translate}}: {{upperForm.controls['minPercent'].value}}, {{'max'| translate}}: {{upperForm.controls['maxPercent'].value}}
                                </mat-hint>

                                <mat-error *ngIf="sumHasErrorRequired('percent', i)">
                                    {{'percent-is-req'| translate}}
                                </mat-error>

                                <mat-error *ngIf="sumHasErrorMin('percent', i)">
                                    {{'min-percent'| translate}}: {{upperForm.controls['minPercent'].value}}
                                </mat-error>

                                <mat-error *ngIf="sumHasErrorMax('percent', i)">
                                    {{'max-percent'| translate}}: {{upperForm.controls['maxPercent'].value}}
                                </mat-error>

                            </mat-form-field>

                            <button mat-icon-button color="primary" (click)="removeItemPercent(i)">
                                <mat-icon>delete forever</mat-icon>
                            </button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>{{'cancel'| translate}}</button>
    <button mat-flat-button color="primary" type="submit" [disabled]="!upperForm.valid"
      cdkFocusInitial>{{'save'| translate}}</button>
  </mat-dialog-actions>
</form>