import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { BothRequired } from 'src/app/helpers/both-required';
import { IAgent } from 'src/app/interfaces/iagent';
import { AgentService } from 'src/app/services/agent.service';
import { DictionaryService } from 'src/app/services/dictionary.service';
import {appConfig} from "../../../../../assets/config/application-config";


@Component({
  selector: 'app-edit-agent',
  templateUrl: './edit-agent.component.html',
  styleUrls: ['./edit-agent.component.css']
})
export class EditAgentComponent implements OnInit {

  regions = [];
  cities = [];
  fiscalizationOrganizations = [];
  isCompleted: boolean = false;

  agentForm = this.fb.group({
    agentId: [this.item.agent.id, [Validators.required, Validators.pattern(this.item.agent.id)]],
    companyBin: [this.item.agent.companyBin, [Validators.required, Validators.pattern(this.item.agent.companyBin)]],
    phone: [this.item.agent.phone, [Validators.required, Validators.pattern(appConfig["add_agent_phone_pattern"])]],
    email: [this.item.agent.email, [Validators.required, Validators.email]],
    lname: [this.item.agent.lName, Validators.required],
    fname: [this.item.agent.fName, Validators.required],
    company: [this.item.agent.company, Validators.required],
    pname: [this.item.agent.pName],
    address: [this.item.agent.address, Validators.required],
    region: [this.item.agent?.regionId?.toString(), Validators.required],
    cityId: [this.item.agent?.cityId?.toString(), Validators.required],
    kkmId: [this.item.agent?.kkmId, [Validators.pattern('^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$')]],
    kkmSerialNumber: [this.item.agent?.kkmSerialNumber],
    ownerId: [this.item.agent?.ownerId],
    terminalId: [this.item.agent?.terminalId]
  },
  {
    validators: [BothRequired('kkmId', 'kkmSerialNumber'), BothRequired('kkmId', 'ownerId')]
  });

  constructor(@Inject(MAT_DIALOG_DATA) public item: any,
  private fb: FormBuilder,
  private agentSrv: AgentService,
  public snackBar: MatSnackBar,
  private translate: TranslateService,
  private dictionarySrv: DictionaryService,
  public dialog: MatDialog) { }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.getRegions();
    this.getCities();
    this.getFiscalizationOrganizations();
  }

  getRegions() {
    this.dictionarySrv.getRegions().subscribe(
      (data: any) => { this.regions = data; },
      error => console.log(error)
    )
  }

  getCities() {
    this.dictionarySrv.getCities().subscribe(
      (data: any) => {
        this.cities = data;
      },
      error => console.log(error)
    )
  }

  getFiscalizationOrganizations() {
    this.dictionarySrv.getFiscalizationOrganizations().subscribe(
      (data: any) => {
        this.fiscalizationOrganizations = data;
      },
      error => console.log(error)
    ).add(() => { this.isCompleted = true })
  }

  // convenience getter for easy access to form fields
  get f() { return this.agentForm.controls; }
  public hasError = (controlName: string, errorName: string) => {
    return this.agentForm.controls[controlName].hasError(errorName);
  }

  public update = (cityForm) => {
    if (this.agentForm.valid) {
      this.execute(cityForm);
    }
  }

  private execute = (data) => {
    let json: IAgent = {
      agentId: data.agentId,
      lname: data.lname,
      fname: data.fname,
      pname: data.pname,
      company: data.company,
      companyBin: data.companyBin,
      address: data.address,
      phone: Number(data.phone),
      email: data.email,
      regionId: Number(data.region),
      cityId: Number(data.cityId),
      kkmId: data.kkmId,
      kkmSerialNumber: data.kkmSerialNumber,
      terminalId: data.terminalId,
      ownerId: data.ownerId
    }

    this.agentSrv.updateAgent(json)
      .subscribe(
        data => {
          let message = this.translate.instant('data-success-updated');
          this.openSnackBar(message, 'x');
          const dialogRef = this.dialog.closeAll();
        },
        error => {
          if (error.status === 409) {
            let message = this.translate.instant('an-error-occurred-while-processing');
            this.openSnackBar(message, 'x');
          }
          else
            if (error.status === 400) {
              let message = this.translate.instant(error?.error?.message);
              this.openSnackBar(message, 'x');
            }
            else
              if (error.status === 500) {
                let message = this.translate.instant('service-is-temporarily-unavailable');
                this.openSnackBar(message, 'x');
              }
              else {
                let message = this.translate.instant('an-error-occurred-while-processing');
                this.openSnackBar(message, 'x');
              }
        }
      );
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

}
