import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { AgentService } from 'src/app/services/agent.service';

@Component({
  selector: 'app-block-agent',
  templateUrl: './block-agent.component.html',
  styleUrls: ['./block-agent.component.css']
})
export class BlockAgentComponent implements OnInit {

  isCompleted: boolean = false;
  refillCodeEmail: string = null;
  isCodeSending: boolean = false;

  blockAgentForm = this.fb.group({
    agentId: [this.item.agent.id, Validators.required],
    blockDays: [0, [Validators.required, Validators.min(1)]]
  });

  constructor(@Inject(MAT_DIALOG_DATA) public item: any,
    private fb: FormBuilder,
    public snackBar: MatSnackBar,
    private translate: TranslateService,
    private agentSrv: AgentService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
      this.isCompleted = true;
  }

  // convenience getter for easy access to form fields
  get f() { return this.blockAgentForm.controls; }
  public hasError = (controlName: string, errorName: string) => {
    return this.blockAgentForm.controls[controlName].hasError(errorName);
  }

  public update = (form) => {
    if (this.blockAgentForm.valid) {
      this.execute(form);
    }
  }

  private execute = (data) => {
    let json: any = {
      AgentId: data.agentId,
      Days: Number(data.blockDays)
    }

    this.agentSrv.blockUser(json)
        .subscribe(
          data => {
            const dialogRef = this.dialog.closeAll();
            let message = this.translate.instant('successfully-blocked-agent');
            this.openSnackBar(message, 'x');
          },
          error => {

            if (error.status === 500) {
              let message = this.translate.instant('service-is-temporarily-unavailable');
              this.openSnackBar(message, 'x');
            }
            else {
              let message = this.translate.instant('an-error-occurred-while-processing');
              this.openSnackBar(message, 'x');
            }
          }
        ).add(() => {
          //http call ends
        });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

}
