<div fxLayout="column" fxLayoutAlign="center center">
    <h1 class="mat-headline">{{'tariff-all-rates'| translate}}</h1>
</div>
<div fxLayout="column" fxLayoutAlign="none">
    <div fxLayoutAlign="center center">
        <mat-form-field fxFlex="40">
            <input type="text" placeholder="{{'selectService'| translate}}" matInput [formControl]="selectedService"
                [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" autoActiveFirstOption (optionSelected)="getAllCurrentRates()">
                <mat-option *ngFor="let service of serviceAutoComplete$ | async; let index = index" [value]="service">
                    {{service.alias}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{'paynetFixedStatus' | translate}}</mat-label>
            <mat-select class="region-selection-width" placeholder="{{'paynetFixedStatus' | translate}}" [formControl]="isPaynetFixedAt" (selectionChange)="getAllCurrentRates()">
                <mat-option value="-1">{{'select-all' | translate}}</mat-option>
                <mat-option value="1">{{'yes' | translate}}</mat-option>
                <mat-option value="0">{{'no' | translate}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{'ukassaFixedStatus' | translate}}</mat-label>
            <mat-select class="region-selection-width" placeholder="{{'ukassaFixedStatus' | translate}}" [formControl]="isUkassaFixedAt" (selectionChange)="getAllCurrentRates()">
                <mat-option value="-1">{{'select-all' | translate}}</mat-option>
                <mat-option value="1">{{'yes' | translate}}</mat-option>
                <mat-option value="0">{{'no' | translate}}</mat-option>
            </mat-select>
        </mat-form-field>

        <button mat-icon-button color="primary" (click)="getAllCurrentRates()">
            <mat-icon>search</mat-icon>
        </button>

        <button mat-icon-button color="primary" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="mainMenuButtonExcel" [disabled]="dataSource?.filteredData?.length < 1">
            <mat-icon>save_alt</mat-icon>
        </button>
        <mat-menu #mainMenuButtonExcel="matMenu">
            <div class="table-column-list-block">
                <section class="button-download">
                    <button mat-raised-button color="primary"  (click)="exportToExcel()" >
                        {{'export' | translate}}
                        <mat-icon>save_alt</mat-icon>
                    </button>   
                </section>
                <span (click)="$event.stopPropagation()" *ngFor="let element of columnDefinitionsForExcel;index as i">
                    <mat-slide-toggle color="primary" (change)="element.isShow = !element.isShow" [checked]="element.isShow == true"></mat-slide-toggle>
                    {{element.label}}
                    <br>
                </span>
            </div>
        </mat-menu>

    </div>
    
    <mat-progress-bar mode="indeterminate" *ngIf="!isCompleted"></mat-progress-bar>

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z0">

        <!-- serviceId Column -->
        <ng-container matColumnDef="serviceId">
            <th mat-header-cell *matHeaderCellDef> {{'serviceId' | translate}} </th>
            <td mat-cell *matCellDef="let element" [class.error-tariffs-bkg]="element.paynetFixedAt != null || element.ukassaFixedAt != null"> {{element.serviceId}} </td>
        </ng-container>

        <!-- serviceName Column -->
        <ng-container matColumnDef="serviceName">
            <th mat-header-cell *matHeaderCellDef> {{'serviceName' | translate}} </th>
            <td mat-cell *matCellDef="let element" [class.error-tariffs-bkg]="element.paynetFixedAt != null || element.ukassaFixedAt != null"> {{element.serviceName}} </td>
        </ng-container>

        <!-- tariffName Column -->
        <ng-container matColumnDef="tariffName">
            <th mat-header-cell *matHeaderCellDef> {{'tariffName' | translate}} </th>
            <td mat-cell *matCellDef="let element">{{element.tariffName}} </td>
        </ng-container>

        <!-- action Column -->
        <ng-container matColumnDef="tariffTree">
            <th mat-header-cell *matHeaderCellDef> {{'tariffTree' | translate}} </th>
            <td mat-cell *matCellDef="let element">    
                <button mat-icon-button color="primary" (click)="openTariffTreeDialog(element)">
                    <mat-icon>device_hub</mat-icon>
                </button>
            </td>
        </ng-container>

        <!-- isEnable Column -->
        <ng-container matColumnDef="isEnable">
            <th mat-header-cell *matHeaderCellDef> {{'isEnable' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.isEnable?.toString() | translate}}
            </td>
        </ng-container>

        <!-- itoPercent Column -->
        <ng-container matColumnDef="itoPercent">
            <th mat-header-cell *matHeaderCellDef [innerHTML]="'itoPercent' | translate"></th>
            <td mat-cell *matCellDef="let element"> {{element.itoPercent / 100}}
            </td>
        </ng-container>
       
         <!-- itoFix Column -->
         <ng-container matColumnDef="itoFix">
            <th mat-header-cell *matHeaderCellDef [innerHTML]="'itoFix' | translate"></th>
            <td mat-cell *matCellDef="let element"> {{element.itoFix / 100| currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}
            </td>
        </ng-container>
       
        <!-- lowerPercent Column -->
        <ng-container matColumnDef="lowerPercent">
            <th mat-header-cell *matHeaderCellDef [innerHTML]="'lowerPercent' | translate"></th>
            <td mat-cell *matCellDef="let element"> {{element.lowerPercent / 100}}
            </td>
        </ng-container>
       
        <!-- lowerFix Column -->
         <ng-container matColumnDef="lowerFix">
            <th mat-header-cell *matHeaderCellDef [innerHTML]="'lowerFix' | translate"></th>
            <td mat-cell *matCellDef="let element"> {{element.lowerFix / 100 | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}
            </td>
        </ng-container>

        <!-- updatedAt Column -->
         <ng-container matColumnDef="updatedAt">
            <th mat-header-cell *matHeaderCellDef [innerHTML]="'u-terminal-updated-time' | translate"></th>
            <td mat-cell *matCellDef="let element"> {{element.updatedAt | date: 'dd/MM/yyyy HH:mm:ss'}}
            </td>
        </ng-container>
       
        <!-- paynetFixedAt Column -->
        <ng-container matColumnDef="paynetFixedAt">
            <th mat-header-cell *matHeaderCellDef [innerHTML]="'paynet-updated-time' | translate"></th>
            <td mat-cell *matCellDef="let element"> {{element.paynetFixedAt | date: 'dd/MM/yyyy HH:mm:ss'}}
            </td>
        </ng-container>

         <!-- ukassaFixedAt Column -->
         <ng-container matColumnDef="ukassaFixedAt">
            <th mat-header-cell *matHeaderCellDef [innerHTML]="'ukassa-updated-time' | translate"></th>
            <td mat-cell *matCellDef="let element"> {{element.ukassaFixedAt | date: 'dd/MM/yyyy HH:mm:ss'}}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSize]="1000" [pageSizeOptions]="[10, 25, 100, 500, 1000, 5000, 10000]"></mat-paginator>
</div>
