import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-delete',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.css']
})
export class ConfirmDeleteComponent {

  message: string = "Вы действительно хотите удалить?"
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<ConfirmDeleteComponent>) {
    if(data){
      this.message = data.message || this.message;
    }
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }

}
