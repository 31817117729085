<div fxLayout="column" fxLayoutAlign="center center">
    <h1 class="mat-headline">{{'tariffs'| translate}}</h1>
</div>

<div fxLayout="column" fxLayoutAlign="none">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z0">

        <!-- name Column -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> {{'tariff-name' | translate}} </th>
            <td mat-cell *matCellDef="let element" [style.padding-left.px]="[ 25 * element.level]"> {{element.name }} </td>
        </ng-container>

        <!-- id Column -->
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> {{'tariffId' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.id }} </td>
        </ng-container>

        <!-- weight Column -->
        <ng-container matColumnDef="weight">
            <th mat-header-cell *matHeaderCellDef> {{'tariff-weight' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.weight}} </td>
        </ng-container>

        <!-- basic Column -->
        <ng-container matColumnDef="basic">
            <th mat-header-cell *matHeaderCellDef> {{'is-basic-tariff' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.basic.toString() | translate}} </td>
        </ng-container>

        <!-- creator Column -->
        <ng-container matColumnDef="creator">
            <th mat-header-cell *matHeaderCellDef> {{'tariff-author' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.lname}} {{ (element.fname ? element.fname : element.company)}}</td>
        </ng-container>

        <!-- terminalId Column -->
        <ng-container matColumnDef="terminalId">
            <th mat-header-cell *matHeaderCellDef> {{'terminalId' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.terminalId}}</td>
        </ng-container>

        <!-- created Column -->
        <ng-container matColumnDef="created">
            <th mat-header-cell *matHeaderCellDef> {{'create-time' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.created | date:"dd/MM/yyyy HH:mm:ss"}} </td>
        </ng-container>

        <!-- isDeleted Column -->
        <ng-container matColumnDef="isDeleted">
          <th mat-header-cell *matHeaderCellDef> {{'tariff-delete-status' | translate}} </th>
          <td mat-cell *matCellDef="let element"> {{(element.isDeleted === 'True') ? ('tariff-is-deleted' | translate) : ('tariff-is-not-deleted' | translate)}} </td>
        </ng-container>

        <!-- action Column -->
        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> {{'settings' | translate}} </th>
            <td mat-cell *matCellDef="let element" class="agents-table-action-td">
                <section class="template-section">
                    <button mat-icon-button color="primary" [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu" class="agents-action-mat-menu">
                        <button mat-menu-item (click)="openTariffViewDialog(element)" mat-menu-item>
                            <mat-icon>bar_chart</mat-icon>
                            <span>{{'view-tariff-commissions' | translate}}</span>
                        </button>

                        <a href="/settings/add-tariff/{{element.id}}" style="text-decoration: none;">
                            <button mat-menu-item>
                                <mat-icon>add</mat-icon>
                                <span>{{'add-sub-tariff' | translate}}</span>
                            </button>
                        </a>

                        <a href="/settings/edit-tariff/{{element.id}}" style="text-decoration: none;">
                            <button mat-menu-item>
                                <mat-icon>edit</mat-icon>
                                <span>{{'edit-tariff' | translate}}</span>
                            </button>
                        </a>

                      <a style="text-decoration: none;" (click)="(element.isDeleted === 'True')? onClickRestoreTariff(element.id) : onClickDeleteTariff(element.id, element.basic, element.fname, element.lname)">
                        <button mat-menu-item>
                          <mat-icon>{{ (element.isDeleted === 'True') ? 'restore' : 'delete' }}</mat-icon>
                          <span>{{(element.isDeleted === 'True') ? ('restore-tariff-btn' | translate) : ('delete-tariff-btn' | translate)}}</span>
                        </button>
                      </a>
                    </mat-menu>
                </section>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>
