<div fxLayout="column" fxLayoutAlign="center center">
    <h1 class="mat-headline">{{'transactions'| translate}}</h1>
    <h4 class="mat-subheading-1">{{'transactions-report-description' | translate}}</h4>
</div>

<div class="container" fxLayout fxLayout.xs="column" fxLayoutAlign="center center" fxLayoutGap="10px"
    fxLayoutGap.xs="0">

    <mat-form-field fxFlex="40">
        <input type="text" matInput placeholder="{{'parameter' | translate}}" [formControl]="abonentParam" (keydown.enter)="updateReport()">
        <button *ngIf="abonentParam != null" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="clearSearchParam()">
            <mat-icon>close</mat-icon>
          </button>
    </mat-form-field>

    <mat-form-field fxFlex="40">
        <input type="text" placeholder="{{'agent'| translate}}" matInput [formControl]="selectedAgent"
            [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" autoActiveFirstOption (optionSelected)="updateReport()">
            <mat-option *ngFor="let option of agentAutoComplete$ | async; let index = index" [value]="option">
                {{option.title}}
            </mat-option>
        </mat-autocomplete>
        <button *ngIf="selectedAgent != null" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
            <mat-icon>close</mat-icon>
          </button>
    </mat-form-field>

    <mat-form-field fxFlex="40">
        <input type="text" placeholder="{{'selectService'| translate}}" matInput [formControl]="selectedService"
            [matAutocomplete]="auto2">
        <mat-autocomplete #auto2="matAutocomplete" [displayWith]="displayFnService" autoActiveFirstOption (optionSelected)="updateReport()">
            <mat-option *ngFor="let service of serviceAutoComplete$ | async; let index = index" [value]="service">
                {{service.alias}}
            </mat-option>
        </mat-autocomplete>
        <button *ngIf="selectedService != null" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="clearSearchService()">
            <mat-icon>close</mat-icon>
          </button>
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{'dateFrom' | translate}}</mat-label>
        <input matInput [matDatepicker]="pickerFrom" (click)="pickerFrom.open()" [formControl]="dateFrom"
            placeholder="dateFrom" (dateChange)="updateReport()" readonly>
        <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
        <mat-datepicker #pickerFrom></mat-datepicker>
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{'dateTo' | translate}}</mat-label>
        <input matInput [matDatepicker]="pickerTo" (click)="pickerTo.open()" [formControl]="dateTo"
            placeholder="{{'dateFrom'| translate}}" (dateChange)="updateReport()" readonly>
        <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
        <mat-datepicker #pickerTo></mat-datepicker>
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{'transactionType' | translate}}</mat-label>
        <mat-select class="region-selection-width" placeholder="{{'transactionType' | translate}}" [formControl]="transactionType" (selectionChange)="updateReport()">
            <mat-option>{{'all' | translate}}</mat-option>
            <mat-option *ngFor="let item of transactionTypes" [value]="item.code">
                {{ item.title }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{'transactionStatus' | translate}}</mat-label>
        <mat-select class="region-selection-width" placeholder="{{'transactionStatus' | translate}}" [formControl]="transactionStatus" (selectionChange)="updateReport()">
            <mat-option>{{'all' | translate}}</mat-option>
            <mat-option *ngFor="let item of transactionStatuses" [value]="item.code">
                {{ item.title }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <button mat-icon-button color="primary" (click)="getReport()">
        <mat-icon>search</mat-icon>
    </button>



    <button mat-icon-button color="primary" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="mainMenuButton">
        <mat-icon>settings</mat-icon>
    </button>
    <mat-menu #mainMenuButton="matMenu">
        <div class="table-column-list-block">
            <span (click)="$event.stopPropagation()" *ngFor="let element of columnDefinitions;index as i">
                <mat-slide-toggle color="primary" (change)="element.isShow = !element.isShow" [checked]="element.isShow == true"></mat-slide-toggle>
                {{element.label}}
                <br>
            </span>
        </div>
    </mat-menu>

    <button mat-icon-button color="primary" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="mainMenuButtonExcel" [disabled]="dataSource?.length < 1">
        <mat-icon>save_alt</mat-icon>
    </button>
    <mat-menu #mainMenuButtonExcel="matMenu">
        <div class="table-column-list-block">
            <section class="button-download">
                <button mat-raised-button color="primary"  (click)="exportToExcel()" >
                    {{'export' | translate}}
                    <mat-icon>save_alt</mat-icon>
                </button>
            </section>
            <span (click)="$event.stopPropagation()" *ngFor="let element of columnDefinitionsForExcel;index as i">
                <mat-slide-toggle color="primary" (change)="element.isShow = !element.isShow" [checked]="element.isShow == true"></mat-slide-toggle>
                {{element.label}}
                <br>
            </span>
        </div>
    </mat-menu>

</div>
<mat-progress-bar mode="indeterminate" *ngIf="!isCompleted"></mat-progress-bar>
<div fxLayout="column" fxLayoutAlign="none">
    <table mat-table [dataSource]="datasourceTableForPagination" class="mat-elevation-z0 transaction-report-table">

          <!-- status Column -->
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> {{'report-status' | translate}}</th>
            <td mat-cell *matCellDef="let element">
                <mat-icon matTooltip="{{element.StatusTitle}}" color="primary-custom" *ngIf="element.Type == 1"  [ngClass]="{'inprogress-transaction-icon': element.Status == 0, 'completed-transaction-icon' : element.Status == 1, 'declined-transaction-icon' : element.Status == 2, 'canceled-transaction-icon' : element.Status == 3 || element.Status == 4 }">{{globalCurrencySymbol}}</mat-icon>
                <mat-icon matTooltip="{{element.StatusTitle}}" color="primary" *ngIf="element.Type == 2 || element.Type == 777" [ngClass]="{'inprogress-transaction-icon': element.Status == 0, 'completed-transaction-icon' : element.Status == 1, 'declined-transaction-icon' : element.Status == 2, 'canceled-transaction-icon' : element.Status == 3 || element.Status == 4 }">%</mat-icon>
                <mat-icon matTooltip="{{element.StatusTitle}}" color="primary" *ngIf="element.Type == 999 || element.Type == 888" [ngClass]="{'inprogress-transaction-icon': element.Status == 0, 'completed-transaction-icon' : element.Status == 1, 'declined-transaction-icon' : element.Status == 2, 'canceled-transaction-icon' : element.Status == 3 || element.Status == 4 }">credit_card</mat-icon>
                <mat-icon matTooltip="{{element.StatusTitle}}" color="primary" *ngIf="element.Type == 3 || element.Type == 6" [ngClass]="{'inprogress-transaction-icon': element.Status == 0, 'completed-transaction-icon' : element.Status == 1, 'declined-transaction-icon' : element.Status == 2, 'canceled-transaction-icon' : element.Status == 3 || element.Status == 4 }">money_off</mat-icon>
                <mat-icon matTooltip="{{element.StatusTitle}}" color="primary" *ngIf="element.Type == 4" [ngClass]="{'inprogress-transaction-icon': element.Status == 0, 'completed-transaction-icon' : element.Status == 1, 'declined-transaction-icon' : element.Status == 2, 'canceled-transaction-icon' : element.Status == 3 || element.Status == 4 }">compare_arrows</mat-icon>
                <mat-icon matTooltip="{{element.StatusTitle}}" color="primary" *ngIf="element.Type == 5" [ngClass]="{'inprogress-transaction-icon': element.Status == 0, 'completed-transaction-icon' : element.Status == 1, 'declined-transaction-icon' : element.Status == 2, 'canceled-transaction-icon' : element.Status == 3 || element.Status == 4 }">reply</mat-icon>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <!-- From Account Name Column -->
        <ng-container matColumnDef="fromToAccountName">
            <th mat-header-cell *matHeaderCellDef> {{'from-account-name' | translate}}</th>
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element">
                <span matTooltip="{{'report-transaction-id-desc' | translate}}: {{element.Id}} {{'parent-transaction-id' | translate}} {{element.ParentTransactionId}}">
                    <span class="mat-small author-mat-small" *ngIf="element.TypeTitle">
                        {{element.TypeTitle}} - {{element.StatusTitle}}
                    </span>
                    <span color="primary" class="mat-small fail-reason" *ngIf="element.Status == 2">
                        {{element.FailReason | translate}}
                    </span>
                    <span class="mat-body" *ngIf="element.FromAccountName">{{element.FromAccountName }} <br></span>
                    <span class="mat-body" class="to-account-span"><mat-icon class="from-to-account-icon">arrow_right_alt</mat-icon> {{element.ToAccountName }}</span>
                </span>
                <span class="mat-small author-mat-small" *ngIf="element.Creator">{{'author' | translate}} {{element.Creator}} </span>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

         <!-- createdAt Column -->
         <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef> {{'createdAt' | translate}}</th>
            <td mat-cell *matCellDef="let element">
                {{element.CreatedAt | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <!-- completedAt Column -->
        <ng-container matColumnDef="completedAt">
            <th mat-header-cell *matHeaderCellDef> {{'completedAt' | translate}}</th>
            <td mat-cell *matCellDef="let element">
                {{element.CompletedAt | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <!-- accepted Column -->
        <ng-container matColumnDef="accepted">
            <th mat-header-cell *matHeaderCellDef> {{'report-accepted' | translate}}</th>
            <td mat-cell *matCellDef="let element">{{element.Accepted | number : '1.2-2'}}
            </td>
            <td mat-footer-cell *matFooterCellDef>{{getTotalAccepted() | number : '1.2-2'}} </td>
        </ng-container>

         <!-- amount Column -->
         <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef> {{'report-user-amount' | translate}}</th>
            <td mat-cell *matCellDef="let element"> {{element.Amount | number : '1.2-2'}}
            </td>
            <td mat-footer-cell *matFooterCellDef>{{getTotalUserAmount() | number : '1.2-2'}} </td>
        </ng-container>

        <!-- commission Column -->
        <ng-container matColumnDef="commission">
            <th mat-header-cell *matHeaderCellDef> {{'report-upper-commission-amount' | translate}}</th>
            <td mat-cell *matCellDef="let element">
                {{element.Commission | number : '1.2-2'}} </td>
            <td mat-footer-cell *matFooterCellDef>{{getTotalUpper() | number : '1.2-2'}} </td>
        </ng-container>

        <!-- canceledAmount Column -->
        <ng-container matColumnDef="canceledAmount">
            <th mat-header-cell *matHeaderCellDef> {{'report-canceled-amount' | translate}}</th>
            <td mat-cell *matCellDef="let element"> {{element.CanceledAmount | number : '1.2-2'}}
            </td>
            <td mat-footer-cell *matFooterCellDef>{{getTotalCanceledAmount() | number : '1.2-2'}} </td>
        </ng-container>

        <!-- commissionRates Column -->
        <ng-container matColumnDef="commissionRates">
            <th mat-header-cell *matHeaderCellDef> {{'commissions' | translate}}</th>
            <td mat-cell *matCellDef="let element">
                <span class="mat-small" *ngIf="element.Rates?.LowerFix.toString().length > 0">{{'lowerFix' | translate}}: {{element.Rates?.LowerFix/100 | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} <br> </span>
                <span class="mat-small" *ngIf="element.Rates?.LowerPercent.toString().length > 0">{{'lowerPercent' | translate}}: {{element.Rates?.LowerPercent/100}} <br> </span>
                <span class="mat-small" *ngIf="element.Rates?.ItoFix.toString().length > 0">{{'itoFix' | translate}}: {{element.Rates?.ItoFix/100 | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} <br></span>
                <span class="mat-small" *ngIf="element.Rates?.ItoPercent.toString().length > 0">{{'itoPercent' | translate}}: {{element.Rates?.ItoPercent/100}} <br></span>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <!-- serviceInfo Column -->
        <ng-container matColumnDef="serviceInfo">
            <th mat-header-cell *matHeaderCellDef> {{'serviceName' | translate}}</th>
            <td mat-cell *matCellDef="let element">
                <span  class="mat-body" *ngIf="element.ServiceId > 0">{{element.ServiceId}}  {{element.ServiceName}}</span>
                <div *ngIf="element.Parameters">
                    <span class="mat-small" *ngFor="let parameter of element.Parameters | keyvalue">
                        {{parameter.key.toLowerCase() | translate}} - {{parameter.value}}
                    </span><br>
                </div>
                <div *ngIf="element.Type == 1">
                    <span class="mat-small">{{'provider-amount' | translate}} {{element.ProviderAmount  | number : '1.2-2'}}</span>
                </div>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <!-- description Column -->
        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef> {{'report-description' | translate}}</th>
            <td mat-cell *matCellDef="let element">
                {{element.Description}}</td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <!-- Id Column -->
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> {{'report-transaction-id-desc' | translate}}</th>
            <td mat-cell *matCellDef="let element">
                {{element.Id}}</td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

          <!-- paynetReference Column -->
          <ng-container matColumnDef="paynetReference">
            <th mat-header-cell *matHeaderCellDef> {{'paynet-reference' | translate}}</th>
            <td mat-cell *matCellDef="let element">
                {{element.PaynetReference}}
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

         <!-- isFiscal Column -->
         <ng-container matColumnDef="isFiscal">
            <th mat-header-cell *matHeaderCellDef> {{'is-fiscal' | translate}}</th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="element.IsFiscal">{{'yes' | translate}}</span>
                <span *ngIf="!element.IsFiscal">{{'no' | translate}}</span>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>


        <!-- isPaymentInOwnFavor Column -->
        <ng-container matColumnDef="IsPaymentInOwnFavor">
          <th mat-header-cell *matHeaderCellDef> {{'payment-in-own-favour' | translate}} </th>
          <td mat-cell *matCellDef="let element">
            <mat-icon *ngIf="element.IsPaymentInOwnFavor; else noIcon">check_circle</mat-icon>
            <ng-template #noIcon>
              <mat-icon>close</mat-icon>
            </ng-template>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <!-- action Column -->
        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <a *ngIf="(element.Status==1 || element.Status==0) && element.Type == 1" (click)="openTransactionDetailDialog(element)">
                    <button mat-icon-button color="primary">
                        <mat-icon>receipt</mat-icon>
                    </button>
                </a>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="getDisplayedColumns(); sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: getDisplayedColumns();"></tr>
        <tr mat-footer-row *matFooterRowDef="getDisplayedColumns()"></tr>
    </table>
    <mat-paginator [pageSize]="100" [pageSizeOptions]="[10, 25, 100, 500, 1000, 5000, 10000]"></mat-paginator>
</div>
