import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { NewsService } from 'src/app/services/news.service';
import { ConfirmDeleteComponent } from '../../custom-dialogs/confirm-delete/confirm-delete.component';
import { EditNewsComponent } from './edit-news/edit-news.component';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
  isCompleted: boolean = true;

  displayedColumns: string[] = ['image', 'title', 'newsDate', 'action'];
  news: any[] = [];
  dataSource: any[] = [];
  staticApiUrl = environment.api_static;

  constructor(private newsSrv: NewsService,
    private translate: TranslateService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.getNews();
  }

  getNews() {
    this.isCompleted = false;
    this.news = [];
    this.dataSource = [];

    this.newsSrv.get().subscribe(
      (data: any[]) => {
        this.news = data;
        this.dataSource = this.news;
      },
      error => {
        if (error.status === 404) {
          let message = this.translate.instant('not-found');
          this.openSnackBar(message, 'x');
        }
        else
          if (error.status === 400) {
            let message = this.translate.instant('an-error-occurred-while-processing');
            this.openSnackBar(message, 'x');
          }
          else
            if (error.status === 500) {
              let message = this.translate.instant('service-is-temporarily-unavailable');
              this.openSnackBar(message, 'x');
            }
            else {
              let message = this.translate.instant('an-error-occurred-while-processing');
              this.openSnackBar(message, 'x');
            }
      }
    ).add(() => { this.isCompleted = true });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  openNewsEditDialog(item: any) {
    const dialogRef = this.dialog.open(EditNewsComponent,
      { data: { news: item },
      maxHeight: '95vh' });

    dialogRef.afterClosed().subscribe(result => {
      this.getNews();
    });
  }

  openNewsAddDialog() {
    var item = { id: 0, title: "", text: "", newsDate: null };
    const dialogRef = this.dialog.open(EditNewsComponent,
      { data: { news: item },
      maxHeight: '95vh' });

    dialogRef.afterClosed().subscribe(result => {
      this.getNews();
    });
  }

  deleteNews(item: any) {
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      panelClass: 'confirm-delete-dialog',
      data: {
        message: this.translate.instant('are-you-sure-delete-news', { title: item.title })
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.newsSrv.deleteNews(item.id).subscribe(
          () => {
            const message = this.translate.instant('success-delete-news');
            this.openSnackBar(message, 'x');
            this.getNews();
          },
          error => {
            const message = this.translate.instant('failure-delete-news');
            this.openSnackBar(message, 'x');
          });
      }
    });
  }
}
