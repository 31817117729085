import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { PagesService } from 'src/app/services/pages.service';
import { ConfirmDeleteComponent } from '../../custom-dialogs/confirm-delete/confirm-delete.component';
import { EditPageComponent } from './edit-page/edit-page.component';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.css']
})
export class PagesComponent implements OnInit {

  displayedColumns: string[] = ['code', 'title', 'action'];
  pages: any[] = [];
  dataSource: any[] = [];
  isCompleted: boolean = false;

  constructor(private pagesSrv: PagesService,
    private translate: TranslateService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.getPages();
  }

  getPages() {
    this.isCompleted = false;
    this.pages =[];
    this.dataSource=[];

    this.pagesSrv.get().subscribe(
      (data: any[]) => {
        this.pages = data;
        this.dataSource = this.pages?.sort((a, b) => a.id - b.id);
      },
      error => {
        if (error.status === 404) {
          let message = this.translate.instant('not-found');
          this.openSnackBar(message, 'x');
        }
        else
          if (error.status === 400) {
            let message = this.translate.instant('an-error-occurred-while-processing');
            this.openSnackBar(message, 'x');
          }
          else
            if (error.status === 500) {
              let message = this.translate.instant('service-is-temporarily-unavailable');
              this.openSnackBar(message, 'x');
            }
            else {
              let message = this.translate.instant('an-error-occurred-while-processing');
              this.openSnackBar(message, 'x');
            }
      }
     ).add(() => { this.isCompleted = true });
    }

    openSnackBar(message: string, action: string) {
      this.snackBar.open(message, action, {
        duration: 5000,
      });
    }

    openPageEditDialog(item: any){
      const dialogRef = this.dialog.open(EditPageComponent,
        { data: { pages: item },
        maxHeight: '95vh' });

        dialogRef.afterClosed().subscribe(result => {
          this.getPages();
      });
    }

    openPageAddDialog(){
      var item = {title: "", content: "", code: ""};
      const dialogRef = this.dialog.open(EditPageComponent,
        { data: { pages: item },
        maxHeight: '95vh' });

      dialogRef.afterClosed().subscribe(result => {
          this.getPages();
      });
    }

    deletePage(item: any){
      const dialogRef = this.dialog.open(ConfirmDeleteComponent,{
        panelClass: 'confirm-delete-dialog',
        data:{
          message: this.translate.instant('are-you-sure-delete-pages', {title: item.title})
        }
      });
      
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
                   
          this.pagesSrv.deletePage(item.code).subscribe(
            (data: any) => {
              if (data == true)
              {
                let message = this.translate.instant('success-delete-pages');
                this.openSnackBar(message, 'x');
                this.getPages();
              }
              else
              console.log("error");
            },
            error => console.log(error))
        }
      });
    }
}
