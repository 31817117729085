<mat-nav-list density>
    <div>
        <div *ngIf="authService.isAdmin || authService.isManager || authService.isAccountant">
            <div mat-subheader>{{'reports' | translate}}</div>
            <a mat-list-item [routerLink]="'/reports/current-balance'">{{'current-balance-report' | translate}} </a>
            <a mat-list-item [routerLink]="'/reports/account-balance'">{{'account-balance-report' | translate}} </a>
            <a mat-list-item [routerLink]="'/reports/consolidated-report'">
                {{'balanceSummary' | translate}} </a>
            <a mat-list-item [routerLink]="'/reports/consolidated-report-extended'">
                {{'consolidated-report'| translate}} </a>
            <a mat-list-item [routerLink]="'/reports/providers-report'">
                {{'providers-report' | translate}} </a>
            <a mat-list-item [routerLink]="'/reports/income-report'">
                {{'income-report' | translate}} </a>
            <a mat-list-item [routerLink]="'/reports/transactions-report'">{{'transactions-report' | translate}} </a>
            <a mat-list-item [routerLink]="'/reports/paid-lower-commission-report'">{{'paid-lower-commission-report' | translate}} </a>
            <a mat-list-item [routerLink]="'/reports/agents-report'">{{'agents-report' | translate}} </a>
            <a mat-list-item [routerLink]="'/reports/region-report'">{{'region-report' | translate}} </a>
            <!-- <mat-divider></mat-divider>
            <div mat-subheader>{{'operation-reports' | translate}}</div>
            <a mat-list-item [routerLink]="'/reports/profiles-report'">{{'profiles-report' | translate}} </a>
            <a mat-list-item [routerLink]="'/reports/idle-profiles-report'">{{'idle-profiles-report' | translate}} </a> -->
        </div>

        <div *ngIf="authService.isAdmin || authService.isAccountant">
            <mat-divider></mat-divider>
            <div mat-subheader>{{'settings' | translate}}</div>

            <div *ngIf="authService.isAdmin">
                <a mat-list-item [routerLink]="'/settings/category'">{{'categories-settings' | translate}} </a>
                <a mat-list-item [routerLink]="'/settings/service'">{{'services-settings' | translate}} </a>
            </div>

            <a mat-list-item [routerLink]="'/settings/agents'">{{'agents-settings' | translate}} </a>

            <a *ngIf="authService.isAccountant" mat-list-item [routerLink]="'/settings/lower-commissions-pay'">{{'lower-commission-pay-report' | translate}}</a>
            
            <div *ngIf="authService.isAdmin">
                <a mat-list-item [routerLink]="'/settings/pages'">{{'pages-settings' | translate}} </a>
                <a mat-list-item [routerLink]="'/settings/news'">{{'settings-news' | translate}} </a>
                <mat-divider></mat-divider>
                <a mat-list-item [routerLink]="'/settings/tariffs'">{{'tariffs' | translate}} </a>
                <a mat-list-item [routerLink]="'/settings/upper-commissions'">{{'manage-upper-commissions' | translate}} </a>
                <a mat-list-item [routerLink]="'/settings/refund-requests'">{{'refund-requests' | translate}} </a>
                <a mat-list-item [routerLink]="'/rates'">{{'tariff-rates' | translate}} </a>
                <a mat-list-item [routerLink]="'/all-rates'">{{'tariff-all-rates' | translate}} </a>
            </div>
        </div>
    </div>
</mat-nav-list>