<div fxLayout="column" fxLayoutAlign="center center">
    <h1 class="mat-headline">{{'idle-profiles-report'| translate}}</h1>   
</div>
<div class="container" fxLayout fxLayout.xs="column" fxLayoutAlign="center center" fxLayoutGap="10px"
    fxLayoutGap.xs="0">
   
    <mat-form-field>
        <mat-label>{{'dateFrom' | translate}}</mat-label>
        <input matInput [matDatepicker]="pickerFrom" (click)="pickerFrom.open()" [formControl]="dateFrom" placeholder="{{'dateFrom'| translate}}" (dateChange)="dateFromChanged()">
        <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
        <mat-datepicker #pickerFrom></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
        <mat-label>{{'dateTo' | translate}}</mat-label>
        <input matInput [matDatepicker]="pickerTo"  (click)="pickerTo.open()" [formControl]="dateTo" placeholder="{{'dateTo'| translate}}" (dateChange)="dateToChanged()">
        <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
        <mat-datepicker #pickerTo></mat-datepicker>
    </mat-form-field>

    <button mat-icon-button color="primary" (click)="getReport()">
        <mat-icon>search</mat-icon>
    </button>

    <button mat-icon-button color="primary" (click)="exportToExcel()" [disabled]="dataSource?.length==0">
        <mat-icon>save_alt</mat-icon>
    </button>
</div>
<mat-progress-bar mode="indeterminate" *ngIf="!isCompleted"></mat-progress-bar>
<div fxLayout="column" fxLayoutAlign="none">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z0">

        <!-- date Column -->
        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef> {{'date' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.date | date: 'dd/MM/yyyy'}} </td>  
            <td mat-footer-cell *matFooterCellDef>{{'total' | translate}}</td>
        </ng-container>

        <!-- profileName Column -->
        <ng-container matColumnDef="profileName">
            <th mat-header-cell *matHeaderCellDef> {{'profileName' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.lName}} {{ (element.fName ? element.fName : element.profileName)}} </td>
            <td mat-footer-cell *matFooterCellDef>{{getTotal()}} </td>
        </ng-container>
       
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
    </table>
</div>