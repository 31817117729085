import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { tap, shareReplay } from 'rxjs/operators';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import jwtDecode, { JwtPayload } from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public isAuthenticated = new BehaviorSubject<boolean>(false);
  public decodedUserInfoRoles: any;
  public isManager: boolean = false;
  public isAdmin: boolean = false;
  public isAccountant: boolean = false;

  constructor(private http: HttpClient, private router: Router) {
      if (this.sessionExists()) {
        const token = localStorage.getItem("id_token");
        let tokenPayload = jwtDecode<JwtPayload>(token);
        if(tokenPayload != null && tokenPayload["role"]){
          this.decodedUserInfoRoles = tokenPayload["role"];
        }
        if(this.decodedUserInfoRoles != undefined){
          if(this.decodedUserInfoRoles?.indexOf("manager") !== -1){
              this.isManager = true;
          }
          if(this.decodedUserInfoRoles?.indexOf("admin") !== -1){
              this.isAdmin = true;
          }
          if(this.decodedUserInfoRoles?.indexOf("accountant") !== -1){
              this.isAccountant = true;
          }
        }
      }
  }

  login(username: string, password: string) {
    this.logout();

    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded' 
    })

    let body = 'client_secret=' + environment.auth.client_secret
     + '&scope=' + environment.auth.scope 
     + '&grant_type=' + environment.auth.grant_type
     + '&username=' + username 
     + '&password=' + encodeURIComponent(password) 
     + '&client_id=' + environment.auth.client_id;

    return this.http.post(environment.auth.url, body, { headers: headers }).pipe(
      tap(res => {
        this.setSession(res);
        this.isAuthenticated.next(true);
      }),
      shareReplay()
    );

  }

  private setSession(authResult) {
    const expireAt = moment().add(authResult.expires_in, 'second');
    localStorage.setItem('id_token', authResult.access_token);
    localStorage.setItem('expired_at', JSON.stringify(expireAt.valueOf()));
  }

  logout() {
    this.http.get(environment.auth.sign_out_url);
    localStorage.removeItem('id_token');
    localStorage.removeItem('expired_at');
    localStorage.removeItem('current_user');
    this.isAuthenticated.next(false);
    this.router.navigate(['/login']);
  }

  checkAuthenticated() {
    if (this.sessionExists()) {
      const authenticated = moment().isBefore(this.getExpiration());
      this.isAuthenticated.next(authenticated);
      return authenticated;
    }
    else {
      this.isAuthenticated.next(false);
      return false;
    }
  }

  isLoggedOut() {
    return !this.checkAuthenticated();
  }

  sessionExists() {
    const expiration = localStorage.getItem('expired_at');
    if (expiration != null && expiration != undefined) {
      return true;
    }
    else
      return false;
  }

  getExpiration() {
    const expiration = localStorage.getItem('expired_at');
    const expireAt = JSON.parse(expiration);
    return moment(expireAt);
  }

  getAuthUser(): any{
    let url = environment.auth.userinfo_url;
    return this.http.get(url);
  }

  getRoles(): any{
    const url = environment.api_identity + '/account/roles';

    return this.http.get(url);
  }

  getUserRoles(userId: string): any{
    const url = environment.api_identity + '/account/' + userId + '/roles';

    return this.http.get(url);
  }

  saveUserRoles(userId: string, roles: string[]): any{
    const url = environment.api_identity + '/account/' + userId + '/roles';

    return this.http.post(url, roles);
  }
}
