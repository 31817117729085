import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ILowerCommission } from 'src/app/interfaces/ilower-commission';
import { AuthService } from 'src/app/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ExportService } from 'src/app/services/export.service';
import { ServicesService } from 'src/app/services/services.service';
import { IService } from 'src/app/interfaces/iservice';
import { IAccount } from 'src/app/interfaces/iaccount';
import { AccountService } from 'src/app/services/account.service';
import { Observable, of } from 'rxjs';
import { AgentService } from 'src/app/services/agent.service';
import { catchError, debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { DictionaryService } from 'src/app/services/dictionary.service';
import { TranslateService } from '@ngx-translate/core';
import { ReportService } from 'src/app/services/report.service';
import { environment } from 'src/environments/environment';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-paid-lower-commission-report',
  templateUrl: './paid-lower-commission-report.component.html',
  styleUrls: ['./paid-lower-commission-report.component.css']
})
export class PaidLowerCommissionReportComponent implements OnInit {

  isCompleted: boolean = true;
  columnDefinitionsForExcel = [];
  uppers: any[] = [];
  services: any[] = [];
  lowers: ILowerCommission[] = [];
  displayedColumns: string[] = ['reference', 'fromAccountName', 'accountToName', 'serviceName', 'commissionAmount', 'isPaid', 'paidDate'];

  columnDefinitions = [
    { def: 'reference', isShow: true, 'label': 'Референс' },
    { def: 'fromAccountName', isShow: true, label: 'С аккаунта' },
    { def: 'accountToName', isShow: true, label: 'На аккаунт' },
    { def: 'serviceName', isShow: true, label: 'Услуга' },
    { def: 'commissionAmount', isShow: true, label: 'Комиссия' },
    { def: 'isPaid', isShow: true, label: 'Выплачено' },
    { def: 'paidDate', isShow: true, label: 'Дата выплаты' },
  ];

  dataSource: any[] = [];
  public accounts: IAccount[] = [];
  selectedAgent = new FormControl();
  selectedService = new FormControl();
  
  transactionDateFrom = new FormControl(new Date(new Date().setDate(new Date().getDate() - 30)));
  transactionDateTo = new FormControl(new Date());

  public agentAutoComplete$: Observable<any> = null;
  public serviceAutoComplete$: Observable<any> = null;

  // глобальные параметры валюты приложения
  globalLocation = environment.globalLocation;
  globalCurrencyCode = environment.globalCurrencyCode;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  datasourceTableForPagination: MatTableDataSource<ILowerCommission>;
  
  constructor(public authService: AuthService, 
    private servicesSrv: ServicesService,
    private exportSrv: ExportService,
    private accountSrv: AccountService,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private agentSrv: AgentService,
    private dictionarySrv: DictionaryService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private reportSrv: ReportService
  ) { }

  lookup(value: any): Observable<any> {  
    if (typeof value === 'object')
    {
      value = value.title;
    } 
    
    return this.agentSrv.search(value.toLowerCase()).pipe(
        // map the item property of the results as our return object
        map(results => results),
        // catch errors
        catchError(_ => {
          return of(null);
        })
      );
    }

  lookupServices(value: any): Observable<any> {  
      if (typeof value === 'object')
      {
        value = value.alias;
      } 
  
      return this.servicesSrv.search(value.toLowerCase()).pipe(
          // map the item property of the results as our return object
          map(results => results),
          // catch errors
          catchError(_ => {
            return of(null);
          })
        );
  }
    
  ngOnInit(): void {
    this.load();

    this.agentAutoComplete$ = this.selectedAgent.valueChanges.pipe(
      startWith(''),
      // delay emits
      debounceTime(300),
      // use switch map so as to cancel previous subscribed events, before creating new once
      switchMap(value => {
        if (value !== '') {
          // lookup from agents
          return this.lookup(value);
        } else {
          // if no value is pressent, return null
          return of(null);
        }
      })
    );

    this.serviceAutoComplete$ = this.selectedService.valueChanges.pipe(
      startWith(''),
      // delay emits
      debounceTime(300),
      // use switch map so as to cancel previous subscribed events, before creating new once
      switchMap(value => {
        if (value !== '') {
          // lookup from services
          return this.lookupServices(value);
        } else {
          // if no value is pressent, return null
          return of(null);
        }
      })
    );
  }

  load() {
    this.getAccounts();
    this.getServices();
    this.getAllPaidLowerCommissions();
  }

  getDisplayedColumns(): string[] {
    return this.columnDefinitions
      .filter(cd => cd.isShow == true)
      .map(cd => cd.def);
  }

  getAllPaidLowerCommissions() {
    this.dataSource = [];
    this.lowers = [];
    
    var curUserId = null;
    if (this.selectedAgent.value != null && this.selectedAgent.value != undefined) {
        curUserId = this.selectedAgent.value.code;
    }

    var curServiceId = null;
    if (this.selectedService.value != null && this.selectedService.value != undefined) {
        curServiceId = this.selectedService.value.id;
    }

    this.isCompleted = false;
    this.columnDefinitionsForExcel = [];
    this.datasourceTableForPagination = new MatTableDataSource<ILowerCommission>();
    this.reportSrv.paidLowerCommissionReport(curServiceId, curUserId,
                                           this.transactionDateFrom.value, this.transactionDateTo.value).subscribe(
      (data: ILowerCommission[]) => {
        this.lowers = data;
        this.dataSource = this.lowers?.sort((a, b) => new Date(b.paidDate).getTime() - new Date(a.paidDate).getTime());
        this.datasourceTableForPagination = new MatTableDataSource<ILowerCommission>(data)
        this.datasourceTableForPagination.paginator = this.paginator;
        if(this.dataSource[0]){
          this.columnDefinitionsForExcel = this.exportSrv.getAllColumnsExcel(Object.keys(this.dataSource[0]));
        }
      },
      error => {
        if (error.status === 404) {
          let message = this.translate.instant('no-data');
          this.openSnackBar(message, 'x');
        }
        else
          if (error.status === 400) {
            let message = this.translate.instant('bad-request');
            this.openSnackBar(message, 'x');
          }
          else
            if (error.status === 500) {
              let message = this.translate.instant('service-is-temporarily-unavailable');
              this.openSnackBar(message, 'x');
            }
            else {
              let message = this.translate.instant('an-error-occurred-while-processing');
              this.openSnackBar(message, 'x');
            }
      }
    ).add(() => { this.isCompleted = true })
  }

  getAccounts() {
    this.accounts = [];
    this.dictionarySrv.agents().subscribe(data => {
        this.accounts = data;
      },
      error => {
        if (error.status === 404) {
          let message = this.translate.instant('no-data');
          this.openSnackBar(message, 'x');
        }
        else
          if (error.status === 400) {
            let message = this.translate.instant('bad-request');
            this.openSnackBar(message, 'x');
          }
          else
            if (error.status === 500) {
              let message = this.translate.instant('service-is-temporarily-unavailable');
              this.openSnackBar(message, 'x');
            }
            else {
              let message = this.translate.instant('an-error-occurred-while-processing');
              this.openSnackBar(message, 'x');
            }
      }
      );
  }

  exportToExcel() {
    this.exportSrv.exportToExcel(this.dataSource, 'Report', 'filename.xlsx', this.columnDefinitionsForExcel);
  }

  displayFnService(service: IService): string {
    return service && service.alias ? service.alias : '';
  }

  displayFnAccount(account: IAccount): string {
    return account && account.title ? account.title : '';
  }

  getServices(){
    this.services = [];
    this.servicesSrv.get(-1,-1,null).subscribe(
      (data: any) => { 
        this.services = data;
       },
      error => console.log(error)
    )
  }

  public updateAllPaidLowerCommissions(){
    this.getAllPaidLowerCommissions();
  }

  displayFn(account: IAccount): string {
    return account && account.title ? account.title : '';
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  clearSearch(){
    this.selectedAgent.setValue("");
      this.getAllPaidLowerCommissions();   
  }

  clearSearchService(){
    this.selectedService.setValue("");
      this.getAllPaidLowerCommissions();   
  }

}
