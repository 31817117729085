<div fxLayout="column" fxLayoutAlign="center center">
    <h1 class="mat-headline">{{'refund-request'| translate}}</h1>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="!isCompleted || isRefundProcessing"></mat-progress-bar>
  <div *ngIf="isCompleted">
    <form [formGroup]="refundForm" novalidate (ngSubmit)="update(refundForm.value)">
        <mat-dialog-content class="mat-typography">
            <h3 *ngIf="item">{{'refund-id' | translate}}: {{item.refund.id}}</h3>
            <h3 *ngIf="item">{{'transaction-amount' | translate}}: {{item.refund.transactionAmount | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </h3>
            
            <mat-form-field fxFill>
                <mat-select placeholder="{{'refund-status-name' | translate}}" formControlName="statusId">
                    <mat-option [value]="">
                        {{ 'choose-status' | translate}}
                    </mat-option>
                    <mat-option *ngFor="let item of statuses" [value]="item.code">
                        {{ item.title }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field fxFill>
                <input #refundSum matInput type="text" placeholder="{{'canceled-sum' | translate}}" formControlName="canceledAmount" (keypress)="onKeypressAmountEvent($event, 'canceledAmount')" currencyMask>

                <mat-error *ngIf="refundForm.controls['canceledAmount'].hasError('required') || refundForm.controls['canceledAmount'].hasError('min')">
                    {{'canceled-sum-is-req' | translate}}
                </mat-error>
            </mat-form-field>

            <mat-form-field fxFill>
                <input #refundSum2 matInput type="text" placeholder="{{'canceled-sum-repeat' | translate}}" formControlName="canceledAmountRepeat" (keypress)="onKeypressAmountEvent($event, 'canceledAmountRepeat')" currencyMask>

                <mat-error *ngIf="f.canceledAmountRepeat.errors">
                    <div *ngIf="f.canceledAmountRepeat.errors.required || f.canceledAmountRepeat.errors.min">{{'canceled-sum-repeat-is-req' | translate}}</div>
                    <div *ngIf="f.canceledAmountRepeat.errors.mustMatch">{{'canceled-sum-repeat-must-match' | translate}}</div>
                </mat-error>
            </mat-form-field>

            <mat-form-field fxFill >
                <textarea matInput placeholder="{{'comment' | translate}}" formControlName="userComment"></textarea>
                <mat-error *ngIf="refundForm.controls['userComment'].hasError('required')">
                    {{'comment-is-req' | translate}}
                </mat-error>
            </mat-form-field>

        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-button mat-dialog-close>{{'cancel'| translate}}</button>
            <button mat-flat-button color="primary" type="submit" [disabled]="isRefundProcessing"
                cdkFocusInitial>{{'to-refund'| translate}}</button>
        </mat-dialog-actions>
    </form>
  </div>