<div fxLayout="column" fxLayoutAlign="center center">
    <h1 class="mat-headline">{{'upper-commission'| translate}}</h1>
  </div>

<mat-dialog-content class="mat-typography">
    <h3>{{item?.service?.serviceName}}</h3>
    <mat-progress-bar mode="indeterminate" *ngIf="!isCompleted"></mat-progress-bar>
    <div fxLayout="column" fxLayoutGap="30px" *ngIf="isCompleted">

        <ng-container>         
            <div fxLayout="column" fxLayoutAlign="none">
                 <p>{{'fix' | translate}}</p>  
                <table mat-table [dataSource]="uppersFix" class="mat-elevation-z0">

                    <!-- from Column -->
                    <ng-container matColumnDef="from">
                        <th mat-header-cell *matHeaderCellDef> {{'from' | translate}} </th>
                        <td mat-cell *matCellDef="let element"> {{element.from | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}
                        </td>
                    </ng-container>
                    <!-- to Column -->
                    <ng-container matColumnDef="to">
                        <th mat-header-cell *matHeaderCellDef> {{'to' | translate}} </th>
                        <td mat-cell *matCellDef="let element"> {{element.to | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}
                        </td>
                    </ng-container>
                    <!-- to Column -->
                    <ng-container matColumnDef="sum">
                        <th mat-header-cell *matHeaderCellDef> {{'commission' | translate}} </th>
                        <td mat-cell *matCellDef="let element"> {{element.sum | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
        </ng-container>


        <ng-container>           
            <div fxLayout="column" fxLayoutAlign="none">
               <p>{{'percent' | translate}}</p>  
                <table mat-table [dataSource]="uppersPercent" class="mat-elevation-z0">

                    <!-- from Column -->
                    <ng-container matColumnDef="from">
                        <th mat-header-cell *matHeaderCellDef> {{'from' | translate}} </th>
                        <td mat-cell *matCellDef="let element"> {{element.from | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}
                        </td>
                    </ng-container>
                    <!-- to Column -->
                    <ng-container matColumnDef="to">
                        <th mat-header-cell *matHeaderCellDef> {{'to' | translate}} </th>
                        <td mat-cell *matCellDef="let element"> {{element.to | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}
                        </td>
                    </ng-container>
                    <!-- to Column -->
                    <ng-container matColumnDef="sum">
                        <th mat-header-cell *matHeaderCellDef> {{'commission' | translate}} </th>
                        <td mat-cell *matCellDef="let element"> {{element.sum}}%
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
        </ng-container>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>{{'close'| translate}}</button>
</mat-dialog-actions>