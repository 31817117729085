import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { IService } from 'src/app/interfaces/iservice';
import { AuthService } from 'src/app/services/auth.service';
import { ExportService } from 'src/app/services/export.service';
import { ServicesService } from 'src/app/services/services.service';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.css']
})
export class ServiceComponent implements OnInit {
  
  services: IService[] = [];
  displayedColumns: string[] = ['alias', 'id', 'isAvailableInPaynet', 'updatedAt', 'enabled'];
  dataSource: IService[] = [];

  selectedService = new FormControl();
  isPaynetFixed = new FormControl(-1);
  isUkassaFixed = new FormControl(-1);
  public serviceAutoComplete$: Observable<any> = null;
  columnDefinitionsForExcel = [];

  constructor(public authService: AuthService,
    public servicesSrv: ServicesService,
    public snackBar: MatSnackBar,
    private translate: TranslateService,
    private exportSrv: ExportService
  ) { }

  ngOnInit(): void {
    this.load();

    this.serviceAutoComplete$ = this.selectedService.valueChanges.pipe(
      startWith(''),
      // delay emits
      debounceTime(300),
      // use switch map so as to cancel previous subscribed events, before creating new once
      switchMap(value => {
        if (value !== '') {
          // lookup from services
          return this.lookup(value);
        } else {
          // if no value is pressent, return null
          return of(null);
        }
      })
    );
  }

  load() {
    this.getServices();
  }

  lookup(value: any): Observable<any> { 
    if (typeof value === 'object')
    {
      value = value.alias;
    } 

    return this.servicesSrv.searchAll(value.toLowerCase()).pipe(
        // map the item property of the results as our return object
        map(results => results),
        // catch errors
        catchError(_ => {
          return of(null);
        })
      );
   }
  
  getServices() {
    this.columnDefinitionsForExcel = [];
    this.servicesSrv.get(this.isPaynetFixed.value, this.isUkassaFixed.value, this.selectedService?.value?.id).subscribe(
      (data: IService[]) => {
        this.services = data;
        this.dataSource = this.services.sort((a,b) => a.serviceId-b.serviceId);
        if(this.dataSource[0]){
          this.columnDefinitionsForExcel = this.exportSrv.getAllColumnsExcel(Object.keys(this.dataSource[0]));
        }
      },
      error => console.log(error)
    )
  }


  // Disable or Enable service
  update(e, item: any): void {
    this.servicesSrv.update(item.id, !item.enabled).subscribe(
      (data: any) => {
        if (data == true){
          this.getServices();
        }
        else{
          console.log("error");
        }
      },
      error => {
        e.source.checked = false;
        if (error.status === 400) {
          let message = this.translate.instant('check-lower-upper-commissions');
          this.openSnackBar(message, 'x');
        }
        else if (error.status === 500) {
          let message = this.translate.instant('service-is-temporarily-unavailable');
          this.openSnackBar(message, 'x');
        }
        else {
          let message = this.translate.instant('an-error-occurred-while-processing');
          this.openSnackBar(message, 'x');
        }
      }
    )
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  exportToExcel(){
    this.exportSrv.exportToExcel(this.dataSource, 'Report', 'filename.xlsx', this.columnDefinitionsForExcel);
  }

  displayFn(service: IService): string {
    return service && service.alias ? service.alias : '';
  }

  clearSearch(){
    this.selectedService.setValue("");
    this.getServices();
  }

}
