import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { AgentService } from 'src/app/services/agent.service';
import { TariffsService } from 'src/app/services/tariffs.service';

@Component({
  selector: 'app-upper-commissions-edit',
  templateUrl: './upper-commissions-edit.component.html',
  styleUrls: ['./upper-commissions-edit.component.css']
})
export class UpperCommissionsEditComponent implements OnInit {

  numberPatern = '^[0-9.,]+$';
  upperForm: FormGroup = this.fb.group(
                              {
                                minFix: 0, 
                                maxFix: 0,
                                minPercent: 0, 
                                maxPercent: 0,
                                fix: [],
                                percent: [],
                                isReadonly: false
                          });
  items: FormArray;
  services = [];
  upperArray: any[] = [];
  isCompleted: boolean = false;
  
  constructor(@Inject(MAT_DIALOG_DATA) public item: any,
            private fb: FormBuilder,
            private translate: TranslateService,
            public snackBar: MatSnackBar,
            private tariffsSrv: TariffsService,
            private agentSrv: AgentService,
            public dialog: MatDialog) {
  }

  ngOnInit(): void {
      this.getUpperCommission(); 
  }

  getUpperCommission() {
    this.tariffsSrv.upperCommissionsByServiceAgent(this.item.service.serviceId, this.item.agentId?.code).subscribe(
      data => {
          this.upperForm = this.fb.group(
            {
              minFix: [Number(data?.minFix || 0), [Validators.required, Validators.min(data?.defaultMinFix || 0), (control: AbstractControl) => Validators.max(this.upperForm.controls['maxFix'].value)(control)]], 
              maxFix: [Number(data?.maxFix || 0), [Validators.required, Validators.min(data?.defaultMinFix || 0), Validators.max(data?.defaultMaxFix || 490000)]],
              minPercent: [Number(data?.minPercent || 0), [Validators.required, Validators.min(data?.defaultMinPercent || 0), (control: AbstractControl) => Validators.max(this.upperForm.controls['maxPercent'].value)(control)]], 
              maxPercent: [Number(data?.maxPercent || 0), [Validators.required, Validators.min(data?.defaultMinPercent || 0), Validators.max(data?.defaultMaxPercent || 100)]],
              fix: this.fb.array(this.getCommissionValues(data?.fix ?? [], 'fix')),
              percent: this.fb.array(this.getCommissionValues(data?.percent ?? [], 'percent')),
              isReadonly: [data?.isReadonly ?? false, Validators.required],
            }
          );

          this.upperForm.controls['minFix'].markAsTouched();
          this.upperForm.controls['maxFix'].markAsTouched();
          this.upperForm.controls['minPercent'].markAsTouched();
          this.upperForm.controls['maxPercent'].markAsTouched();
          this.upperForm.get("fix").markAllAsTouched();
          this.upperForm.get("percent").markAllAsTouched();
      },
      error => {
        let message = this.translate.instant('an-error-occurred-while-processing');
        this.openSnackBar(message, 'x');
      }
    ).add(() => { this.isCompleted = true; });
  }

  getCommissionValues(json, commission_type){
    if(json && json.length > 0){
        if(commission_type == "percent"){
            return json.map(jsonItem => this.fb.group({
                from: [Number(jsonItem.from), [Validators.required, Validators.pattern(this.numberPatern)]],
                to: [Number(jsonItem.to), [Validators.required, Validators.pattern(this.numberPatern)]],
                sum: [Number(jsonItem.sum), [Validators.required, Validators.pattern(this.numberPatern), (control: AbstractControl) => Validators.min(this.upperForm.controls['minPercent'].value)(control), (control: AbstractControl) => Validators.max(this.upperForm.controls['maxPercent'].value)(control)]]
            }));
        }
        else{
          return json.map(jsonItem => this.fb.group({
                from: [Number(jsonItem.from), [Validators.required, Validators.pattern(this.numberPatern)]],
                to: [Number(jsonItem.to), [Validators.required, Validators.pattern(this.numberPatern)]],
                sum: [Number(jsonItem.sum), [Validators.required, Validators.pattern(this.numberPatern), (control: AbstractControl) => Validators.min(this.upperForm.controls['minFix'].value)(control), (control: AbstractControl) => Validators.max(this.upperForm.controls['maxFix'].value)(control)]]
          }));
        }
    }
    else{
      return [];
    }
  }

  createItem(commission_type): FormGroup {
    if(commission_type == 'percent'){
        return this.fb.group({
          from: [0, [Validators.required,Validators.pattern(this.numberPatern)]],
          to: [0, [Validators.required, Validators.pattern(this.numberPatern)]],
          sum: [0, [Validators.required, Validators.pattern(this.numberPatern), (control: AbstractControl) => Validators.min(this.upperForm.controls['minPercent'].value)(control), (control: AbstractControl) => Validators.max(this.upperForm.controls['maxPercent'].value)(control)]]
      });
    }
    else{
      return this.fb.group({
          from: [0, [Validators.required, Validators.pattern(this.numberPatern)]],
          to: [0, [Validators.required, Validators.pattern(this.numberPatern)]],
          sum: [0, [Validators.required, Validators.pattern(this.numberPatern), (control: AbstractControl) => Validators.min(this.upperForm.controls['minFix'].value)(control), (control: AbstractControl) => Validators.max(this.upperForm.controls['maxFix'].value)(control)]]
      });
    }
  }
  
  addItemFix(){
    this.items = this.upperForm.get('fix') as FormArray;
    this.items.push(this.createItem('fix'));
    this.upperForm.get("fix").markAllAsTouched();
    return false;
  }

  removeItemFix(i){
      const control = <FormArray>this.upperForm.controls['fix'];
      control.removeAt(i);
      return false;
  }

  clearAllFix() {
    const control = <FormArray>this.upperForm.controls['fix'];
    while (control.length) {
      control.removeAt(control.length - 1);
    }
    control.clearValidators();
  };
 
  addItemPercent(){
    this.items = this.upperForm.get('percent') as FormArray;
    this.items.push(this.createItem('percent'));
    this.upperForm.get("percent").markAllAsTouched();
    return false;
  }

  removeItemPercent(i){
    const control = <FormArray>this.upperForm.controls['percent'];
    control.removeAt(i);
    return false;
  }

  clearAllPercent() {
    const control = <FormArray>this.upperForm.controls['percent'];
    while (control.length) {
      control.removeAt(control.length - 1);
    }
    control.clearValidators();
  };

  get f() { return this.upperForm.controls; }
  public hasError = (controlName: string, errorName: string) => {
    return this.upperForm.controls[controlName].hasError(errorName);
  }

  public update = (form) => {
    if (this.upperForm.valid) {
      this.execute(form);
    }
  }

  private execute = (data) => {

    let json: any = {
      agentId: this.item.agentId?.code,
      minFix: Number(data.minFix),
      maxFix: Number(data.maxFix),
      minPercent: Number(data.minPercent),
      maxPercent: Number(data.maxPercent),
      fix: data.fix,
      percent: data.percent,
      isReadonly: data.isReadonly
    }

     this.tariffsSrv.updateUpperCommissionsByService(json, this.item.service.serviceId)
      .subscribe(
        data => {
            const dialogRef = this.dialog.closeAll();
            let message = this.translate.instant('upper-commissions-updated');
            this.openSnackBar(message, 'x');
        },
        error => {
          if (error.status === 409) {
            let message = this.translate.instant('an-error-occurred-while-processing');
            this.openSnackBar(message, 'x');
          }
          else
            if (error.status === 400) {
              let message = this.translate.instant('incorrect-upper-commission-values');
              this.openSnackBar(message, 'x');
            }
            else
              if (error.status === 500) {
                let message = this.translate.instant('service-is-temporarily-unavailable');
                this.openSnackBar(message, 'x');
              }
              else {
                let message = this.translate.instant('an-error-occurred-while-processing');
                this.openSnackBar(message, 'x');
              }
        }
      );
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  sumHasErrorRequired(upper_type, i) : boolean {
      let items = this.upperForm.get(upper_type) as FormArray;
      return items.controls[i]['controls']['sum'].hasError("required");
  }

  sumHasErrorMin(upper_type, i) : boolean {
      let items = this.upperForm.get(upper_type) as FormArray;
      return items.controls[i]['controls']['sum'].hasError("min");
  }

  sumHasErrorMax(upper_type, i) : boolean {
      let items = this.upperForm.get(upper_type) as FormArray;
      return items.controls[i]['controls']['sum'].hasError("max");
  }

  updateFormValidation(upper_type){
    let items = this.upperForm.get(upper_type) as FormArray;
    items.controls.forEach(element => {
        element['controls']['sum'].updateValueAndValidity();
    });

    this.upperForm.controls['minFix'].updateValueAndValidity();
    this.upperForm.controls['maxFix'].updateValueAndValidity();
    this.upperForm.controls['minPercent'].updateValueAndValidity();
    this.upperForm.controls['minPercent'].updateValueAndValidity();
  }

}
