import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TransactionsService {

  host: string;

  constructor(private http: HttpClient) {
    this.host = environment.api_url + '/api/transactions'
  }

  get(transactionId): Observable<any> {
    let url = this.host + '/' + transactionId;
    return this.http.get<any[]>(url);
  };

  getRefillCode(): Observable<any> {
    const url = this.host + '/one-time-code';
    return this.http.get<any>(url);
  }

  send(email: string, transactionId: number ): Observable<any> {
    let json =
    {
      transactionId: transactionId,
      email: email
    }
    let url = environment.api_kassa_url + '/check';
    return this.http.post(url, json);
  }

  sendToWhatsapp(phone: string, transactionId: number): Observable<any> {
    let url = environment.api_kassa_url + '/check/send-whatsapp';
    let json =
    {
      transactionId: transactionId,
      phone: phone
    }
    return this.http.post(url, json);
  }
}
