import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IRefund } from '../interfaces/irefund';

@Injectable({
  providedIn: 'root'
})
export class RefundsService {

  host: string;

  constructor(private http: HttpClient) {
    this.host = environment.api_url + '/api/refunds'
  }

  get(id: number, statusId: number, dateFrom: Date,  dateTo: Date): Observable<IRefund[]> {
    let url = this.host;
    let params = new HttpParams();
    if (id != null) {
      params = params.append('id', id.toString());
    }
    params = params.append('statusId', statusId.toString());

    if (dateFrom != null && dateFrom != undefined) {
      params = params.append('dateFrom', moment(dateFrom).format("YYYY-MM-DDT00:00:00.000") + "Z");
    }
    if (dateTo != null && dateTo != undefined) {
      params = params.append('dateTo', moment(dateTo).format("YYYY-MM-DDT23:59:59.000") + "Z");
    }
    return this.http.get<IRefund[]>(url, { params: params });
  }

  refund(data: any, id: number): Observable<any> {
    let url = this.host + "/" + id + "/refund";
    return this.http.post(url, data);
  }
  
}
