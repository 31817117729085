<div fxLayout="column" fxLayoutAlign="center center">
    <h1 class="mat-headline">{{'region-report'| translate}}</h1>
</div>

<div class="container" fxLayoutGap="10px"
    fxLayoutGap.xs="0">
    <div fxLayout="row" fxFill>
        <p>{{"region-report-search-btn-warning" | translate}}</p>
    </div>

    <div fxLayout fxLayout.xs="column" fxLayoutAlign="center center">
        
        <mat-form-field fxFlex="40">
            <input type="text" placeholder="{{'agent'| translate}}" matInput [formControl]="agentId"
                [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" autoActiveFirstOption>
                <mat-option *ngFor="let option of agentAutoComplete$ | async; let index = index" [value]="option">
                    {{option.title}}
                </mat-option>
            </mat-autocomplete>
            <button *ngIf="agentId != null" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>

        <mat-form-field fxFlex="40">
            <input type="text" placeholder="{{'selectService'| translate}}" matInput [formControl]="selectedService"
                [matAutocomplete]="auto2">
            <mat-autocomplete #auto2="matAutocomplete" [displayWith]="displayFnService" autoActiveFirstOption>
                <mat-option *ngFor="let service of serviceAutoComplete$ | async; let index = index" [value]="service">
                    {{service.alias}}
                </mat-option>
            </mat-autocomplete>
            <button *ngIf="selectedService != null" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="clearSearchService()">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>

        <mat-form-field fxFlex="40">
            <mat-select placeholder="{{'agent-city' | translate}}" [formControl]="cityId">
                <mat-option [value]="">
                    {{ 'choose-city' | translate}}
                    </mat-option>
                <mat-option *ngFor="let item of cities" [value]="item.code">
                {{ item.title }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{'dateFrom' | translate}}</mat-label>
            <input matInput [matDatepicker]="pickerFrom" (click)="pickerFrom.open()" [formControl]="dateFrom"
                placeholder="dateFrom" readonly>
            <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
            <mat-datepicker #pickerFrom></mat-datepicker>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{'dateTo' | translate}}</mat-label>
            <input matInput [matDatepicker]="pickerTo" (click)="pickerTo.open()" [formControl]="dateTo"
                placeholder="{{'dateFrom'| translate}}" readonly>
            <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
            <mat-datepicker #pickerTo></mat-datepicker>
        </mat-form-field>

        <button mat-icon-button color="primary" (click)="getReport(0)" [disabled]="!isCompleted">
            <mat-icon>search</mat-icon>
        </button>

        <button mat-icon-button color="primary" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="mainMenuButtonExcel" [disabled]="dataSource?.length < 1">
            <mat-icon>save_alt</mat-icon>
        </button>
        <mat-menu #mainMenuButtonExcel="matMenu">
            <div class="table-column-list-block">
                <section class="button-download">
                    <button mat-raised-button color="primary"  (click)="exportToExcel()" >
                        {{'export' | translate}}
                        <mat-icon>save_alt</mat-icon>
                    </button>   
                </section>
                <span (click)="$event.stopPropagation()" *ngFor="let element of columnDefinitionsForExcel;index as i">
                    <mat-slide-toggle color="primary" (change)="element.isShow = !element.isShow" [checked]="element.isShow == true"></mat-slide-toggle>
                    {{element.label}}
                    <br>
                </span>
            </div>
        </mat-menu>
    </div>
</div>
<mat-progress-bar mode="indeterminate" *ngIf="!isCompleted"></mat-progress-bar>
<div fxLayout="column" fxLayoutAlign="none">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z0 region-report-table">

        <!-- agentName Column -->
        <ng-container matColumnDef="agentName">
            <th mat-header-cell *matHeaderCellDef> {{'report-agent-name' | translate}} </th>
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element">{{element.lName }} {{element.fName ? element.fName : element.agentName }} </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

         <!-- cityName Column -->
         <ng-container matColumnDef="cityName">
            <th mat-header-cell *matHeaderCellDef> {{'report-city-name' | translate}} </th>
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element"> {{element.cityName }} </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <!-- transactionsCount Column -->
        <ng-container matColumnDef="transactionsCount">
            <th mat-header-cell *matHeaderCellDef> {{'successTransactionCount' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.transactionsCount}} </td>
            <td mat-footer-cell *matFooterCellDef>{{getTotalSuccesTransaction()}} </td>
        </ng-container>
        
         <!-- canceledCount Column -->
         <ng-container matColumnDef="canceledCount">
            <th mat-header-cell *matHeaderCellDef> {{'canceledTransactionCount' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.canceledCount}} </td>
            <td mat-footer-cell *matFooterCellDef>{{getTotalCanceledTransaction()}} </td>
        </ng-container>

        <!-- cashFlow Column -->
        <ng-container matColumnDef="cashFlow">
            <th mat-header-cell *matHeaderCellDef> {{'cashFlow' | translate}} </th>
            <td mat-cell *matCellDef="let element">{{element.cashFlow | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}
            </td>
            <td mat-footer-cell *matFooterCellDef>{{getTotalCashFlow() | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </td>
        </ng-container>

        <!-- acceptedAmount Column -->
        <ng-container matColumnDef="acceptedAmount">
            <th mat-header-cell *matHeaderCellDef> {{'success-amount' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.acceptedAmount | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}
            </td>
            <td mat-footer-cell *matFooterCellDef>{{getTotalAcceptedAmount() | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </td>
        </ng-container>

        <!-- canceledAmount Column -->
        <ng-container matColumnDef="canceledAmount">
            <th mat-header-cell *matHeaderCellDef> {{'report-canceled-amount' | translate}}</th>
            <td mat-cell *matCellDef="let element">
                {{element.canceledAmount | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}</td>
            <td mat-footer-cell *matFooterCellDef>{{getTotalCanceledAmount() | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </td>
        </ng-container>

        <!-- upperCommissionAmount Column -->
        <ng-container matColumnDef="upperCommissionAmount">
            <th mat-header-cell *matHeaderCellDef> {{'report-upper-commission-amount' | translate}} <mat-icon color="primary" matTooltip="{{'paid-upper-commissions' | translate: {agent: agentId?.value?.title } }}">contact_support</mat-icon>  </th>
            <td mat-cell *matCellDef="let element"> {{element.upperCommissionAmount | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}
            </td>
            <td mat-footer-cell *matFooterCellDef>{{getTotalUpperCommissionAmount() | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </td>
        </ng-container>
      
        <!-- lowerCommissionIncome Column -->
        <ng-container matColumnDef="lowerCommissionIncome">
            <th mat-header-cell *matHeaderCellDef> {{'report-lower-commission-income-amount' | translate}} <mat-icon color="primary" matTooltip="{{'report-income-lower-commissions' | translate: {agent: agentId?.value?.title } }}">contact_support</mat-icon>  </th>
            <td mat-cell *matCellDef="let element">
                {{element.lowerCommissionIncome | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </td>
            <td mat-footer-cell *matFooterCellDef>{{getTotalLowerCommissionIncome() | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </td>
        </ng-container>

        <!-- lowerCommissionOutcome Column -->
        <ng-container matColumnDef="lowerCommissionOutcome">
            <th mat-header-cell *matHeaderCellDef> {{'report-lower-commission-outcome-amount' | translate}} <mat-icon color="primary" matTooltip="{{'paid-lower-commissions' | translate: {agent: agentId?.value?.title } }}">contact_support</mat-icon>  </th>
            <td mat-cell *matCellDef="let element">
                {{element.lowerCommissionOutcome | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </td>
            <td mat-footer-cell *matFooterCellDef>{{getTotalLowerCommissionOutcome() | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </td>
        </ng-container>

        <!-- canceledLowerCommission Column -->
        <ng-container matColumnDef="canceledLowerCommission">
            <th mat-header-cell *matHeaderCellDef> {{'canceled-lower-commission-amount' | translate}} <mat-icon color="primary" matTooltip="{{'canceled-lower-commissions' | translate: {agent: agentId?.value?.title } }}">contact_support</mat-icon>  </th>
            <td mat-cell *matCellDef="let element">
                {{element.canceledLowerCommission | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </td>
            <td mat-footer-cell *matFooterCellDef>{{getTotalCanceledLowerCommission() | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </td>
        </ng-container>

        <!-- itoCommissionIncome Column -->
        <ng-container matColumnDef="itoCommissionIncome">
            <th mat-header-cell *matHeaderCellDef> {{'report-ito-commission-income-amount' | translate}} <mat-icon color="primary" matTooltip="{{'report-income-ito-commissions' | translate: {agent: agentId?.value?.title } }}">contact_support</mat-icon>  </th>
            <td mat-cell *matCellDef="let element">
                {{element.itoCommissionIncome | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </td>
            <td mat-footer-cell *matFooterCellDef>{{getTotalItoCommissionIncome() | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </td>
        </ng-container>
        
        <!-- itoCommissionOutcome Column -->
         <ng-container matColumnDef="itoCommissionOutcome">
            <th mat-header-cell *matHeaderCellDef> {{'report-ito-commission-outcome-amount' | translate}} <mat-icon color="primary" matTooltip="{{'report-outcome-ito-commissions' | translate: {agent: agentId?.value?.title } }}">contact_support</mat-icon>  </th>
            <td mat-cell *matCellDef="let element">
                {{element.itoCommissionOutcome | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </td>
            <td mat-footer-cell *matFooterCellDef>{{getTotalItoCommissionOutcome() | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </td>
        </ng-container>

        <!-- expectedLowerCommissionDelta Column -->
        <ng-container matColumnDef="expectedLowerCommissionDelta">
            <th mat-header-cell *matHeaderCellDef> {{'report-expected-lower-commission-delta' | translate}} <mat-icon color="primary" matTooltip="{{'expected-delta-lower-commissions' | translate: {agent: agentId?.value?.title } }}">contact_support</mat-icon> </th>
            <td mat-cell *matCellDef="let element">
                {{element.expectedLowerCommissionDelta | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}</td>
            <td mat-footer-cell *matFooterCellDef>{{getTotalExpectedLowerCommissionDelta() | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </td>
        </ng-container>

        <!-- lowerCommissionDelta Column -->
        <ng-container matColumnDef="lowerCommissionDelta">
            <th mat-header-cell *matHeaderCellDef> {{'report-lower-commission-delta' | translate}} <mat-icon color="primary" matTooltip="{{'delta-lower-commissions' | translate: {agent: agentId?.value?.title } }}">contact_support</mat-icon> </th>
            <td mat-cell *matCellDef="let element">
                {{element.lowerCommissionDelta | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}</td>
            <td mat-footer-cell *matFooterCellDef>{{getTotalLowerCommissionDelta() | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </td>
        </ng-container>

        <!-- itoDelta Column -->
        <ng-container matColumnDef="itoDelta">
            <th mat-header-cell *matHeaderCellDef> {{'report-ito-delta' | translate}}  <mat-icon color="primary" matTooltip="{{'paid-ito-commissions' | translate: {agent: agentId?.value?.title } }}">contact_support</mat-icon> </th>
            <td mat-cell *matCellDef="let element">
                {{element.itoDelta | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}</td>
            <td mat-footer-cell *matFooterCellDef>{{getTotalITODelta() | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </td>
        </ng-container>

        <!-- grSum Column -->
        <ng-container matColumnDef="grSum">
            <th mat-header-cell *matHeaderCellDef> {{'report-gr-sum' | translate}} <mat-icon color="primary" matTooltip="{{'gr-sum' | translate: {agent: agentId?.value?.title } }}">contact_support</mat-icon></th>
            <td mat-cell *matCellDef="let element">
                {{element.grSum | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}</td>
            <td mat-footer-cell *matFooterCellDef>{{getTotalGRSum() | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
    </table>
</div>