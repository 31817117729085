import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ConsolidatedComponent } from './components/reports/consolidated/consolidated.component';
import { ConsolidatedExtendedComponent } from './components/reports/consolidated-extended/consolidated-extended.component';
import { AccountBalanceComponent } from './components/reports/account-balance/account-balance.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './helpers/auth.guard';
import { HomeComponent } from './components/home/home.component';
import { ProfilesComponent } from './components/reports/profiles/profiles.component';
import { UnauthorizedComponent } from './components/pages/unauthorized/unauthorized.component';
import { IdleProfilesComponent } from './components/reports/idle-profiles/idle-profiles.component';
import { CategoryComponent } from './components/settings/category/category.component';
import { ServiceComponent } from './components/settings/service/service.component';
import { ProvidersReportComponent } from './components/reports/providers-report/providers-report.component';
import { IncomeReportComponent } from './components/reports/income-report/income-report.component';
import { AgentsComponent } from './components/settings/agents/agents.component';
import { RatesComponent } from './components/rates/rates.component';
import { AllRatesComponent } from './components/all-rates/all-rates.component';
import { PaidLowerCommissionReportComponent } from './components/reports/paid-lower-commission-report/paid-lower-commission-report.component';
import { CurrentBalanceComponent } from './components/reports/current-balance/current-balance.component';
import { TransactionsComponent } from './components/reports/transactions/transactions.component';
import { AgentsReportComponent } from './components/reports/agents-report/agents-report.component';
import { RecoveryComponent } from './components/user/recovery/recovery.component';
import { ResetPasswordComponent } from './components/user/reset-password/reset-password.component';
import { PagesComponent } from './components/settings/pages/pages.component';
import { RegionReportComponent } from './components/reports/region-report/region-report.component';
import { TariffsComponent } from './components/settings/tariff/tariffs/tariffs.component';
import { EditTariffComponent } from './components/settings/tariff/edit-tariff/edit-tariff/edit-tariff.component';
import { AddTariffComponent } from './components/settings/tariff/add-tariff/add-tariff/add-tariff.component';
import { UpperCommissionsComponent as UpperCommissionsList } from './components/settings/upper-commissions/upper-commissions.component';
import { NewsComponent } from './components/settings/news/news.component';
import { AgentsTreeComponent } from './components/settings/agents/agents-tree/agents-tree.component';
 import { WhatsappReportComponent } from './components/reports/whatsapp-report/whatsapp-report.component';
import { RefundRequestsComponent } from './components/refund-requests/refund-requests.component';
import { LowerCommissionPayComponent } from './components/settings/lower-commission-pay/lower-commission-pay.component';


const routes: Routes = [   
  { path: '', component: HomeComponent, canActivate: [AuthGuard], data: {title:'home'}},
  { path: 'login', component: LoginComponent, data: {title:'login'}},
  { path: 'recovery', component: RecoveryComponent, data: { title: 'recovery' } },
  { path: 'resetpassword', component: ResetPasswordComponent, data: { title: 'reset-password' } },
  { path: 'unauthorized', component: UnauthorizedComponent, data: {title:'unauthorized'}},
  
  {path:'reports/current-balance', component: CurrentBalanceComponent,  canActivate: [AuthGuard], data: {title:'current-balance'}},
  {path:'reports/account-balance', component: AccountBalanceComponent,  canActivate: [AuthGuard], data: {title:'account-balance'}},
  {path:'reports/consolidated-report', component: ConsolidatedComponent, canActivate: [AuthGuard], data: {title:'consolidated-report'}},
  {path:'reports/consolidated-report-extended', component: ConsolidatedExtendedComponent, canActivate: [AuthGuard], data: {title:'consolidated-report-extended'}}, 
  {path:'reports/providers-report', component: ProvidersReportComponent, canActivate: [AuthGuard], data: {title:'providers-report'}},
  {path:'reports/income-report', component: IncomeReportComponent, canActivate: [AuthGuard], data: {title:'income-report'}},
  {path:'reports/paid-lower-commission-report', component: PaidLowerCommissionReportComponent , canActivate: [AuthGuard], data: {title:'paid-lower-commission'}},   
  {path:'reports/transactions-report', component: TransactionsComponent, canActivate: [AuthGuard], data: {title:'transactions-report'}},
  {path:'reports/agents-report', component: AgentsReportComponent, canActivate: [AuthGuard], data: {title:'agents-report'}},
  {path:'reports/region-report', component: RegionReportComponent, canActivate: [AuthGuard], data: {title:'region-report'}},
  {path:'reports/region-report/:mode', component: RegionReportComponent, canActivate: [AuthGuard], data: {title:'region-report'}},
  {path:'reports/whatsapp-report', component: WhatsappReportComponent, canActivate: [AuthGuard], data: {title:'whatsapp-report'}},
  
  {path:'reports/profiles-report', component: ProfilesComponent, canActivate: [AuthGuard], data: {title:'profiles-report'}}, 
  {path:'reports/idle-profiles-report', component: IdleProfilesComponent, canActivate: [AuthGuard], data: {title:'idle-profiles-report'}},   

  {path:'settings/category', component: CategoryComponent, canActivate: [AuthGuard], data: {title:'categories-settings'}},   
  {path:'settings/service', component: ServiceComponent , canActivate: [AuthGuard], data: {title:'services-settings'}},   
  {path:'settings/agents', component: AgentsComponent , canActivate: [AuthGuard], data: {title:'agents-settings'}},   
  {path:'settings/pages', component: PagesComponent, canActivate: [AuthGuard], data: {title:'pages-settings'}},   
  
  {path:'rates', component: RatesComponent , canActivate: [AuthGuard], data: {title:'tariff-rates'}},   
  {path:'all-rates', component: AllRatesComponent , canActivate: [AuthGuard], data: {title:'tariff-all-rates'}},   
  {path:'settings/tariffs', component: TariffsComponent , canActivate: [AuthGuard], data: {title:'tariffs'}},   
  {path:'settings/edit-tariff/:id', component: EditTariffComponent , canActivate: [AuthGuard], data: {title:'edit-tariff'}},   
  {path:'settings/add-tariff/:id', component: AddTariffComponent , canActivate: [AuthGuard], data: {title:'add-tariff'}},   
  {path:'settings/upper-commissions', component: UpperCommissionsList, canActivate: [AuthGuard], data: {title:'manager-upper-commissions'}},
  {path:'settings/news', component:NewsComponent, canActivate:[AuthGuard], data: {title:'news'}},
  {path:'settings/agents-tree', component:AgentsTreeComponent, canActivate:[AuthGuard], data: {title:'agents-tree'}},
  {path:'settings/refund-requests', component:RefundRequestsComponent, canActivate:[AuthGuard], data: {title:'refund-requets'}},
  {path:'settings/lower-commissions-pay', component:LowerCommissionPayComponent, canActivate:[AuthGuard], data: {title:'lower-commissions-pay'}}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
