import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ITariffs } from 'src/app/interfaces/itariffs';
import { AuthService } from 'src/app/services/auth.service';
import { TariffsService } from 'src/app/services/tariffs.service';
import { ViewTariffComponent } from '../view-tariff/view-tariff.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-tariffs',
  templateUrl: './tariffs.component.html',
  styleUrls: ['./tariffs.component.css']
})
export class TariffsComponent implements OnInit {

  tariffs: ITariffs[] = [];
  displayedColumns: string[] = ['name', 'id', 'weight', 'basic', 'creator', 'created', 'terminalId', 'isDeleted', 'action'];
  dataSource: ITariffs[] = [];
  new_data = [];

  constructor(public authService: AuthService,
    public tariffsSrv: TariffsService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.getTariffs();
  }

  getTariffs() {
    this.tariffsSrv.getTariffsTree().subscribe(
      (data: ITariffs[]) => {
        this.tariffs = data.sort((a,b) => b.weight - a.weight);
        this.get_children(data,1);

        this.dataSource = this.new_data;
      },
      error => console.log(error)
    )
  }

  get_children(data,level){
    var i=0
    while(i<data.length)
    {
      var current_parent = data[i];
      current_parent.level = level;

      this.new_data.push(current_parent)
      if(current_parent.tariffsChild.length < 0){

      }
      else if(current_parent.tariffsChild.length > 0){
        var next_level = current_parent.level + 1;
        this.get_children(current_parent.tariffsChild, next_level);
      }
      i++
    }
  }

  // View tariff commission dialog
  openTariffViewDialog(item: any) {
    const dialogRef = this.dialog.open(ViewTariffComponent,
      { data: { tariff: item },
      autoFocus: false,
      maxHeight: '95vh'
     });
  }

  onClickDeleteTariff(tariffId: number, isTariffBasic: boolean, fname: string, lname: string){
    if (isTariffBasic === true){
      const message = this.translate.instant('delete-basic-tariff-error');
      this.openSnackBar(message, 'x');
    }
    else if (fname !== null || lname !== null){
      const message = this.translate.instant('delete-empty-author-tariff-error');
      this.openSnackBar(message, 'x');
    }
    else{
      this.tariffsSrv.deleteTariff(tariffId).subscribe( data => {
        const message = this.translate.instant('delete-tariff-success');
        this.openSnackBar(message, 'x');
        this.reloadPage();
      }, error => {
        console.log(error);
        const message = this.translate.instant('delete-tariff-error');
        this.openSnackBar(message, 'x');
      });
    }
  }

  onClickRestoreTariff(tariffId: number){
    this.tariffsSrv.restoreTariff(tariffId).subscribe( data => {
      const message = this.translate.instant('restore-tariff-success');
      this.openSnackBar(message, 'x');
      this.reloadPage();
    }, error => {
      console.log(error);
      const message = this.translate.instant('restore-tariff-error');
      this.openSnackBar(message, 'x');
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  reloadPage(){
    setTimeout(() => window.location.reload(), 3000);
  }
}
