<mat-toolbar color="primary">
    <mat-toolbar-row>
        <button mat-icon-button>
            <mat-icon (click)="sidenav.toggle()">menu</mat-icon>
        </button>
        <!--   <img src="assets/img/logo.png" alt="Yukassa" height="36" /> -->
        <span>{{'cashbox-backoffice' | translate}}</span>
        <span class="menu-spacer"></span>
        <span class="toolbar-filler"></span>

        <div>
            <a mat-button *ngIf="isAuthenticated" [routerLink]="'/'"> <span class="material-icons">
                    account_circle
                </span> 
                <span style="padding-left: 20px;">
                    {{currentUser?.name}} 
                </span>
            </a>
            <button mat-button routerLink="/login" *ngIf="!isAuthenticated">
                <mat-icon>login</mat-icon>
            </button>
            <button mat-button *ngIf="isAuthenticated" (click)="logout()">
                <mat-icon>exit_to_app</mat-icon>
            </button>
        </div>

    </mat-toolbar-row>
</mat-toolbar>
<mat-sidenav-container>
    <mat-sidenav #sidenav>
        <mat-nav-list density>
            <div *ngIf="isAuthenticated">
                <div *ngIf="authService.isAdmin || authService.isManager || authService.isAccountant">
                    <div mat-subheader>{{'reports' | translate}}</div>
                    <a mat-list-item (click)="sidenav.toggle()"
                        [routerLink]="'/reports/current-balance'">{{'current-balance-report' | translate}} </a>
                    <a mat-list-item (click)="sidenav.toggle()"
                        [routerLink]="'/reports/account-balance'">{{'account-balance-report' | translate}} </a>
                    <a mat-list-item (click)="sidenav.toggle()" [routerLink]="'/reports/consolidated-report'">
                        {{'balanceSummary' | translate}} </a>
                    <a mat-list-item (click)="sidenav.toggle()" [routerLink]="'/reports/consolidated-report-extended'">
                        {{'consolidated-report'| translate}} </a>
                    <a mat-list-item (click)="sidenav.toggle()" [routerLink]="'/reports/providers-report'">
                            {{'providers-report'| translate}} </a>
                    <a mat-list-item (click)="sidenav.toggle()" [routerLink]="'/reports/income-report'">
                                {{'income-report'| translate}} </a>
                    <a mat-list-item (click)="sidenav.toggle()" [routerLink]="'/reports/transactions-report'">{{'transactions-report' | translate}} </a>
                    <a mat-list-item (click)="sidenav.toggle()" [routerLink]="'/reports/paid-lower-commission-report'">{{'paid-lower-commission-report' | translate}} </a>
                    <a mat-list-item (click)="sidenav.toggle()" [routerLink]="'/reports/agents-report'">{{'agents-report' | translate}} </a>
                    <a mat-list-item (click)="sidenav.toggle()" [routerLink]="'/reports/region-report'">{{'region-report' | translate}} </a>
                    <a mat-list-item (click)="sidenav.toggle()" [routerLink]="'/reports/region-report/beta'">{{'region-report' | translate}} (BETA)</a>
                    <a mat-list-item (click)="sidenav.toggle()" [routerLink]="'/reports/whatsapp-report'">{{'whatsapp-report' | translate}} </a>
                    <!-- <mat-divider></mat-divider>
                    <div mat-subheader>{{'operation-reports' | translate}}</div>
                    <a mat-list-item (click)="sidenav.toggle()"
                        [routerLink]="'/reports/profiles-report'">{{'profiles-report' | translate}} </a>
                        <a mat-list-item (click)="sidenav.toggle()"
                        [routerLink]="'/reports/idle-profiles-report'">{{'idle-profiles-report' | translate}} </a> -->
                </div>
                
                <div *ngIf="authService.isAdmin || authService.isAccountant">
                    <mat-divider></mat-divider>
                    <div mat-subheader>{{'settings' | translate}}</div>
                    
                    <div *ngIf="authService.isAdmin">
                        <a mat-list-item (click)="sidenav.toggle()"
                        [routerLink]="'/settings/category'">{{'categories-settings' | translate}} </a>           
                        <a mat-list-item (click)="sidenav.toggle()"
                        [routerLink]="'/settings/service'">{{'services-settings' | translate}} </a>
                        <a mat-list-item (click)="sidenav.toggle()"
                        [routerLink]="'/settings/news'">{{'settings-news' | translate}}</a>              
                    </div>

                    <a mat-list-item (click)="sidenav.toggle()"
                    [routerLink]="'/settings/agents'">{{'agents-settings' | translate}} </a>
                    
                    <a *ngIf="authService.isAccountant" mat-list-item (click)="sidenav.toggle()"
                        [routerLink]="'/settings/lower-commissions-pay'">{{'lower-commission-pay-report' | translate}} </a>

                    <div *ngIf="authService.isAdmin">

                        <a mat-list-item (click)="sidenav.toggle()"
                        [routerLink]="'/settings/pages'">{{'pages-settings' | translate}} </a>
                        <mat-divider></mat-divider>
                        <a mat-list-item (click)="sidenav.toggle()"
                        [routerLink]="'/settings/tariffs'">{{'tariffs' | translate}} </a>
                        <a mat-list-item (click)="sidenav.toggle()"
                        [routerLink]="'/settings/upper-commissions'">{{'manage-upper-commissions' | translate}} </a>
                        <a mat-list-item (click)="sidenav.toggle()"
                        [routerLink]="'/settings/refund-requests'">{{'refund-requests' | translate}} </a>
                        
                        <a mat-list-item (click)="sidenav.toggle()"
                        [routerLink]="'/rates'">{{'services-rates' | translate}} </a>
                        <a mat-list-item (click)="sidenav.toggle()"
                        [routerLink]="'/all-rates'">{{'tariff-all-rates' | translate}} </a>
                    </div>
                </div>
            </div>
            <div mat-subheader>{{'about' | translate}}</div>
            <mat-list-item>
                {{'version'| translate}} {{version}}
            </mat-list-item>
        </mat-nav-list>

    </mat-sidenav>
    <mat-sidenav-content fxFlexFill>
        <div style="height: 88vh;" class="container" #scrollTop>
            <br>
            <router-outlet></router-outlet>
        </div>
        <button class="button-up" *ngIf="visibilityUpButton" (click)="scrollToUp()"><mat-icon>arrow_upward</mat-icon></button>
    </mat-sidenav-content>
</mat-sidenav-container>