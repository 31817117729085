import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { TranslateService } from '@ngx-translate/core';
import { AgentService } from 'src/app/services/agent.service';
import { ILowerCommissionAgents } from 'src/app/interfaces/ilower-commission-agents';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { DynamicButtonComponent } from './dynamic-button/dynamic-button.component';
import * as _moment from 'moment'; 
import { FormControl, Validators } from '@angular/forms';
import { GetMonthList } from 'src/app/helpers/month-list';

@Component({
  providers: [DynamicButtonComponent], 
  selector: 'app-lower-commission-pay',
  templateUrl: './lower-commission-pay.component.html',
  styleUrls: ['./lower-commission-pay.component.css'], 

})
export class LowerCommissionPayComponent implements OnInit {
  
  month = new FormControl(0, [Validators.required, Validators.min(1)]);
  year = new FormControl(0, [Validators.required, Validators.min(1)]);
  
  agents = {};
  selectedMonth = 0;
  selectedYear = 0;

  parentInclude = false;

  currentNodeLevel: ILowerCommissionAgents;

  private _transformer = (node: ILowerCommissionAgents, level: number) => {
    node.expandable = !!node.subs && node.subs.length > 0;
    node.level = level;
    node.isChecked = false;
    return node;
  };

  isCompleted: boolean = false;

  treeControl = new FlatTreeControl<ILowerCommissionAgents>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.subs
  );
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  constructor(
    public agentsSrv: AgentService,
    private translate: TranslateService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public dynamicButton: DynamicButtonComponent
  ) {}

  hasChild = (_: number, node: any) => node.expandable;

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  ngOnInit(): void {
    this.getAgents();
  }

  setAgent(node: ILowerCommissionAgents) {
    if (node.id in this.agents) {
      delete this.agents[node.id];
      return;
    }
    this.agents[node.id] = node;
  }

  setAgentsByTree(node: ILowerCommissionAgents, include: boolean) {
    this.currentNodeLevel = node;
    this.parentInclude = include;
    this.stepForward(node, include);
  }

  stepForward(node: ILowerCommissionAgents, include: boolean) {
    if (node.subs.length > 0) {
      node.subs.forEach((child) => {
        this.stepForward(child, include);
        if (!include) {
          delete this.agents[child.id];
          return;
        } else {
          this.agents[child.id] = child;
          return;
        }
      });
    }
  }

  setButtonStatus(node: ILowerCommissionAgents) {
    if (this.currentNodeLevel != undefined) {
      let length = this.currentNodeLevel.key.length;
      if (
        node.index.length > this.currentNodeLevel.index.length &&
        node.key.substring(0, length) == this.currentNodeLevel.key
      )
      {
        return this.parentInclude;
      }
    }
  }

  setKey(node: ILowerCommissionAgents): void {
    let q = [node];
    while (q.length > 0) {
      for (var i = 0; i < q.length; i++) {
        let temp = q.pop();
        temp.subs.forEach((child, index) => {
          child.index = [...temp.index];
          child.index.push(index);
          child.key = child.index.join('.');
          q.push(child);
        });
      }
    }
  }

  agentCheckboxClick(e: any, node: ILowerCommissionAgents) {
    this.setAgent(node);
    e.checked = this.getAgentCheckedStatus(node);
  }

  getAgentCheckedStatus(node: ILowerCommissionAgents) {
    if (node.id in this.agents) {
      return true;
    }
    return false;
  }

  openConfirmDialog() {
    if (this.year.invalid || this.month.invalid){
        let message = this.translate.instant('date-not-chosen');
        this.openSnackBar(message, 'x');
        return false; 
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: { agents: this.agents, month: this.month.value, year: this.year.value }, 
        maxHeight: '95vh',
    });

    dialogRef.afterClosed().subscribe((result) => {
        if (result == true){
          this.isCompleted = false;
          this.month.setValue(0);
          this.year.setValue(0);
          this.getAgents();  
        }
    });
  }

  getAgents() {
    this.agents = {};

    this.isCompleted = false;
    this.selectedMonth = this.month.value;
    this.selectedYear = this.year.value;

    this.agentsSrv.getAgentsNKTree(this.month.value, this.year.value).subscribe(
        (data: ILowerCommissionAgents[]) => {
          data[0].index = [0];
          data[0].key = "0"
          this.setKey(data[0]);
          this.dataSource.data = data;
        },
        (error) => {
          if (error.status === 404) {
            let message = this.translate.instant('not-found');
            this.openSnackBar(message, 'x');
          } 
          else if (error.status === 400) {
            let message = this.translate.instant('an-error-occurred-while-processing');
            this.openSnackBar(message, 'x');
          } 
          else if (error.status === 500) {
            let message = this.translate.instant('service-is-temporarily-unavailable');
            this.openSnackBar(message, 'x');
          }
           else {
            let message = this.translate.instant('an-error-occurred-while-processing');
            this.openSnackBar(message, 'x');
          }
        }
      )
      .add(() => {
        this.isCompleted = true;
      });
  }

  isEmptyAgentsCount(){
    return (Object.keys(this.agents).length === 0);
  }

  getYear() {
      let years = new Array();
      let currentYear = (new Date()).getFullYear();
      for(let i = 0; i < 5; i++){
        years.push(currentYear-i);
      }
      return years;
  }

  getMonths(){
    return GetMonthList();
  }

  filterAgentsNk(){
    if((this.month.value == 0 && this.year.value == 0) || (this.month.value > 0 && this.year.value > 0)){
      this.getAgents();
    }
    else{
      let message = this.translate.instant('choose-month-and-year');
      this.openSnackBar(message, 'x');
    }
  }
}


