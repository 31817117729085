<div fxLayout="column" fxLayoutAlign="none">
    <div fxLayout="column" fxLayoutAlign="center center" style="margin-top: 30px;">
        <h1 class="mat-headline">{{item.tariff.name}}</h1>
    </div>
    
    <mat-progress-bar mode="indeterminate" *ngIf="!isCompleted"></mat-progress-bar>

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z0">

        <!-- serviceId Column -->
        <ng-container matColumnDef="serviceId">
            <th mat-header-cell *matHeaderCellDef> {{'serviceId' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.serviceId}} </td>
        </ng-container>

        <!-- serviceName Column -->
        <ng-container matColumnDef="serviceName">
            <th mat-header-cell *matHeaderCellDef> {{'alias' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.serviceName }} </td>
        </ng-container>

        <!-- isEnable Column -->
        <ng-container matColumnDef="isEnable">
            <th mat-header-cell *matHeaderCellDef> {{'isEnable' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.isEnable?.toString() | translate}}
            </td>
        </ng-container>

        <!-- itoPercent Column -->
        <ng-container matColumnDef="itoPercent">
            <th mat-header-cell *matHeaderCellDef> {{'itoPercent' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.itoPercent/100}}
            </td>
        </ng-container>
        <!-- itoFix Column -->
        <ng-container matColumnDef="itoFix">
            <th mat-header-cell *matHeaderCellDef> {{'itoFix' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.itoFix/100 | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </td>
        </ng-container>
        <!-- lowerPercent Column -->
        <ng-container matColumnDef="lowerPercent">
            <th mat-header-cell *matHeaderCellDef> {{'lowerPercent' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.lowerPercent/100}}
            </td>
        </ng-container>
        <!-- lowerFix Column -->
        <ng-container matColumnDef="lowerFix">
            <th mat-header-cell *matHeaderCellDef> {{'lowerFix' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.lowerFix/100 | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
        
    <mat-card-actions fxLayoutGap="10px" fxLayoutAlign="end" style="margin-top: 20px;">
        <button mat-flat-button type="button" (click)="closeViewTariffDialog()">{{'close' | translate}}</button>
    </mat-card-actions>
</div>