import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { PagesService } from 'src/app/services/pages.service';

@Component({
  selector: 'app-edit-page',
  templateUrl: './edit-page.component.html',
  styleUrls: ['./edit-page.component.css']
})
export class EditPageComponent implements OnInit {

  isCompleted: boolean = false;
  pagesForm = this.fb.group({
    title: [this.item.pages.title, Validators.required],
    content: "",
    code: [this.item.pages.code, Validators.required],
  });

  constructor(@Inject(MAT_DIALOG_DATA) public item: any,
            private fb: FormBuilder,
            private pagesSrv: PagesService,
            public snackBar: MatSnackBar,
            private translate: TranslateService) { }

  ngOnInit(): void {
    this.getPage();
  }

  getPage(){
    this.isCompleted = false;
    this.pagesSrv.getPage(this.item.pages.code).subscribe(
      (data: any) => {
        this.pagesForm.controls['title'].setValue(data.title);
        this.pagesForm.controls['content'].setValue(data.content);
        this.pagesForm.controls['code'].setValue(data.code);
      },
      error => console.log(error)
    ).add(() => { this.isCompleted = true });
  }

  get f() { return this.pagesForm.controls; }
  public hasError = (controlName: string, errorName: string) => {
    return this.pagesForm.controls[controlName].hasError(errorName);
  }

  public update = (form) => {
    if (this.pagesForm.valid) {
      this.execute(form);
    }
  }

  private execute = (data) => {
    this.isCompleted = false;
    let json: any = {
      title: data.title,
      content: data.content,
      code: data.code
    }

    this.pagesSrv.update(json)
      .subscribe(
        data => {
          let message = this.translate.instant('success-save-pages');
          this.openSnackBar(message, 'x');
        },
        error => {
          if (error.status === 404 || error.status === 400) {
            let message = error.error;
            this.openSnackBar(message, 'x');
          }
          else if (error.status === 409) {
            let message = this.translate.instant('an-error-occurred-while-processing');
            this.openSnackBar(message, 'x');
          }
          else{
              if (error.status === 500) {
                let message = this.translate.instant('service-is-temporarily-unavailable');
                this.openSnackBar(message, 'x');
              }
              else {
                let message = this.translate.instant('an-error-occurred-while-processing');
                this.openSnackBar(message, 'x');
              }
          }
        }
      ).add(() => { this.isCompleted = true });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

}
