import { FormGroup } from '@angular/forms';

// custom validator to check that two fields match
export function BothRequired(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (control.errors && !control.errors.firstRequired) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        if (matchingControl.errors && !matchingControl.errors.secondRequired) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        if ((control.value?.length > 0 && matchingControl.value?.length < 1)) {
            matchingControl.setErrors({ secondRequired: true  });
        }
        else if((control.value?.length < 1 && matchingControl.value?.length > 0)){
            control.setErrors({ firstRequired: true  });
        } else {
            control.setErrors(null);
            matchingControl.setErrors(null);
        }
    }
}