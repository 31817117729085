<div fxLayout="column" fxLayoutAlign="center center">
    <h1 class="mat-headline">{{'services-rates'| translate}}</h1>
</div>
<div fxLayout="column" fxLayoutAlign="none">
    <div fxLayoutAlign="center center">
        <mat-form-field fxFlex="40">
            <input type="text" placeholder="{{'tariff-rates'| translate}}" matInput [formControl]="selectedTariff"
                [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" autoActiveFirstOption (optionSelected)="getCurrentRates()">
                <mat-option *ngFor="let tariff of tariffAutoComplete$ | async; let index = index" [value]="tariff">
                    {{tariff.name}}
                </mat-option>
            </mat-autocomplete>
            <button *ngIf="selectedTariff != null" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="clearSearchTariff()">
                <mat-icon>close</mat-icon>
              </button>
        </mat-form-field>

        <button mat-icon-button color="primary" (click)="getCurrentRates()" [disabled]="!selectedTariff.valid">
            <mat-icon>search</mat-icon>
        </button>
        
        <button mat-icon-button color="primary" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="mainMenuButtonExcel" [disabled]="dataSource?.length < 1">
            <mat-icon>save_alt</mat-icon>
        </button>
        <mat-menu #mainMenuButtonExcel="matMenu">
            <div class="table-column-list-block">
                <section class="button-download">
                    <button mat-raised-button color="primary"  (click)="exportToExcel()" >
                        {{'export' | translate}}
                        <mat-icon>save_alt</mat-icon>
                    </button>   
                </section>
                <span (click)="$event.stopPropagation()" *ngFor="let element of columnDefinitionsForExcel;index as i">
                    <mat-slide-toggle color="primary" (change)="element.isShow = !element.isShow" [checked]="element.isShow == true"></mat-slide-toggle>
                    {{element.label}}
                    <br>
                </span>
            </div>
        </mat-menu>
    </div>
    
    <mat-progress-bar mode="indeterminate" *ngIf="!isCompleted"></mat-progress-bar>

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z0">

        <!-- serviceId Column -->
        <ng-container matColumnDef="serviceId">
            <th mat-header-cell *matHeaderCellDef> {{'serviceId' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.serviceId}} </td>
        </ng-container>
        <!-- serviceName Column -->
        <ng-container matColumnDef="serviceName">
            <th mat-header-cell *matHeaderCellDef> {{'alias' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.serviceName }} </td>
        </ng-container>
        <!-- itoPercent Column -->
        <ng-container matColumnDef="itoPercent">
            <th mat-header-cell *matHeaderCellDef> {{'itoPercent' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.itoPercent/100}}
            </td>
        </ng-container>
        <!-- itoFix Column -->
        <ng-container matColumnDef="itoFix">
            <th mat-header-cell *matHeaderCellDef> {{'itoFix' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.itoFix/100 | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </td>
        </ng-container>
        <!-- lowerPercent Column -->
        <ng-container matColumnDef="lowerPercent">
            <th mat-header-cell *matHeaderCellDef> {{'lowerPercent' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.lowerPercent/100}}
            </td>
        </ng-container>
        <!-- lowerFix Column -->
        <ng-container matColumnDef="lowerFix">
            <th mat-header-cell *matHeaderCellDef> {{'lowerFix' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.lowerFix/100 | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}
            </td>
        </ng-container>

        <!-- isActive Column -->
        <ng-container matColumnDef="isEnable">
            <th mat-header-cell *matHeaderCellDef> {{'isEnable' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.isEnable.toString() | translate}}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>